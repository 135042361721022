import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Fahrradroute } from "src/app/fahrradrouten/entities/fahrradroute";

@Injectable({ providedIn: "root" })
export class FahrradroutenFilterService implements OnDestroy {
  public static readonly ALL = "ALL";
  public static readonly LANDESRADFERNWEG = "LANDESRADFERNWEG";
  public static readonly HAUPTSTRECKE = "HAUPTSTRECKE";
  public static readonly VARIANTE = "VARIANTE";

  private _$filterKategorie = new BehaviorSubject<string | undefined>(FahrradroutenFilterService.LANDESRADFERNWEG);
  private _$filterTourenkategorie = new BehaviorSubject<string | undefined>(FahrradroutenFilterService.ALL);
  private _$filterVariantenkategorie = new BehaviorSubject<string | undefined>(FahrradroutenFilterService.HAUPTSTRECKE);
  private _$filterVolltext = new BehaviorSubject<string | undefined>("");

  ngOnDestroy(): void {
    this._$filterKategorie.complete();
    this._$filterTourenkategorie.complete();
    this._$filterVariantenkategorie.complete();
    this._$filterVolltext.complete();
  }

  setKategorieFilter(value: string): void {
    this._$filterKategorie.next(value);
  }

  setTourenkategorieFilter(value: string): void {
    this._$filterTourenkategorie.next(value);
  }

  setVariantenkategorieFilter(value: string): void {
    this._$filterVariantenkategorie.next(value);
  }

  setVolltextFilter(value: string): void {
    this._$filterVolltext.next(value.toLowerCase());
  }

  filter(fahrradrouten: Fahrradroute[]): Fahrradroute[] {
    return fahrradrouten
      .filter(
        f =>
          !this._$filterKategorie.value ||
          this._$filterKategorie.value === FahrradroutenFilterService.ALL ||
          f.kategorie === this._$filterKategorie.value
      )
      .filter(
        f =>
          !this._$filterTourenkategorie.value ||
          this._$filterTourenkategorie.value === FahrradroutenFilterService.ALL ||
          f.tourenkategorie === this._$filterTourenkategorie.value
      )
      .filter(
        f =>
          !this._$filterVariantenkategorie.value ||
          this._$filterVariantenkategorie.value === FahrradroutenFilterService.ALL ||
          (this._$filterVariantenkategorie.value === FahrradroutenFilterService.HAUPTSTRECKE &&
            !f.variantenkategorie) ||
          (this._$filterVariantenkategorie.value === FahrradroutenFilterService.VARIANTE && !!f.variantenkategorie)
      )
      .filter(f => {
        return (
          !this._$filterVolltext.value ||
          (f.name != null && f.name.toLowerCase().includes(this._$filterVolltext.value)) ||
          (f.kurzbeschreibung != null && f.kurzbeschreibung.toLowerCase().includes(this._$filterVolltext.value)) ||
          (f.beschreibung != null && f.beschreibung.toLowerCase().includes(this._$filterVolltext.value)) ||
          (f.lizenz != null && f.lizenz.toLowerCase().includes(this._$filterVolltext.value)) ||
          (f.lizenzNamensnennung != null &&
            f.lizenzNamensnennung.toLowerCase().includes(this._$filterVolltext.value)) ||
          (f.homepage != null && f.homepage.toLowerCase().includes(this._$filterVolltext.value)) ||
          (f.verantwortlich != null && f.verantwortlich.toLowerCase().includes(this._$filterVolltext.value)) ||
          (f.zusaetzlicheInformationen != null &&
            f.zusaetzlicheInformationen.toLowerCase().includes(this._$filterVolltext.value))
        );
      });
  }

  get $filterKategorie(): Observable<string | undefined> {
    return this._$filterKategorie.asObservable();
  }

  get $filterTourenkategorie(): Observable<string | undefined> {
    return this._$filterTourenkategorie.asObservable();
  }

  get $filterVariantenkategorie(): Observable<string | undefined> {
    return this._$filterVariantenkategorie.asObservable();
  }

  get $filterVolltext(): Observable<string | undefined> {
    return this._$filterVolltext.asObservable();
  }

  get filterKategorie(): string | undefined {
    return this._$filterKategorie.value;
  }

  get filterTourenkategorie(): string | undefined {
    return this._$filterTourenkategorie.value;
  }

  get filterVariantenkategorie(): string | undefined {
    return this._$filterVariantenkategorie.value;
  }

  get filterVolltext(): string | undefined {
    return this._$filterVolltext.value;
  }
}
