import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Poi } from "../entities/poi";
import { fromLonLat } from "ol/proj";
import { GeoLocation } from "../../common/entities/geo-location";
import { InfrastrukturRepository } from "src/app/karte/repositories/infrastruktur.repository";
import { TranslateService } from "@ngx-translate/core";

interface PoiGeoJson {
  features: [
    {
      id: string;
      properties: {
        name: string;
      };
      geometry: {
        coordinates: [number, number];
      };
    }
  ];
}

@Injectable({
  providedIn: "root",
})
export class PoiRepository {
  static readonly QUERY_ADRESSEN_RESULT_COUNT: number = 10;
  // Notiz: "typeName" ist KEIN Typo!
  static readonly LAYER_URL: string = `/api/geoserver/rrpbw-poi/wfs?service=wfs&version=2.0.0&request=GetFeature&typeName={typeNames}&outputFormat=application/json&count=${PoiRepository.QUERY_ADRESSEN_RESULT_COUNT}&cql_filter=strToLowerCase(name) LIKE '%25{query}%25'`;

  constructor(
    private httpClient: HttpClient,
    private infrastrukturRepository: InfrastrukturRepository,
    private translateService: TranslateService
  ) {}

  queryPois(query: string): Observable<Poi[]> {
    return this.httpClient
      .get<PoiGeoJson>(
        PoiRepository.LAYER_URL.replace("{query}", query.toLowerCase()).replace(
          "{typeNames}",
          this.infrastrukturRepository
            .getAllPois()
            .map(infrastruktur => infrastruktur.getFullWFSLayerDescriptor())
            .join(",")
        )
      )
      .pipe(
        map(geojson => {
          return geojson.features
            .filter(f => !!f.id && f.geometry && f.geometry.coordinates && f.properties)
            .map(feature => {
              const coords = fromLonLat(feature.geometry.coordinates);
              const typeName = feature.id.split(".").slice(0, -1).join();
              const infrastruktur = this.infrastrukturRepository
                .getAllPois()
                .filter(infrastruktur => infrastruktur.getWFSLayerNames()?.includes(typeName))[0];
              const title = this.translateService.instant(infrastruktur.labelKey);

              return new Poi(title, new GeoLocation(coords[0], coords[1]), feature.properties.name);
            });
        })
      );
  }
}
