<div
  class="hoehenprofil-tooltip-container"
  [class.vertical-layout]="isSmartphoneLayout"
  [class.dummy-space]="!isSmartphoneLayout"
  [class.mb-xs]="isSmartphoneLayout"
>
  <ng-container *ngIf="hoveredHoehenprofilLocation">
    <span class="elevation-label">{{
      "hoehenprofil.tooltip"
        | translate
          : {
              distance: hoveredHoehenprofilLocation.distanceInM,
              elevation: hoveredHoehenprofilLocation.elevation
            }
    }}</span>
    <div *ngIf="withAttributeTooltip" class="attribute-legend-container" [class.vertical-layout]="isSmartphoneLayout">
      <div class="attribute-legend-item">
        <div class="circle" [style.background-color]="belagArtColor | colorToCss"></div>
        <span>{{ "hoehenprofil.belagart" | translate }}: {{ "detailProperties.values." + belagArt | translate }}</span>
      </div>
      <div class="attribute-legend-item">
        <div class="circle" [style.background-color]="radverkehrsfuehrungArtColor | colorToCss"></div>
        <span
          >{{ "hoehenprofil.radverkehrsfuehrung" | translate }}:
          {{ "detailProperties.values." + radverkehrsfuehrung | translate }}</span
        >
      </div>
    </div>
  </ng-container>
</div>
