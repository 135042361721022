<div class="background-image" [class.smartphone-layout]="isSmartphoneLayout$ | async"></div>
<div
  class="content"
  [class.smartphone-layout]="isSmartphoneLayout$ | async"
  [class.top-margin-small]="smallTopMargin"
  [ngClass]="header.children.length > 0 ? 'no-vertical-padding' : 'with-vertical-padding'"
>
  <div #header>
    <ng-content select="[headerContent]"></ng-content>
  </div>

  <ng-content></ng-content>
</div>
<ng-content select="[footerContent]"></ng-content>
