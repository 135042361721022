import { Chart } from "chart.js";
import { ColorTone, RrpBwColors } from "src/app/common/utils/rrp-bw-colors";
import { RouteMitWegpunkten } from "src/app/common/entities/route-mit-wegpunkten";
import { toLonLat } from "ol/proj";
import { formatMeter } from "src/app/common/utils/distance-formatter";
import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { HoveredHoehenprofilLocation } from "src/app/common/entities/hoehenprofil-hover-location";
import { SinglePathDetail } from "src/app/common/entities/single-path-detail";
import { BelagArt } from "src/app/common/entities/belag-art";
import { Radverkehrsfuehrung } from "src/app/common/entities/radverkehrsfuehrung";

@Directive()
export abstract class AbstractHoehenprofilComponent implements OnDestroy, OnChanges, OnChanges {
  static readonly SMALL_LAYOUT_CHART_TICK_BORDER_DISTANCE = 0.2;

  public readonly belagArtColors = BelagArt.colors;
  public readonly radverkehrsfuehrungColors = Radverkehrsfuehrung.colors;

  @Input() routeMitWegpunkten: RouteMitWegpunkten | undefined;

  @Output() selectedElementIndex: EventEmitter<number | undefined> = new EventEmitter();
  @Output() hoehenprofilHovered: EventEmitter<HoveredHoehenprofilLocation | undefined> = new EventEmitter();

  public chart: Chart;
  public xAxisStart = 0;
  public xAxisEnd = 0;

  protected abstract lineChartOptions: any;
  protected abstract lineChartDatasets: any;

  protected distanzen: number[];

  private wegpunktDistances: number[] = [];

  protected constructor(private useSmallLayout: boolean, private showPositionLineOnHover: boolean) {}

  private updateChart(): void {
    if (this.routeMitWegpunkten) {
      const hoehen = this.routeMitWegpunkten.route.elevations;
      this.distanzen = this.routeMitWegpunkten.route.distancesToStart;
      this.lineChartOptions.scales!.x!.max = Math.max(...this.distanzen);

      if (this.useSmallLayout) {
        const maxHoehe = Math.max(...hoehen);
        const minHoehe = Math.min(...hoehen);
        const distanceToBorder =
          (maxHoehe - minHoehe) * AbstractHoehenprofilComponent.SMALL_LAYOUT_CHART_TICK_BORDER_DISTANCE;

        this.lineChartOptions.scales!.y!.min = Math.max(0, minHoehe - distanceToBorder);
        this.lineChartOptions.scales!.y!.max = maxHoehe + distanceToBorder;
      }

      this.lineChartDatasets[0].data = hoehen.map((hoehe, index) => {
        return { x: this.distanzen[index], y: hoehe };
      });
      this.wegpunktDistances = this.routeMitWegpunkten.route.getWegpunktDistances(
        this.routeMitWegpunkten.wegpunkte?.map(c => toLonLat(c)) ?? []
      );
    } else {
      this.lineChartDatasets[0].data = [];
    }

    if (this.chart) {
      this.chart.update();
    }
  }

  protected initChart(context: CanvasRenderingContext2D): void {
    if (this.useSmallLayout) {
      this.lineChartOptions.scales.y.afterBuildTicks = (axis: any) => {
        const min = Math.min(...(this.routeMitWegpunkten?.route.elevations! ?? []));
        const max = Math.max(...(this.routeMitWegpunkten?.route.elevations! ?? []));

        axis.ticks[0].value = min;
        axis.ticks[1].value = max;
      };
    }

    this.chart = new Chart(context, {
      type: "line",
      data: {
        datasets: this.lineChartDatasets,
      },
      options: this.lineChartOptions,
    });

    const oldDraw = this.chart.draw;
    this.chart.draw = () => {
      oldDraw.call(this.chart);

      const chart = this.chart!;
      const ctx = chart.ctx;
      const bottomY = this.chart.scales.y.bottom;
      let topY: number;
      let verticalWegpunktDisplacement: number;
      let wegpunktRadius: number;
      let wegpunktFontSize: number;

      if (this.useSmallLayout) {
        topY = 9;
        verticalWegpunktDisplacement = -10;
        wegpunktRadius = 7;
        wegpunktFontSize = 9;
      } else {
        topY = this.chart.scales.y.top;
        verticalWegpunktDisplacement = 0;
        wegpunktRadius = 10;
        wegpunktFontSize = 12;
      }

      if (this.showPositionLineOnHover && chart.tooltip && chart.tooltip.opacity > 0) {
        const x = chart.tooltip?.caretX!;

        // draw line
        ctx.restore();
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = RrpBwColors.getAccentColor(ColorTone.Tone500);
        ctx.stroke();
        ctx.closePath();
        ctx.beginPath();
        ctx.ellipse(x, topY, 8, 8, 0, 0, 360);
        ctx.fillStyle = RrpBwColors.getAccentColor(ColorTone.Tone500);
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
      }
      const wegpunktY = bottomY + verticalWegpunktDisplacement;
      this.wegpunktDistances.forEach((wegpunkt: number, index: number) => {
        if (index === 0 || index === this.wegpunktDistances.length - 1) {
          return;
        }

        ctx.restore();
        ctx.beginPath();
        const x = (wegpunkt / this.distanzen[this.distanzen.length - 1]) * this.chart.scales.x.width;
        ctx.arc(this.chart.scales.x.left + x, wegpunktY, wegpunktRadius, 0, Math.PI * 2);
        ctx.fillStyle = "#000000";
        ctx.fill();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#FFFFFF";
        ctx.font = "bolder " + wegpunktFontSize + "px arial";
        ctx.fillText("" + index, this.chart.scales.x.left + x, wegpunktY);
        ctx.restore();
      });
      this.updateAxisXRange();
    };
    this.chart.update();
  }

  ngOnDestroy(): void {
    this.selectedElementIndex.emit();
    this.hoehenprofilHovered.emit();
    this.chart?.destroy();
  }

  ngOnChanges(): void {
    this.hideTooltip();
    this.updateChart();
  }

  onHover(distance: number, elevation: number): void {
    const distances = this.distanzen.map(value => Math.abs(value - distance));
    const index = distances.indexOf(Math.min(...distances));
    this.selectedElementIndex.emit(index);

    this.hoehenprofilHovered.emit({
      distanceInM: formatMeter(distance),
      elevation: elevation,
      pathDetail: this.pathDetails[index],
    });
  }

  onUnhover(): void {
    this.selectedElementIndex.emit();
    this.hoehenprofilHovered.emit();
  }

  onMouseMove(): void {
    const tooltip = this.chart.tooltip;

    if (!tooltip || !tooltip.dataPoints || tooltip.dataPoints.length === 0) {
      return;
    }

    const dataPoint = tooltip.dataPoints[0];
    const px = (dataPoint.raw as any).x;
    const py = (dataPoint.raw as any).y;
    this.onHover(px, py);
  }

  public get pathDetails(): SinglePathDetail[] {
    return this.routeMitWegpunkten?.route.pathDetails ?? [];
  }

  public convertToCss(colorValues: number[] | undefined): string {
    if (!colorValues || colorValues.length < 4) {
      return "rgba(200,200,200,1)";
    }
    return `rgba(${colorValues[0]},${colorValues[1]},${colorValues[2]},${colorValues[3]})`;
  }

  private hideTooltip(): void {
    if (!this.chart) {
      return;
    }

    // Manuelles Feuern vom "mouseout" Event, da es keine normale Möglichkeit gibt programmatisch das Tooltip und den
    // senkrechten Marker zu verstecken.
    const mouseOutEvent = new MouseEvent("mouseout");
    this.chart.canvas.dispatchEvent(mouseOutEvent);
  }

  private updateAxisXRange(): void {
    this.xAxisStart = this.chart?.scales.x.left ?? 0;
    this.xAxisEnd = this.chart?.width ? this.chart?.width - this.chart?.scales.x.right : 0;
  }
}
