const locale = "de";

export function millisToTimeString(milliseconds: number): string {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const remainingMinutesInMillis = milliseconds - hours * (1000 * 60 * 60);
  const minutes = Math.floor(remainingMinutesInMillis / (1000 * 60));

  const hourString = ("" + hours).padStart(2, "0");
  const minuteString = ("" + minutes).padStart(2, "0");

  return `${hourString}:${minuteString}`;
}

export function meterToKilometerString(meters: number): string {
  const kilometer = meters / 1000;
  if (meters % 1000 === 0) {
    return `${kilometer.toFixed(0)} km`;
  }
  return (
    kilometer.toLocaleString(locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }) + " km"
  );
}
