import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Geometry } from "ol/geom";
import { Stroke, Style } from "ol/style";
import { ColorTone, RrpBwColors } from "../../../common/utils/rrp-bw-colors";
import { GeoJSON } from "ol/format";

@Component({
  selector: "rrpbw-grenze-layer",
  templateUrl: "./grenze-layer.component.html",
  styleUrls: ["./grenze-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrenzeLayerComponent implements OnInit, OnDestroy {
  static readonly GRENZE_STYLE = new Style({
    stroke: new Stroke({
      width: 1,
      color: RrpBwColors.getAccentColor(ColorTone.Tone500, 1),
    }),
  });

  readonly dataLayer: VectorLayer<VectorSource<Geometry>>;

  constructor(public karteBaseService: KarteBaseService) {
    this.dataLayer = new VectorLayer({
      source: new VectorSource({
        url: "/assets/data/bw-grenzen.geojson",
        format: new GeoJSON(),
      }),
      style: GrenzeLayerComponent.GRENZE_STYLE,
    });
  }

  ngOnInit(): void {
    this.karteBaseService.addLayer(this.dataLayer);
  }

  ngOnDestroy(): void {
    this.karteBaseService.removeLayer(this.dataLayer);
  }
}
