<div class="button-container">
  <rrpbw-karte-icon-button
    [attr.aria-expanded]="isLayerMenuOpen"
    class="mb-m"
    (clicked)="onLayerButtonClicked()"
    icon="layers"
    [selected]="isLayerMenuOpen"
    [ariaLabel]="'karteControlsSidebar.toggleLayerMenu' | translate"
  ></rrpbw-karte-icon-button>
  <rrpbw-karte-icon-button
    class="mb-s mt-m"
    (clicked)="onZoomInButtonClicked()"
    icon="add"
    *ngIf="!isMobileDevice"
    [ariaLabel]="'karteControlsSidebar.zoomIn' | translate"
  ></rrpbw-karte-icon-button>
  <rrpbw-karte-icon-button
    class="mb-xl"
    (clicked)="onZoomOutButtonClicked()"
    icon="remove"
    *ngIf="!isMobileDevice"
    [ariaLabel]="'karteControlsSidebar.zoomOut' | translate"
  ></rrpbw-karte-icon-button>
  <rrpbw-karte-icon-button
    *ngIf="!isKarteGenorded"
    (clicked)="onEinnordenButtonClicked()"
    icon="explore_filled"
    [ariaLabel]="'karteControlsSidebar.north' | translate"
  ></rrpbw-karte-icon-button>
</div>
<div class="button-container">
  <rrpbw-karte-icon-button
    (clicked)="onLocateMeClicked()"
    icon="my_location"
    [ariaLabel]="'karteControlsSidebar.myLocation' | translate"
  ></rrpbw-karte-icon-button>
</div>
