import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { map, Observable } from "rxjs";

import { LayoutService, ScreenLayout } from "../../services/layout.service";

@Component({
  selector: "rrpbw-informationen-anzeige-layout",
  templateUrl: "./informationen-anzeige-layout.component.html",
  styleUrls: ["./informationen-anzeige-layout.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationenAnzeigeLayoutComponent {
  @Input()
  smallTopMargin: boolean;

  isSmartphoneLayout$: Observable<boolean>;

  constructor(layoutService: LayoutService) {
    this.isSmartphoneLayout$ = layoutService.screenLayout.pipe(map(layout => layout === ScreenLayout.Smartphone));
  }
}
