import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { FahrradroutenFilterService } from "src/app/fahrradrouten/services/fahrradrouten-filter.service";
import { Fahrradroute } from "src/app/fahrradrouten/entities/fahrradroute";

@Component({
  selector: "rrpbw-fahrradrouten-filter",
  templateUrl: "./fahrradrouten-filter.component.html",
  styleUrls: ["./fahrradrouten-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrradroutenFilterComponent {
  @Input()
  allFahrradrouten: Fahrradroute[] = [];

  @Input()
  allFahrradroutenKategorien: string[] = [];

  @Input()
  allFahrradroutenTourenkategorien: string[] = [];

  @Input()
  allFahrradroutenVarianten: any[] = [
    FahrradroutenFilterService.ALL,
    FahrradroutenFilterService.HAUPTSTRECKE,
    FahrradroutenFilterService.VARIANTE,
  ];

  constructor(public fahrradroutenFilterService: FahrradroutenFilterService) {}

  onFilterKategorieChanges(event: MatSelectChange): void {
    this.fahrradroutenFilterService.setKategorieFilter(event.value);
  }

  onFilterTourenkategorieChanges(event: MatSelectChange): void {
    this.fahrradroutenFilterService.setTourenkategorieFilter(event.value);
  }

  onFilterVarianteChanged(event: MatSelectChange): void {
    this.fahrradroutenFilterService.setVariantenkategorieFilter(event.value);
  }

  onFilterVolltextChanged(event: any): void {
    this.fahrradroutenFilterService.setVolltextFilter(event.target.value);
  }
}
