import { NgModule } from "@angular/core";
import { PropertyLabelComponent } from "./property-label.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [PropertyLabelComponent],
  imports: [MatIconModule],
  exports: [PropertyLabelComponent],
})
export class PropertyLabelModule {}
