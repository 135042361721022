<button
  class="mat-drawer-button-back focus"
  (click)="matDrawer.close()"
  mat-flat-button
  [attr.aria-label]="'routenplaner.optionsMenu.close' | translate"
>
  {{ "routenplaner.optionen" | translate }}
  <mat-icon class="contrast">close</mat-icon>
</button>
<rrpbw-routendetails
  *ngIf="route"
  [duration]="route?.duration"
  [distance]="route?.distance"
  [totalAscend]="route?.totalAscend"
  [totalDescend]="route?.totalDescend"
></rrpbw-routendetails>
<mat-accordion [multi]="true">
  <mat-expansion-panel #radtypOptions class="expansion-panel" [expanded]="true" [hideToggle]="true">
    <mat-expansion-panel-header class="focus">
      <mat-icon class="mr-m">directions_bike</mat-icon>
      <span id="radtypTitle">{{ "routenplaner.optionsMenu.radtyp" | translate }}</span>

      <mat-icon class="arrow-icon" *ngIf="radtypOptions.expanded">arrow_drop_up</mat-icon>
      <span class="cdk-visually-hidden" *ngIf="radtypOptions.expanded">{{
        "routenplaner.optionsMenu.hideRadtyp"
      }}</span>

      <mat-icon class="arrow-icon" *ngIf="!radtypOptions.expanded">arrow_drop_down</mat-icon>
      <span class="cdk-visually-hidden" *ngIf="!radtypOptions.expanded">{{
        "routenplaner.optionsMenu.showRadtyp"
      }}</span>
    </mat-expansion-panel-header>
    <rrpbw-radtyp-auswahl
      class="accordion-item-body"
      [selectedRadtyp]="currentRadtyp"
      (radtypChanged)="this.radtypChanged.emit($event)"
    >
    </rrpbw-radtyp-auswahl>
    <rrpbw-fahrverhalten-auswahl
      class="accordion-item-body"
      [selectedFahrverhalten]="currentFahrverhalten"
      (fahrverhaltenChanged)="fahrverhaltenChanged.emit($event)"
    >
    </rrpbw-fahrverhalten-auswahl>
  </mat-expansion-panel>
  <mat-expansion-panel #steigungOptions class="expansion-panel" [expanded]="true" [hideToggle]="true">
    <mat-expansion-panel-header class="focus">
      <mat-icon class="mr-m">trending_up</mat-icon>
      <span id="steigungTitle">{{ "routenplaner.optionsMenu.steigung" | translate }}</span>

      <mat-icon class="arrow-icon" *ngIf="steigungOptions.expanded">arrow_drop_up</mat-icon>
      <span class="cdk-visually-hidden" *ngIf="steigungOptions.expanded">{{
        "routenplaner.optionsMenu.hideSteigung"
      }}</span>

      <mat-icon class="arrow-icon" *ngIf="!steigungOptions.expanded">arrow_drop_down</mat-icon>
      <span class="cdk-visually-hidden" *ngIf="!steigungOptions.expanded">{{
        "routenplaner.optionsMenu.showSteigung"
      }}</span>
    </mat-expansion-panel-header>
    <div class="checkboxGroup">
      <mat-checkbox
        [checked]="currentSteigungVermeiden"
        (change)="steigungVermeidenChanged.emit($event.checked)"
        color="primary"
        [aria-labelledby]="'steigungTitle'"
        >{{ "routenplaner.optionsMenu.steigungVermeiden" | translate }}</mat-checkbox
      >
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel #routingOptions class="expansion-panel" [expanded]="true" [hideToggle]="true">
    <mat-expansion-panel-header class="focus">
      <mat-icon class="mr-m">route</mat-icon>
      <span id="streckeTitle">{{ "routenplaner.optionsMenu.strecke" | translate }}</span>
      <a href="https://www.aktivmobil-bw.de/radverkehr/radnetz/das-radnetz/" target="_blank">
        <mat-icon class="help-icon" title="{{ 'routenplaner.optionsMenu.radNetzInfo' | translate }}">help</mat-icon>
      </a>

      <mat-icon class="arrow-icon" *ngIf="routingOptions.expanded">arrow_drop_up</mat-icon>
      <span class="cdk-visually-hidden" *ngIf="routingOptions.expanded">{{
        "routenplaner.optionsMenu.hideStrecke"
      }}</span>

      <mat-icon class="arrow-icon" *ngIf="!routingOptions.expanded">arrow_drop_down</mat-icon>
      <span class="cdk-visually-hidden" *ngIf="!routingOptions.expanded">{{
        "routenplaner.optionsMenu.showStrecke"
      }}</span>
    </mat-expansion-panel-header>
    <mat-radio-group [value]="currentRoutingPraeferenz" (change)="routingPraeferenzChanged.emit($event.value)">
      <mat-radio-button [value]="RoutingPraeferenz.RADNETZ_ANY" color="primary" aria-labelledby="streckeTitle">{{
        "routenplaner.optionsMenu.radnetzAny" | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="RoutingPraeferenz.RADNETZ_ALLTAG" color="primary" aria-labelledby="streckeTitle">{{
        "routenplaner.optionsMenu.radnetzAlltag" | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="RoutingPraeferenz.RADNETZ_FREIZEIT" color="primary" aria-labelledby="streckeTitle">{{
        "routenplaner.optionsMenu.radnetzFreizeit" | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="RoutingPraeferenz.D_ROUTE" color="primary" aria-labelledby="streckeTitle">{{
        "routenplaner.optionsMenu.dRoute" | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="RoutingPraeferenz.NONE" color="primary" aria-labelledby="streckeTitle">{{
        "routenplaner.optionsMenu.keinePraeferenz" | translate
      }}</mat-radio-button>
    </mat-radio-group>
  </mat-expansion-panel>
</mat-accordion>
