import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Feature, MapBrowserEvent } from "ol";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { MapRouteService } from "../../services/map-route.service";
import { Geometry, LineString, MultiLineString } from "ol/geom";
import { GeoLocation } from "../../../common/entities/geo-location";
import { RouteRepository } from "../../repositories/route.repository";
import { Radtyp } from "../../entities/radtyp";
import { catchError, distinctUntilChanged, finalize, Observable, of, Subscription, timer } from "rxjs";
import { Modify } from "ol/interaction";
import { filter, map, tap } from "rxjs/operators";
import GPX from "ol/format/GPX";
import KML from "ol/format/KML";
import { LAT_LON_PROJECTION, MAP_PROJECTION } from "../../../constants";
import { ColorTone, GrayscaleTone, RrpBwColors } from "../../../common/utils/rrp-bw-colors";
import { RoutingPraeferenz } from "../../entities/routing-praeferenz";
import { NotificationService } from "../../../common/services/notification.service";
import { distance } from "ol/coordinate";
import { LoadingBarService } from "../../../common/services/loading-bar.service";
import { TimeService } from "src/app/common/services/time.service";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import { Route } from "src/app/common/entities/route";

@Component({
  selector: "rrpbw-route-layer",
  templateUrl: "./route-layer.component.html",
  styleUrls: ["./route-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteLayerComponent implements OnInit, OnDestroy, MapRouteService {
  static readonly ROUTE_STYLE = [
    new Style({
      stroke: new Stroke({
        width: 6,
        color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone1, 0.75),
      }),
    }),
    new Style({
      stroke: new Stroke({
        width: 5,
        color: RrpBwColors.getPrimaryColor(ColorTone.Tone500, 0.75),
      }),
    }),
  ];

  static readonly MODIFY_STYLE = new Style({
    image: new Circle({
      fill: new Fill({
        color: RrpBwColors.getAccentColor(ColorTone.Tone500),
      }),
      stroke: new Stroke({
        width: 2,
        color: RrpBwColors.getAccentContrastColor(ColorTone.Tone500),
      }),
      radius: 5,
    }),
  });

  static readonly ROUTE_EDIT_PREVIEW_INTERVALL = 250;

  @Output()
  routeEditedPreview: EventEmitter<[GeoLocation, GeoLocation[]]> = new EventEmitter();

  @Output()
  routeEdited: EventEmitter<GeoLocation> = new EventEmitter();

  readonly featureLayer: VectorLayer<VectorSource<Geometry>>;
  readonly interactionLayer: VectorLayer<VectorSource<Geometry>>;
  readonly modifyInteraction: Modify;
  readonly gpxFormatter: GPX;
  readonly kmlFormatter: KML;

  routeCounter: number = 0;
  lastEmittedCounter: number = 0;
  routeTimestamp: number = 0;

  constructor(
    public routeRepository: RouteRepository,
    public karteBaseService: KarteBaseService,
    public notificationService: NotificationService,
    public loadingBarService: LoadingBarService,
    public timeService: TimeService
  ) {
    this.featureLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style: RouteLayerComponent.ROUTE_STYLE,
      zIndex: 50,
    });
    this.interactionLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style: new Style(),
    });

    this.modifyInteraction = new Modify({
      source: this.interactionLayerSource,
      snapToPointer: true,
      hitDetection: true,
      style: RouteLayerComponent.MODIFY_STYLE,
    });

    this.gpxFormatter = new GPX();
    this.kmlFormatter = new KML();
  }

  ngOnInit(): void {
    this.karteBaseService.addLayer(this.featureLayer);
    this.karteBaseService.addLayer(this.interactionLayer);
    this.karteBaseService.addInteraction(this.modifyInteraction);
    this.setupInteraction();

    this.karteBaseService
      .poiHover()
      .pipe(distinctUntilChanged())
      .subscribe(p => {
        this.modifyInteraction.setActive(!p);
      });
  }

  ngOnDestroy(): void {
    this.karteBaseService.removeLayer(this.featureLayer);
    this.karteBaseService.removeLayer(this.interactionLayer);
    this.karteBaseService.removeInteraction(this.modifyInteraction);
  }

  get featureLayerSource(): VectorSource<Geometry> {
    return this.featureLayer.getSource()!;
  }

  get interactionLayerSource(): VectorSource<Geometry> {
    return this.interactionLayer.getSource()!;
  }

  setRoute(
    geoLocations: GeoLocation[],
    radtyp: Radtyp,
    routingPraeferenz: RoutingPraeferenz,
    steigungVermeiden: boolean
  ): Observable<Route> {
    this.routeCounter++;
    const currentRoute = this.routeCounter;
    const loading = this.loadingBarService.push();

    if (radtyp === Radtyp.LASTENRAD) {
      // Wir haben kein eigenes Lastenrad-Profil, daher nehmen wir das normale Fahrrad-Profil.
      radtyp = Radtyp.FAHRRAD;
    }

    return this.routeRepository.getRoute(geoLocations, radtyp, routingPraeferenz, steigungVermeiden).pipe(
      finalize(() => loading.next()),
      tap({
        next: route => {
          const elevationValues = route.points.map(p => p[2]);
          if (elevationValues.includes(0)) {
            this.notificationService.notify("toast.keineHoehendatenAußerhalbBw");
          }
        },
        error: e => this.handleHttpRoutingError(e),
      }),
      map(route => {
        if (currentRoute > this.lastEmittedCounter) {
          this.routeTimestamp = this.timeService.getEpochMillis();
          this.updateRoute(route);
          this.lastEmittedCounter = currentRoute;
          return route;
        }

        return undefined;
      }),
      filter(value => value != null)
    ) as Observable<Route>;
  }

  previewRoute(
    geoLocations: GeoLocation[],
    radtyp: Radtyp,
    routingPraeferenz: RoutingPraeferenz,
    steigungVermeiden: boolean
  ): Observable<Route> {
    const previewStartTime = this.timeService.getEpochMillis();
    const loading = this.loadingBarService.push();
    return this.routeRepository.getRoute(geoLocations, radtyp, routingPraeferenz, steigungVermeiden).pipe(
      tap({
        next: route => {
          if (previewStartTime <= this.routeTimestamp) {
            return;
          }

          this.featureLayerSource.clear();

          const feature = new Feature({
            geometry: new LineString(route.points),
          });
          this.featureLayerSource.addFeature(feature);
        },
        error: e => this.handleHttpRoutingError(e),
      }),
      catchError(() => of()),
      finalize(() => loading.next())
    );
  }

  private setupInteraction(): void {
    let timerSub: Subscription;
    this.modifyInteraction.on("modifystart", () => {
      const originalCoordinates = [
        ...(this.interactionLayerSource.getFeatures()[0].getGeometry() as LineString).getCoordinates(),
      ];

      timerSub = timer(0, RouteLayerComponent.ROUTE_EDIT_PREVIEW_INTERVALL).subscribe(() => {
        const modifyCoordinate = (this.interactionLayerSource.getFeatures()[0].getGeometry() as LineString)
          .getCoordinates()
          .find(
            (coordinate, index) =>
              coordinate[0] !== originalCoordinates[index][0] || coordinate[1] !== originalCoordinates[index][1]
          )!;

        const interactiveRouteBeforeEdit = (this.interactionLayerSource.getFeatures()[0].getGeometry() as LineString)
          .getCoordinates()
          .map(coordinates => GeoLocation.fromCoordinates(coordinates));

        this.routeEditedPreview.emit([GeoLocation.fromCoordinates(modifyCoordinate), interactiveRouteBeforeEdit]);
      });
    });

    this.modifyInteraction.on("modifyend", event => {
      timerSub?.unsubscribe();

      const newCoordinate = (event.mapBrowserEvent as MapBrowserEvent<any>).coordinate;
      this.routeEdited.next(GeoLocation.fromCoordinates(newCoordinate));

      // Interaktionslayer zurücksetzen, da es sein kann, dass keine Route aktualisiert wurde, aber ein Punkt auf dem
      // Interaktionslayer definitiv verschoben wurde.
      if (this.featureLayerSource.getFeatures() && this.featureLayerSource.getFeatures().length > 0) {
        this.interactionLayerSource.clear();
        this.interactionLayerSource.addFeature(
          new Feature({
            geometry: this.featureLayerSource.getFeatures()[0].getGeometry()!.clone() as LineString,
          })
        );
      }
    });
  }

  private handleHttpRoutingError(error: any): void {
    if (error.status === 500) {
      this.notificationService.notifyPermanent("toast.routeAußerhalbBw");
    } else if (error.status === 502) {
      this.notificationService.notifyPermanent("toast.routingEngineNichtErreichbar");
    }
  }

  zoomToRoute(): void {
    const feature = this.featureLayerSource.getFeatures()[0];

    if (feature) {
      this.karteBaseService.zoomToExtent(feature.getGeometry()!.getExtent());
    }
  }

  clearLayer(): void {
    this.lastEmittedCounter = this.routeCounter;
    this.featureLayerSource.clear();
    this.interactionLayerSource.clear();
  }

  getClosestPointToGeoLocation(geoLocation: GeoLocation): GeoLocation {
    const feature = this.interactionLayerSource.getClosestFeatureToCoordinate(geoLocation.coordinates);

    const distances = (feature.getGeometry() as LineString)
      .getCoordinates()
      .map(coordinate => distance(coordinate, geoLocation.coordinates));
    const index = distances.indexOf(Math.min(...distances));
    const coordinate = (feature.getGeometry() as LineString).getCoordinates()[index];

    return GeoLocation.fromCoordinates(coordinate);
  }

  getRouteAsGpx(): string {
    return this.gpxFormatter.writeFeatures(
      this.featureLayerSource
        .getFeatures()
        .filter(f => !!f.getGeometry())
        .map(f => {
          // Objekte vom Typ MultiLineString werden im GPX-Format von OpenLayers zu GPX-Tracks konvertiert. Normale
          // LineStrings werden jedoch zu GPX-Routen konvertiert, was wohl eher unüblich ist und viele Anwendungen
          // erwarten eher einen GPX-Track.
          const multiLineString = new MultiLineString([
            f.getGeometry()!.clone().transform(MAP_PROJECTION, LAT_LON_PROJECTION) as LineString,
          ]);
          return new Feature(multiLineString);
        })
    );
  }

  getRouteAsKml(): string {
    return this.kmlFormatter.writeFeatures(
      this.featureLayerSource
        .getFeatures()
        .filter(f => !!f.getGeometry())
        .map(f => new Feature(f.getGeometry()!.clone().transform(MAP_PROJECTION, LAT_LON_PROJECTION)))
    );
  }

  updateRoute(route: Route): void {
    this.clearLayer();

    const feature = new Feature({
      geometry: new LineString(route.points),
    });
    this.featureLayerSource.addFeature(feature);

    this.interactionLayerSource.addFeature(
      new Feature({
        geometry: new LineString(route.points),
      })
    );
  }
}
