<ng-container *ngFor="let ebene of ebenen; index as i">
  <rrpbw-tile-layer
    [label]="ebene.label | translate"
    [layerId]="ebene.id"
    [url]="ebene.url"
    [maxZoom]="ebene.maxZoom"
    [initiallyVisible]="i === 0"
  >
  </rrpbw-tile-layer>
</ng-container>
