<div
  tabindex="0"
  id="{{ mapId }}"
  class="map-container focus"
  (contextmenu)="onContextMenu($event)"
  rrpbwAdditionalTouchEvents
  (longPress)="onLongPress($event)"
  (keydown)="onKeydown($event)"
>
  <rrpbw-poi-tooltip></rrpbw-poi-tooltip>
</div>

<rrpbw-karte-context-menu #defaultContextMenu [entries]="defaultContextMenuEntries"></rrpbw-karte-context-menu>
<rrpbw-karte-context-menu #featureContextMenu [entries]="featureContextMenuEntries"></rrpbw-karte-context-menu>
<rrpbw-karte-context-menu
  #featureDetailContextMenu
  [entries]="featureDetailContextMenuEntries"
  [useSymbols]="true"
></rrpbw-karte-context-menu>

<rrpbw-hintergrundebenen
  [ebenen]="hintergrundebenen"
  (addLayer)="onAddBackgroundLayer($event)"
  (removeLayer)="removeLayer($event)"
></rrpbw-hintergrundebenen>

<ng-container *ngFor="let layer of infrastrukturen">
  <rrpbw-geoserver-wms-layer
    *ngFor="let layerName of layer.layerNames; let i = index"
    [infrastruktur]="layer"
    [signatur]="layer.hasSignatur ? signatur : undefined"
    #geoserverLayer
    [visible]="layer.selected$ | async"
  ></rrpbw-geoserver-wms-layer>
</ng-container>

<ng-content></ng-content>

<rrpbw-locate-me-layer></rrpbw-locate-me-layer>

<mat-drawer-container>
  <mat-drawer-content>
    <rrpbw-karte-controls-sidebar
      class="m-m"
      [isKarteGenorded]="isKarteGenorded"
      [isLayerMenuOpen]="layerSideNav.opened"
      (layerMenuButtonClicked)="layerSideNav.toggle()"
    ></rrpbw-karte-controls-sidebar>
    <rrpbw-karte-attributions
      class="mr-m mb-m karte-attributions"
      [attribution]="attribution"
    ></rrpbw-karte-attributions>
  </mat-drawer-content>
  <mat-drawer #layerSideNav mode="side" position="end" autoFocus="true">
    <aside>
      <rrpbw-layer-selection-menu
        rrpbwAccessabilityTabCircleGroup
        [hintergrundebenen]="hintergrundebenen"
        [infrastrukturen]="selektierbarePoiInfrastrukturen"
        [selectedHintergrundebeneId]="hintergrundebenenService.selectedLayerId"
        (hintergrundebeneSelected)="onHintergrundebeneSelected($event)"
      >
      </rrpbw-layer-selection-menu>
    </aside>
  </mat-drawer>
</mat-drawer-container>
