import { NgModule } from "@angular/core";
import { DialogWrapperComponent } from "./dialog-wrapper.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [DialogWrapperComponent],
  imports: [CommonModule, TranslateModule, MatButtonModule],
  exports: [DialogWrapperComponent],
})
export class DialogWrapperModule {}
