import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "rrpbw-karte-attributions",
  templateUrl: "./karte-attributions.component.html",
  styleUrls: ["./karte-attributions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KarteAttributionsComponent {
  @Input()
  attribution: string;
}
