import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Hintergrundebene } from "../../entities/hintergrundebene";
import { HintergrundebenenService } from "../../services/hintergrundebenen.service";
import BaseLayer from "ol/layer/Base";
import { Subscription } from "rxjs";

@Component({
  selector: "rrpbw-hintergrundebenen",
  templateUrl: "./hintergrundebenen.component.html",
  styleUrls: ["./hintergrundebenen.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintergrundebenenComponent implements OnInit, OnDestroy {
  @Input()
  ebenen: Hintergrundebene[];

  @Output()
  addLayer: EventEmitter<BaseLayer> = new EventEmitter();

  @Output()
  removeLayer: EventEmitter<BaseLayer> = new EventEmitter();

  private subscriptions: Subscription[] = [];

  constructor(private hintergrundebenenService: HintergrundebenenService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.hintergrundebenenService.$addLayer.subscribe(layer => this.addLayer.next(layer)),
      this.hintergrundebenenService.$removeLayer.subscribe(layer => this.removeLayer.next(layer))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.addLayer.complete();
    this.removeLayer.complete();
  }
}
