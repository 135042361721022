import BaseLayer from "ol/layer/Base";
import { Interaction } from "ol/interaction";
import { Extent } from "ol/extent";
import { Observable } from "rxjs";
import { TooltipEvent } from "src/app/karte/services/karte.service";

export abstract class KarteBaseService {
  abstract addLayer(layer: BaseLayer): void;

  abstract addInteraction(interaction: Interaction): void;

  abstract removeLayer(layer: BaseLayer): void;

  abstract removeInteraction(interaction: Interaction): void;

  abstract zoomToExtent(extent: number[]): void;

  abstract center(coordinates: number[]): void;

  abstract viewExtent(): Extent;

  abstract poiHover(): Observable<TooltipEvent | undefined>;

  abstract getZoomForResolution(res: number): number | undefined;
}
