import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Hintergrundebene } from "src/app/karte/entities/hintergrundebene";
import { onKeyboardSubmit } from "src/app/common/utils/accessibility";
import { Environment } from "src/app/common/utils/environment";
import { ActivatedRoute } from "@angular/router";
import { RoutenplanerComponent } from "../../../routenplaner/smart-components/routenplaner/routenplaner.component";
import { InfrastrukturRepository } from "src/app/karte/repositories/infrastruktur.repository";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";

@Component({
  selector: "rrpbw-layer-selection-menu",
  templateUrl: "./layer-selection-menu.component.html",
  styleUrls: ["./layer-selection-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerSelectionMenuComponent {
  onKeyboardSubmit = onKeyboardSubmit;
  Environment = Environment;

  @Input()
  hintergrundebenen: Hintergrundebene[] = [];

  @Input()
  selectedHintergrundebeneId: string = "";

  @Output()
  hintergrundebeneSelected: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  infrastrukturen: Infrastruktur[];

  constructor(private activatedRoute: ActivatedRoute, public infrastrukturRepository: InfrastrukturRepository) {}

  onSelectLayer(layerId: string): void {
    this.hintergrundebeneSelected.emit(layerId);
  }

  onRadNetzVisibilityChanged(radNetzVisible: boolean): void {
    this.infrastrukturRepository.radnetzInfrastruktur.select(radNetzVisible);
  }

  get radNetzSelected(): boolean {
    return this.infrastrukturRepository.radnetzInfrastruktur.isSelected();
  }

  get showKartendatenAndStrecken(): boolean {
    return this.activatedRoute.snapshot.component === RoutenplanerComponent;
  }
}
