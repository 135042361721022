import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[rrpbwAccessabilityTabCircleGroup]",
})
export class AccessabilityTabCircleGroupDirective implements AfterViewInit {
  constructor(private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.refresh();
  }

  refresh(): void {
    const hostElement = this.elRef.nativeElement as HTMLElement;
    const elements: any[] = Array.from(hostElement.querySelectorAll("[rrpbwAccessabilityTabCircleElement]"));
    elements.forEach((element, index) => {
      element.addEventListener("keydown", (event: KeyboardEvent) => {
        if ((event as KeyboardEvent).key !== "Tab") {
          return;
        }
        let nextElement: any;
        if (!event.shiftKey) {
          if (elements.slice(index + 1).some(e => this.isEnabled(e))) {
            return;
          }
          nextElement = elements.slice(0, index + 1).find(e => this.isEnabled(e));
        } else {
          if (elements.slice(0, index).some(e => this.isEnabled(e))) {
            return;
          }
          nextElement = elements
            .slice(index)
            .reverse()
            .find(e => this.isEnabled(e));
        }
        event.preventDefault();

        if (!nextElement) {
          return;
        }

        if (nextElement.tagName === "MAT-CHECKBOX") {
          nextElement.querySelector("input").focus();
        } else {
          nextElement.focus();
        }
      });
    });
  }

  private isEnabled(element: any): boolean {
    if (element.tagName === "MAT-CHECKBOX") {
      return !element.querySelector("input").disabled;
    } else {
      return !element.disabled;
    }
  }
}
