export enum ColorTone {
  Tone50 = 50,
  Tone100 = 100,
  Tone200 = 200,
  Tone300 = 300,
  Tone400 = 400,
  Tone500 = 500,
  Tone600 = 600,
  Tone700 = 700,
  Tone800 = 800,
  Tone900 = 900,
}

export enum GrayscaleTone {
  Tone1 = 1,
  Tone2 = 2,
  Tone3 = 3,
  Tone4 = 4,
  Tone5 = 5,
  Tone6 = 6,
  Tone7 = 7,
  Tone8 = 8,
  Tone9 = 9,
  Tone10 = 10,
  Tone11 = 11,
}

export class RrpBwColors {
  static readonly WHITE = RrpBwColors.getGrayscaleColor(0);
  static readonly BLACK = RrpBwColors.getGrayscaleColor(12);

  static getPrimaryColor(tone: ColorTone, transparency: number = 1): string {
    return RrpBwColors.getCssColorVariable("--rrp-bw-primary-palette-" + tone.toString(), transparency);
  }

  static getPrimaryContrastColor(tone: ColorTone, transparency: number = 1): string {
    return RrpBwColors.getCssColorVariable("--rrp-bw-primary-palette-contrast-" + tone.toString(), transparency);
  }

  static getAccentColor(tone: ColorTone, transparency: number = 1): string {
    return RrpBwColors.getCssColorVariable("--rrp-bw-accent-palette-" + tone.toString(), transparency);
  }

  static getAccentContrastColor(tone: ColorTone, transparency: number = 1): string {
    return RrpBwColors.getCssColorVariable("--rrp-bw-accent-palette-contrast-" + tone.toString(), transparency);
  }

  static getGrayscaleColor(tone: GrayscaleTone, transparency: number = 1): string {
    return RrpBwColors.getCssColorVariable("--rrp-bw-grayscale-" + tone.toString(), transparency);
  }

  private static getCssColorVariable(variableName: string, transparency: number): string {
    // 2021-11-12 - td: In bestimmten Umgebungen (z.B. Chrome) können in der CSS Variable Spaces enthalten sein.
    let color = window.getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
    if (transparency === 1) {
      return color;
    }

    if (color.length === 4) {
      // 2021-11-12 - td: Angular minified sowas wie #RRGGBB zu #RGB
      color = "#" + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
    }

    const transparencyHex = Math.min(255, Math.ceil(transparency * 255)).toString(16);
    if (transparencyHex.length === 1) {
      return color + "0" + transparencyHex;
    }
    return color + transparencyHex;
  }
}
