<div class="property-list" [class.vertical-layout]="verticalLayout" [class.evenly-spaces]="evenlySpaces">
  <rrpbw-property-label
    *ngIf="duration != null"
    [text]="durationResultingFromFahrverhalten | duration"
    [ariaLabel]="'routendetails.duration' | translate"
    icon="timer"
  ></rrpbw-property-label>
  <rrpbw-property-label
    *ngIf="distance != null"
    [text]="distance | distance"
    [ariaLabel]="'routendetails.distance' | translate"
    icon="settings_ethernet"
  ></rrpbw-property-label>
  <rrpbw-property-label
    *ngIf="totalAscend != null"
    [text]="totalAscend | distance"
    [ariaLabel]="'routendetails.elevationUp' | translate"
    icon="north_east"
  ></rrpbw-property-label>
  <rrpbw-property-label
    *ngIf="totalDescend != null"
    [text]="totalDescend | distance"
    [ariaLabel]="'routendetails.elevationDown' | translate"
    icon="south_east"
  ></rrpbw-property-label>
</div>
