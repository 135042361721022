import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { Dialog } from "../../../common/services/dialog.service";
import { DownloadService } from "../../../common/services/download.service";

@Component({
  selector: "rrpbw-route-speichern-dialog",
  templateUrl: "./route-speichern-dialog.component.html",
  styleUrls: ["./route-speichern-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteSpeichernDialogComponent implements Dialog {
  @Input()
  generateGpxFromRouteFn: () => string;

  @Input()
  generateKmlFromRouteFn: () => string;

  @Output()
  readonly closed: EventEmitter<void> = new EventEmitter();

  readonly title: string = "routenplaner.routeSpeichernDialog.dialogTitle";
  readonly description: string = "routenplaner.routeSpeichernDialog.dialogDescription";

  constructor(private downloadService: DownloadService) {}

  onSaveAsGpx(): void {
    const encodedGpxString = encodeURIComponent(this.generateGpxFromRouteFn());
    this.downloadService.download(encodedGpxString, "radroutenplaner-bw-export.gpx", "text/gpx-xml");
    this.closed.emit();
  }

  onSaveAsKml(): void {
    const encodedKmlString = encodeURIComponent(this.generateKmlFromRouteFn());
    this.downloadService.download(
      encodedKmlString,
      "radroutenplaner-bw-export.kml",
      "application/vnd.google-earth.kml+xml"
    );
    this.closed.emit();
  }
}
