import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MeldeplattformComponent } from "./smart-components/meldeplattform/meldeplattform.component";
import { MeldeplattformRoutingModule } from "src/app/meldeplattform/meldeplattform-routing.module";
import { RRPBWCommonModule } from "../common/common.module";

@NgModule({
  declarations: [MeldeplattformComponent],
  imports: [CommonModule, MeldeplattformRoutingModule, RRPBWCommonModule],
})
export class MeldeplattformModule {}
