import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HoveredHoehenprofilLocation } from "src/app/common/entities/hoehenprofil-hover-location";
import { BelagArt } from "src/app/common/entities/belag-art";
import { Radverkehrsfuehrung } from "src/app/common/entities/radverkehrsfuehrung";

@Component({
  selector: "rrpbw-hoehenprofil-tooltip",
  templateUrl: "./hoehenprofil-tooltip.component.html",
  styleUrls: ["./hoehenprofil-tooltip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoehenprofilTooltipComponent {
  public readonly belagArtColors = BelagArt.colors;
  public readonly radverkehrsfuehrungColors = Radverkehrsfuehrung.colors;

  @Input()
  hoveredHoehenprofilLocation: HoveredHoehenprofilLocation | undefined;

  @Input()
  withAttributeTooltip = true;

  @Input()
  isSmartphoneLayout = false;

  get belagArt(): BelagArt {
    return this.hoveredHoehenprofilLocation?.pathDetail?.belagArt ?? BelagArt.UNBEKANNT;
  }

  get belagArtColor(): number[] | undefined {
    return this.belagArtColors.get(this.belagArt);
  }

  get radverkehrsfuehrung(): Radverkehrsfuehrung {
    return this.hoveredHoehenprofilLocation?.pathDetail?.radverkehrsfuehrung ?? Radverkehrsfuehrung.UNBEKANNT;
  }

  get radverkehrsfuehrungArtColor(): number[] | undefined {
    return this.radverkehrsfuehrungColors.get(this.radverkehrsfuehrung);
  }
}
