import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UrlStorageKeys, UrlStorageRepository } from "src/app/common/repositories/url-storage.repository";
import { Fahrverhalten } from "src/app/common/entities/fahrverhalten";

@Component({
  selector: "rrpbw-routendetails",
  templateUrl: "./routendetails.component.html",
  styleUrls: ["./routendetails.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutendetailsComponent implements OnInit {
  fahrverhaltenFaktor = Fahrverhalten.ZUEGIG.faktor;

  constructor(private urlStorageRepository: UrlStorageRepository, private changeDetectorRef: ChangeDetectorRef) {}

  @Input()
  duration?: number;

  @Input()
  distance?: number;

  @Input()
  totalAscend?: number;

  @Input()
  totalDescend?: number;

  @Input()
  verticalLayout: boolean = false;

  @Input()
  evenlySpaces: boolean = false;

  ngOnInit(): void {
    this.urlStorageRepository.findQueryParameter<number>(UrlStorageKeys.FAHRVERHALTEN).subscribe(faktor => {
      if (faktor) {
        this.fahrverhaltenFaktor = faktor;
      } else {
        this.fahrverhaltenFaktor = Fahrverhalten.ZUEGIG.faktor;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  get durationResultingFromFahrverhalten(): number | undefined {
    return this.duration != null ? this.duration * this.fahrverhaltenFaktor : undefined;
  }
}
