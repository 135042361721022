import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Fahrverhalten } from "src/app/common/entities/fahrverhalten";
import { MatSliderChange } from "@angular/material/slider";

@Component({
  selector: "rrpbw-fahrverhalten-auswahl",
  templateUrl: "./fahrverhalten-auswahl.component.html",
  styleUrls: ["./fahrverhalten-auswahl.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrverhaltenAuswahlComponent {
  @Input()
  selectedFahrverhalten: Fahrverhalten = Fahrverhalten.ZUEGIG;

  @Output()
  fahrverhaltenChanged: EventEmitter<Fahrverhalten> = new EventEmitter();

  getDisplayText = (faktor: number): string => Fahrverhalten.ofFaktor(faktor).displayText;

  onFahrverhaltenChanged(change: MatSliderChange): void {
    const faktor = change.value;
    if (!faktor || faktor === this.selectedFahrverhalten.faktor) {
      return;
    }

    this.selectedFahrverhalten = Fahrverhalten.ofFaktor(faktor);
    this.fahrverhaltenChanged.emit(this.selectedFahrverhalten);
  }
}
