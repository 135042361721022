import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Geometry } from "ol/geom";
import { Feature } from "ol";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";

@Injectable({
  providedIn: "root",
})
export class PoiSelectionService implements OnDestroy {
  private _$selectPoi: BehaviorSubject<Feature<Geometry> | undefined> = new BehaviorSubject<
    Feature<Geometry> | undefined
  >(undefined);

  private _selectedPoiInfrastruktur: Infrastruktur | undefined;

  ngOnDestroy(): void {
    this._$selectPoi.complete();
  }

  public selectPoi(poiFeature: Feature<Geometry> | undefined, poiInfrastruktur: Infrastruktur): void {
    this._selectedPoiInfrastruktur = poiInfrastruktur;
    this._$selectPoi.next(poiFeature);
  }

  get selectedPoiInfrastruktur(): Infrastruktur | undefined {
    return this._selectedPoiInfrastruktur;
  }

  public deselectPoi(): void {
    this._selectedPoiInfrastruktur = undefined;
    this._$selectPoi.next(undefined);
  }

  get $selectPoi(): Observable<Feature<Geometry> | undefined> {
    return this._$selectPoi.asObservable();
  }
}
