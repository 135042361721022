import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ChartDataset, ChartOptions } from "chart.js";
import { GrayscaleTone, RrpBwColors } from "../../../common/utils/rrp-bw-colors";
import { AbstractHoehenprofilComponent } from "src/app/common/dumb-components/abstract-hoehenprofil.component";

@Component({
  selector: "rrpbw-hoehenprofil-preview",
  templateUrl: "./hoehenprofil-preview.component.html",
  styleUrls: ["./hoehenprofil-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoehenprofilPreviewComponent extends AbstractHoehenprofilComponent implements OnInit {
  @ViewChild("chartCanvas", { static: true })
  chartCanvas: any;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter();

  constructor() {
    super(true, false);
  }

  lineChartDatasets: ChartDataset[] = [
    {
      data: [],
      borderWidth: 3,
      borderColor: RrpBwColors.getPrimaryColor(500),
      backgroundColor: RrpBwColors.getPrimaryColor(500, 0.1),
      fill: { value: 0 },
      normalized: true,
      spanGaps: true,
      parsing: false,
      indexAxis: "x",
      type: "line",
    },
  ];

  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    parsing: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        right: 0,
        bottom: 0,
        left: -28,
        top: 0,
      },
    },
    scales: {
      x: {
        type: "linear",
        display: false,
        position: "top",
        grid: {
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone4),
        },
        ticks: {
          display: false,
          maxTicksLimit: 1,
        },
      },
      y: {
        type: "linear",
        grid: {
          offset: false,
          drawBorder: false,
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone4),
        },
        ticks: {
          mirror: true,
          padding: 10,
          z: 1,
          labelOffset: -12,
          font: {
            size: 16,
          },
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone11),
          maxTicksLimit: 2,
          callback(value: number | string): string | number | null | undefined {
            return `${Math.round(value as number)} m`;
          },
        },
      },
    },
    hover: {
      mode: undefined,
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      decimation: {
        enabled: true,
        algorithm: "lttb",
        samples: 50,
        threshold: 50,
      },
    },
  };

  ngOnInit(): void {
    const context = this.chartCanvas.nativeElement.getContext("2d");
    this.initChart(context);
  }

  onClick(): void {
    this.clicked.emit();
  }
}
