import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColorService {
  constructor() {}

  toNormalizedRgbValues(hex: string): [number, number, number] {
    const rgb = this.toRgbValues(hex);

    rgb[0] = rgb[0] / 255;
    rgb[1] = rgb[1] / 255;
    rgb[2] = rgb[2] / 255;

    return rgb;
  }

  toRgbValues(hex: string): [number, number, number] {
    const rgb: [number, number, number] = [0, 0, 0];

    if (hex.length === 4) {
      // #abc -> #aabbcc
      hex = "#" + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    }

    rgb[0] = parseInt(hex[1] + hex[2], 16);
    rgb[1] = parseInt(hex[3] + hex[4], 16);
    rgb[2] = parseInt(hex[5] + hex[6], 16);

    return rgb;
  }

  toPaleBrightHex(hex: string, saturation: number): string {
    const iconColorRgb = this.toRgbValues(hex);
    iconColorRgb[0] = Math.round(Math.min(255, Math.max(0, 255 + saturation * (iconColorRgb[0] - 255))));
    iconColorRgb[1] = Math.round(Math.min(255, Math.max(0, 255 + saturation * (iconColorRgb[1] - 255))));
    iconColorRgb[2] = Math.round(Math.min(255, Math.max(0, 255 + saturation * (iconColorRgb[2] - 255))));
    return this.rgbToHex(iconColorRgb);
  }

  rgbToHex(values: [number, number, number]): string {
    const hexValues = values.map(v => {
      const hexValue = v.toString(16);
      return hexValue.length === 1 ? "0" + hexValue : hexValue;
    });
    return "#" + hexValues.join("");
  }
}
