import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TimeService {
  getEpochMillis(): number {
    return new Date().getTime();
  }
}
