import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Observable, of, Subscription, switchMap } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { INPUT_DEBOUNCE_TIME } from "../../../constants";
import { Stringer } from "../../../common/entities/stringer";
import { Adresse } from "src/app/routenplaner/entities/adresse";

@Component({
  selector: "rrpbw-adresse-suche",
  templateUrl: "./adresse-suche.component.html",
  styleUrls: ["./adresse-suche.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdresseSucheComponent implements OnInit, OnDestroy {
  @Input() label = "adresseSuche.label";
  @Input() placeholder = "adresseSuche.placeholder";
  @Input() labelPrefix: string;
  @Input() debounceTime: number = INPUT_DEBOUNCE_TIME;
  @Input() itemFn: (query: string) => Observable<Stringer[]> = () => of([]);
  @Input() $item: Observable<Stringer | undefined> = of();
  @Input() alwaysShowDeleteButton = false;

  @Output() deleteButtonClicked: EventEmitter<void> = new EventEmitter();
  @Output() itemSelected: EventEmitter<Adresse> = new EventEmitter();
  @Output() cleared: EventEmitter<void> = new EventEmitter();

  formGroup: FormGroup;
  items: Stringer[] = [];

  private _showDeleteButton: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef, formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({
      search: null,
    });

    this.search.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        switchMap(query => {
          if (query instanceof Adresse) {
            // Wenn der neue Eintrag eine Adresse ist, wurde ein Eintrag aus der Autovervollständigung ausgewählt. In
            // diesem Fall wollen wir keine item-query abschicken, weil wir ja gerade ein solches item ausgewählt haben.
            return of();
          }
          return this.itemFn(query);
        })
      )
      .subscribe(items => {
        // Keine items (nicht mal ein leeres Array) bedeutet, dass Eintrag aus Autocomplete-Liste ausgewählt wurde. In
        // dem Fall machen wir nichts.
        if (items) {
          // Gibt es items oder ist das array leer, wollen wir diese setzen und ggf. das form zurücksetzen.
          if (items.length > 0) {
            this.items = items;
          } else {
            this.items = [];
            this.cleared.next();
            this._showDeleteButton = false;
          }
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.$item.subscribe(item => {
        this._showDeleteButton = true;

        if (item) {
          this.search.setValue(item.string, { emitEvent: false });
        } else {
          this.search.setValue(null, { emitEvent: false });
        }

        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.itemSelected.complete();
  }

  optionSelected(item: Adresse): void {
    this._showDeleteButton = true;
    this.search.setValue(item.string, { emitEvent: false });
    this.itemSelected.next(item);
  }

  onDeleteButtonClicked(): void {
    this._showDeleteButton = false;
    this.items = [];
    this.search.setValue(null);
    this.deleteButtonClicked.emit();
  }

  get search(): AbstractControl {
    return this.formGroup.controls.search;
  }

  get showDeleteButton(): boolean {
    return this._showDeleteButton || this.alwaysShowDeleteButton;
  }
}
