import { Injectable, OnDestroy } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

export enum ScreenLayout {
  Smartphone,
  Tablet,
  Desktop,
}

@Injectable({
  providedIn: "root",
})
export class LayoutService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private $screenLayout: BehaviorSubject<ScreenLayout> = new BehaviorSubject<ScreenLayout>(ScreenLayout.Desktop);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.subscriptions.push(
      this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
        if (result.matches) {
          this.$screenLayout.next(ScreenLayout.Smartphone);
        }
      }),
      this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Small]).subscribe(result => {
        if (result.matches) {
          this.$screenLayout.next(ScreenLayout.Tablet);
        }
      }),
      this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge]).subscribe(result => {
        if (result.matches) {
          this.$screenLayout.next(ScreenLayout.Desktop);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get screenLayout(): Observable<ScreenLayout> {
    return this.$screenLayout.asObservable();
  }
}
