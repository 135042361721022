<div class="sidenav-widget-header top-margin-small" *ngIf="!isSmartphoneLayout">
  <h1 class="sidenav-widget-title bold">{{ "routenplaner.fahrradrouten.fahrradrouten" | translate }}</h1>
</div>
<div
  class="selected-routes-list"
  [class.selected-routes-list-mobile]="isSmartphoneLayout"
  *ngIf="selectedFahrradrouten$ | async as selectedFahrradrouten"
  [attr.aria-label]="'routenplaner.fahrradrouten.angezeigteFahrradrouten' | translate"
>
  <div
    class="focus selected-route"
    *ngFor="let route of selectedFahrradrouten"
    (mouseenter)="onHover(route)"
    (mouseleave)="onUnHover()"
  >
    <div
      tabindex="0"
      class="selected-route-text"
      (click)="onClickRoute(route)"
      (keydown.enter)="onClickRoute(route)"
      [attr.aria-label]="'routenplaner.fahrradrouten.detailsAnzeigen' | translate : { name: route.name }"
    >
      <span>{{ route.name }}</span>
    </div>
    <div class="button-container">
      <button
        class="focus"
        mat-icon-button
        (click)="onZoomToFahrradroute(route, $event)"
        [attr.aria-label]="'routenplaner.fahrradrouten.angezeigteFahrradrouteFokussieren' | translate"
      >
        <mat-icon>gps_fixed</mat-icon>
      </button>
      <button
        class="focus"
        mat-icon-button
        (click)="onRouteRemoved(route)"
        [attr.aria-label]="'routenplaner.fahrradrouten.angezeigteFahrradrouteEntfernen' | translate"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
</div>
<mat-form-field appearance="standard" *ngIf="routen.length > 0; else noRoutes">
  <mat-label>{{ "routenplaner.fahrradrouten.fahrradrouteAnzeigen" | translate }}</mat-label>
  <input
    matInput
    [matAutocomplete]="auto"
    [formControl]="inputFormControl"
    #trigger="matAutocompleteTrigger"
    (focus)="onInputFocus($event, trigger)"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="routeDisplayName"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option *ngFor="let option of filteredOptions" [value]="option" [title]="option.name">
      {{ option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<ng-template #noRoutes>
  <span *ngIf="!loading">{{ "fahrradrouten.list.keineRouten" | translate }}</span>
  <mat-spinner *ngIf="loading"></mat-spinner>
</ng-template>
