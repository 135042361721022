<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuLocation.x + 'px'"
  [style.top]="contextMenuLocation.y + 'px'"
  [matMenuTriggerFor]="contextMenu"
></div>
<mat-menu [hasBackdrop]="false" #contextMenu="matMenu" [yPosition]="isMobileDevice ? 'above' : 'below'">
  <ng-template matMenuContent>
    <ng-container *ngFor="let menuEntry of entries">
      <button mat-menu-item (click)="menuEntry.callbackFn(contextMenuGeoLocation)">
        <mat-icon
          role="img"
          *ngIf="useSymbols"
          class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color"
          aria-hidden="true"
          data-mat-icon-type="font"
          >{{ menuEntry.symbol }}</mat-icon
        >
        {{ menuEntry.string | translate }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
