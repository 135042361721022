import { NgModule } from "@angular/core";
import { PropertyLabelComponent } from "./dumb-components/property-label/property-label.component";
import { DistancePipe } from "./pipes/distance.pipe";
import { DurationPipe } from "./pipes/duration.pipe";
import { PropertyLabelModule } from "./dumb-components/property-label/property-label.module";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogWrapperModule } from "./dumb-components/dialog-wrapper/dialog-wrapper.module";
import { AdditionalTouchEventsDirective } from "./utils/additional-touch-events-directive";
import { LoadingBarComponent } from "./smart-components/loading-bar/loading-bar.component";
import { LoadingBarModule } from "./smart-components/loading-bar/loading-bar.module";
import { InformationenAnzeigeLayoutComponent } from "./dumb-components/informationen-anzeige-layout/informationen-anzeige-layout.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { RoutendetailsComponent } from "src/app/common/dumb-components/routendetails/routendetails.component";
import { HoehenprofilComponent } from "src/app/common/dumb-components/hoehenprofil/hoehenprofil.component";
import { HoehenprofilTooltipComponent } from "./dumb-components/hoehenprofil-tooltip/hoehenprofil-tooltip.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatPaginatorIntlLocalizationService } from "src/app/common/services/mat-paginator-intl-localization.service";
import { ColorToCssPipe } from "./pipes/color-to-css.pipe";
import { AccessabilityTabCircleGroupDirective } from "src/app/common/utils/accessability-tab-circle-group.directive";
import { AccessabilityTabCircleElementDirective } from "src/app/common/utils/accessability-tab-circle-element.directive";

@NgModule({
  imports: [
    PropertyLabelModule,
    MatDialogModule,
    DialogWrapperModule,
    LoadingBarModule,
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [
    PropertyLabelComponent,
    DurationPipe,
    DistancePipe,
    AdditionalTouchEventsDirective,
    LoadingBarComponent,
    InformationenAnzeigeLayoutComponent,
    RoutendetailsComponent,
    HoehenprofilComponent,
    HoehenprofilTooltipComponent,
    AccessabilityTabCircleGroupDirective,
    AccessabilityTabCircleElementDirective,
  ],
  declarations: [
    DistancePipe,
    DurationPipe,
    AdditionalTouchEventsDirective,
    InformationenAnzeigeLayoutComponent,
    RoutendetailsComponent,
    HoehenprofilComponent,
    HoehenprofilTooltipComponent,
    ColorToCssPipe,
    AccessabilityTabCircleGroupDirective,
    AccessabilityTabCircleElementDirective,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlLocalizationService }],
})
export class RRPBWCommonModule {}
