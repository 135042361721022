<rrpbw-informationen-anzeige-layout>
  <h1>Meldeplattform Radinfrastruktur Baden-Württemberg</h1>
  <p>
    Ist Ihnen während Ihrer Fahrt auf dem RadNETZ etwas Besonderes aufgefallen? Ist zum Beispiel ein Schild beschädigt,
    verdreht oder gibt es Störungen und Gefahrenstellen? Wir freuen uns aber auch über positive Rückmeldungen und
    Wünsche!
  </p>
  <p>
    Für diese Fälle gibt es bald eine schnelle und unkomplizierte Möglichkeit Feedback zu geben. Deshalb entsteht hier
    die neue Meldeplattform für die Radinfrastruktur in Baden-Württemberg.
  </p>
  <p>
    Aktuell sind nur Meldungen zur wegweisenden Beschilderung am RadNETZ über
    <a [href]="generateEmailLink()">radnetz@nvbw.de</a>
    möglich.
  </p>
</rrpbw-informationen-anzeige-layout>
