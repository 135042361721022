import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import TileLayer from "ol/layer/Tile";
import { TileWMS } from "ol/source";
import { GeoserverFeatureService } from "src/app/karte/services/geoserver-feature.service";
import { Extent } from "ol/extent";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of } from "rxjs";
import GeoJSON from "ol/format/GeoJSON";
import { map } from "rxjs/operators";
import { MAP_PROJECTION } from "src/app/constants";
import { SelectedInfrastrukturFeature } from "src/app/karte/entities/selected-infrastruktur-feature";
import { DetailProperties } from "src/app/karte/entities/detail-properties";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";

@Component({
  selector: "rrpbw-geoserver-wms-layer",
  templateUrl: "./geoserver-wms-layer.component.html",
  styleUrls: ["./geoserver-wms-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeoserverWmsLayerComponent implements OnInit, OnDestroy, GeoserverFeatureService, OnChanges {
  @Input()
  infrastruktur: Infrastruktur;

  @Input()
  signatur: string | undefined;

  @Input()
  namePropertyKey: string = DetailProperties.NAME.key;

  @Input()
  set visible(value: boolean | null) {
    this.tileLayer.setVisible(value ?? false);
  }

  readonly tileLayer: TileLayer<TileWMS>;

  constructor(public karteBaseService: KarteBaseService, private httpClient: HttpClient) {
    this.tileLayer = new TileLayer();
  }

  getFeatures(extent: Extent): Observable<SelectedInfrastrukturFeature[]> {
    const bbox = extent.join(",");
    const layerNames = this.infrastruktur.getWFSLayerNames();
    const workspace = this.infrastruktur.getWFSWorkspace();

    // TODO Hier eigentlich pro layerName einen Request abfeuern, damit wir am Feature genau wissen woher es kommt. Hat aber geringe Prio.
    return this.httpClient
      .get<string>(
        `/api/geoserver/${workspace}/wms?SERVICE=WFS&VERSION=1.3.0&REQUEST=GetFeature&outputFormat=application%2Fjson&typeName=${workspace}:${layerNames}&bbox=${bbox},${MAP_PROJECTION}&srsName=${MAP_PROJECTION}`
      )
      .pipe(
        map(geojsonString => {
          const features = new GeoJSON()
            .readFeatures(geojsonString)
            .slice(0, 3)
            .map(feature => {
              feature.setProperties(feature.getProperties() as { [p: string]: any });
              feature.set(DetailProperties.NAME.key, feature.get(this.namePropertyKey));
              feature.set("layerNames", layerNames);
              return feature;
            });
          return features.map(f => ({ feature: f, infrastruktur: this.infrastruktur }));
        }),
        catchError(error => {
          console.error("Error caught while querying WFS:", error);
          return of();
        })
      );
  }

  isActive(): boolean {
    return this.tileLayer.getVisible();
  }

  ngOnInit(): void {
    this.tileLayer.setZIndex(500 + this.infrastruktur.zIndex);

    this.karteBaseService.addLayer(this.tileLayer);
  }

  ngOnDestroy(): void {
    this.karteBaseService.removeLayer(this.tileLayer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.signatur) {
      const params: { [p: string]: any } = { LAYERS: this.infrastruktur.layerNames, TILED: true, FORMAT: "image/png8" };
      if (this.signatur) {
        params.STYLES = this.signatur;
      }
      this.tileLayer.setSource(
        new TileWMS({
          url: "/api/geoserver/" + this.infrastruktur.layerWorkspace + "/wms",
          params,
          serverType: "geoserver",
        })
      );
    }
  }
}
