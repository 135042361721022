import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "rrpbw-mobile-karte-bedienhinweis",
  templateUrl: "./mobile-karte-bedienhinweis.component.html",
  styleUrls: ["./mobile-karte-bedienhinweis.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileKarteBedienhinweisComponent {
  @Output() routenplanungBearbeiten: EventEmitter<void> = new EventEmitter();
}
