import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Adresse } from "../../entities/adresse";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { Stringer } from "../../../common/entities/stringer";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { onKeyboardSubmit } from "src/app/common/utils/accessibility";
import { WegpunktEditorService } from "../../services/wegpunkt-editor.service";

export interface AdresseAutocomplete {
  id: number;
  $item: Subject<Stringer | undefined>;
  adresse?: Adresse;
}

export interface WegpunkteBearbeitet {
  adressen: (Adresse | undefined)[];
  zoomToRoute: boolean;
}

@Component({
  selector: "rrpbw-wegpunkt-editor",
  templateUrl: "./wegpunkt-editor.component.html",
  styleUrls: ["./wegpunkt-editor.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class WegpunktEditorComponent implements WegpunktEditorService {
  onKeyboardSubmit = onKeyboardSubmit;

  @Input() adresseQueryChangedFn: (query: string) => Observable<Stringer[]>;

  @Output() wegpunkteBearbeitet: EventEmitter<WegpunkteBearbeitet> = new EventEmitter();

  adresseAutocomplete: AdresseAutocomplete[] = [
    { id: 1, $item: new ReplaySubject(1) },
    { id: 2, $item: new ReplaySubject(1) },
  ];

  adresseAutocompleteCounter: number = this.adresseAutocomplete.length;
  zwischenzieleAusgeblendet: boolean = false;
  dragInDropInProgress: boolean = false;

  enableDragging: boolean = false;

  onWegpunktItemSelected(item: Adresse, index: number): void {
    this.adresseAutocomplete[index].adresse = item;
    this.emitWegpunkteBearbeitet();
  }

  onAdresseCleared(index: number): void {
    this.adresseAutocomplete[index].adresse = undefined;
    this.emitWegpunkteBearbeitet();
  }

  onAddWegpunktClicked(index: number): void {
    this.adresseAutocompleteCounter++;
    this.adresseAutocomplete.splice(index + 1, 0, {
      id: this.adresseAutocompleteCounter,
      $item: new Subject<Stringer | undefined>(),
    });
  }

  onRemoveWegpunktClicked(index: number): void {
    if (this.adresseAutocomplete.length <= 2) {
      return;
    }

    this.adresseAutocomplete.splice(index, 1);

    this.emitWegpunkteBearbeitet();
  }

  onSwitchWegpunkteClicked(): void {
    this.adresseAutocomplete.reverse();

    this.emitWegpunkteBearbeitet();
  }

  onReorder(event: CdkDragDrop<void>): void {
    moveItemInArray(this.adresseAutocomplete, event.previousIndex, event.currentIndex);

    this.reorder();
  }

  getLabel(index: number): string {
    if (index === 0) {
      return "wegpunktEditor.start";
    } else if (index === this.zielIndex) {
      return "wegpunktEditor.ziel";
    } else {
      return "wegpunktEditor.wegpunkt";
    }
  }

  getLabelPrefix(index: number): string {
    if (index === 0) {
      return "A";
    } else if (index === this.zielIndex) {
      return "B";
    } else {
      return index.toString();
    }
  }

  alwaysShowDeleteButton(index: number): boolean {
    return index !== 0 && index !== this.zielIndex;
  }

  toggleZwischenzieleAusblenden(): void {
    this.zwischenzieleAusgeblendet = !this.zwischenzieleAusgeblendet;
  }

  wegpunktAnlegen(adresse: Adresse, index: number | undefined, wegpunktErsetzen: boolean, zoomToRoute = false): void {
    this.adresseAutocompleteCounter++;

    let insertIndex = index;
    if (insertIndex == null) {
      insertIndex = this.adresseAutocomplete.length - 1;
    }

    this.adresseAutocomplete.splice(insertIndex, wegpunktErsetzen ? 1 : 0, {
      id: this.adresseAutocompleteCounter,
      $item: new BehaviorSubject<Stringer | undefined>(adresse),
      adresse: adresse,
    });
    this.emitWegpunkteBearbeitet(zoomToRoute);
  }

  wegpunktEntfernen(adresse: Adresse): void {
    const index = this.adresseAutocomplete.findIndex(value =>
      value.adresse?.geoLocation.isEqualTo(adresse.geoLocation)
    );

    if (index !== -1) {
      const autocomplete = this.adresseAutocomplete[index];
      if (this.adresseAutocomplete.length <= 2) {
        this.onAdresseCleared(index);
        autocomplete.$item.next(undefined);
      } else {
        this.onRemoveWegpunktClicked(index);
      }
    }
  }

  get zielIndex(): number {
    return this.adresseAutocomplete.length - 1;
  }

  get showZwischenziele(): boolean {
    return this.adresseAutocomplete.length > 2;
  }

  get canSwitchWegpunkte(): boolean {
    return !!this.adresseAutocomplete[0]?.adresse && !!this.adresseAutocomplete[this.zielIndex]?.adresse;
  }

  get zwischenzieleEinblendenToggleLabel(): string {
    if (this.adresseAutocomplete.length === 3) {
      return "wegpunktEditor.zwischenzieleToggle.1Einblenden";
    }
    return "wegpunktEditor.zwischenzieleToggle.nEinblenden";
  }

  get zwischenzieleAusblendenToggleLabel(): string {
    if (this.adresseAutocomplete.length === 3) {
      return "wegpunktEditor.zwischenzieleToggle.1Ausblenden";
    }
    return "wegpunktEditor.zwischenzieleToggle.nAusblenden";
  }

  get zwischenzieleEinblendenAriaLabel(): string {
    if (this.adresseAutocomplete.length === 3) {
      return "wegpunktEditor.zwischenzieleToggle.1EinblendenLabel";
    }
    return "wegpunktEditor.zwischenzieleToggle.nEinblendenLabel";
  }

  get zwischenzieleSichtbar(): boolean {
    return !this.zwischenzieleAusgeblendet || this.dragInDropInProgress;
  }

  reorder(): void {
    this.adresseAutocomplete.forEach(autocomplete => {
      autocomplete.$item.next(autocomplete.adresse);
    });

    this.emitWegpunkteBearbeitet();
  }

  private emitWegpunkteBearbeitet(zoomToRoute = true): void {
    const adressen = this.adresseAutocomplete.map(autocomplete => autocomplete.adresse);

    this.wegpunkteBearbeitet.emit({ adressen, zoomToRoute });

    this.adresseAutocomplete
      .filter(a => !!a.adresse)
      .forEach(autocomplete => autocomplete.$item.next(autocomplete.adresse));
  }

  onKeydown(event: KeyboardEvent, index: number): void {
    if (event.key === "ArrowUp" || event.key === "Up") {
      if (index === 0) {
        return;
      }

      moveItemInArray(this.adresseAutocomplete, index, index - 1);
    } else if (event.key === "ArrowDown" || event.key === "Down") {
      if (index === this.zielIndex) {
        return;
      }

      moveItemInArray(this.adresseAutocomplete, index, index + 1);
    } else {
      return;
    }

    this.reorder();
  }

  onMousedown(): void {
    this.enableDragging = true;
  }

  onMousemove(): void {
    if (this.enableDragging) {
      this.dragInDropInProgress = true;
    }
  }

  onMouseup(): void {
    this.enableDragging = false;
    this.dragInDropInProgress = false;
  }

  onDragEnded(): void {
    this.dragInDropInProgress = false;
    this.enableDragging = false;
  }
}
