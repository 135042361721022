import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";

export interface PoiToggleMenuEntry {
  label: string;
  icon: string;
  checked?: boolean;
  onChecked: (_: boolean) => void;
}

@Component({
  selector: "rrpbw-poi-toggle-menu",
  templateUrl: "./poi-toggle-menu.component.html",
  styleUrls: ["./poi-toggle-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoiToggleMenuComponent {
  @Input()
  menuEntries: Infrastruktur[] = [];
}
