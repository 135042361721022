import { fromLonLat } from "ol/proj";
import { getDistance } from "ol/sphere";
import * as turf from "@turf/turf";
import { GeoJSONLineString } from "ol/format/GeoJSON";
import { SinglePathDetail } from "src/app/common/entities/single-path-detail";

export class Route {
  readonly points: number[][];
  readonly elevations: number[];
  readonly distancesToStart: number[];
  readonly hasElevationData: boolean;

  constructor(
    public coordinates: number[][],
    public pathDetails: SinglePathDetail[] = [],
    public distance?: number,
    public totalAscend?: number,
    public totalDescend?: number,
    public duration?: number
  ) {
    this.points = this.coordinates.map(coords => fromLonLat(coords));
    this.elevations = this.coordinates.map(coords => coords[2]);
    this.hasElevationData = this.coordinates.some(c => c[2] != null && c[2] !== 0);
    this.distancesToStart = this.calculateDistancesToStart();
  }

  calculateDistancesToStart(): number[] {
    const distancesToStart: number[] = [0];
    let previousCoord = this.coordinates[0];
    let distance = 0;
    for (let i = 1; i < this.coordinates.length; ++i) {
      distance += getDistance(previousCoord, this.coordinates[i]);
      distancesToStart.push(distance);
      previousCoord = this.coordinates[i];
    }

    return distancesToStart;
  }

  /**
   * Berechnet die Distanzen gegebener Wegpunkte zum Startpunkt entlag der Route
   *
   * @param wegpunkte die Wegpunkte in LonLat.
   */
  getWegpunktDistances(wegpunkte: number[][]): number[] {
    const distancesToStart: number[] = [];
    let distance = 0;

    let line: GeoJSONLineString = {
      coordinates: this.coordinates.map(coor => coor.slice(0, 2)),
      type: "LineString",
    };

    wegpunkte.forEach(wegpunkt => {
      const closestPoint = turf.nearestPointOnLine(line, wegpunkt);
      distance += (closestPoint.properties.location ?? 0) * 1000;
      distancesToStart.push(distance);

      const rest = turf.lineSlice(closestPoint, this.coordinates[this.coordinates.length - 1], line);
      line = rest.geometry;
    });

    return distancesToStart;
  }
}
