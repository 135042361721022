import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class TitleService {
  constructor(private title: Title, private translateService: TranslateService) {}

  setSuffix(suffixLocation: string): void {
    this.translateService
      .get(["app.name", suffixLocation])
      .subscribe(result => this.title.setTitle(result["app.name"] + " - " + result[suffixLocation]));
  }
}
