import { Component, Input } from "@angular/core";

@Component({
  selector: "rrpbw-divider",
  templateUrl: "./divider.component.html",
  styleUrls: ["./divider.component.scss"],
})
export class DividerComponent {
  @Input() contrast: boolean = false;
  @Input() icon: string = "add";
  @Input() svgIcon: string;
  @Input() ariaLabel: string;
}
