<rrpbw-hoehenprofil-tooltip
  [hoveredHoehenprofilLocation]="hoveredHoehenprofilLocation"
  [isSmartphoneLayout]="true"
></rrpbw-hoehenprofil-tooltip>
<div class="chart-envelope">
  <div class="chart-absolute">
    <canvas
      #chartCanvas
      [style.display]="!!routeMitWegpunkten ? 'block' : 'none'"
      (touchcancel)="onUnhover()"
      (mouseout)="onUnhover()"
      (mousemove)="onMouseMove()"
      (touchmove)="onMouseMove()"
    ></canvas>
  </div>
</div>
