import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ColorService } from "../../../common/services/color.service";
import { SelectedInfrastrukturFeature } from "src/app/karte/entities/selected-infrastruktur-feature";
import { DetailProperties } from "src/app/karte/entities/detail-properties";

@Component({
  selector: "rrpbw-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsComponent {
  DetailProperties = DetailProperties;

  @Input() showHeading: boolean = true;
  @Input() layerName: string;
  @Input() iconPath: string;
  @Input() iconColor: string;

  private _selectedFeature: SelectedInfrastrukturFeature | undefined;
  private _detailProperties: Map<string, string>;

  @Input()
  set selectedFeature(selection: SelectedInfrastrukturFeature | undefined) {
    this._selectedFeature = selection;
    if (!selection || !selection.infrastruktur || !selection.infrastruktur.detailProperties) {
      this._detailProperties = new Map<string, string>();
    } else {
      this._detailProperties = selection.infrastruktur.detailProperties?.filterDetailProperties(
        selection.feature.getProperties()
      );
    }
  }

  get selectedFeature(): SelectedInfrastrukturFeature | undefined {
    return this._selectedFeature;
  }

  get detailProperties(): Map<string, string> {
    return this._detailProperties;
  }

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  constructor(private translateService: TranslateService, private colorService: ColorService) {}

  // alleSichtbarenPropertyKeys
  get propertyKeys(): string[] {
    return Array.from(this._detailProperties.keys() ?? []).filter(
      key =>
        key !== DetailProperties.SOURCE.key &&
        key !== DetailProperties.SOURCE_URL.key &&
        this.selectedFeature?.infrastruktur.detailProperties?.isVisible(key)
    );
  }

  get ariaLabelName(): string {
    return this.getTitle(DetailProperties.NAME.key);
  }

  get ariaLabelNameWithValue(): string {
    return this.ariaLabelName + ": " + this.getValue(DetailProperties.NAME.key);
  }

  get ariaLabelLayerWithValue(): string {
    return this.translateService.instant("detailProperties.layerLabel") + ": " + this.layerName;
  }

  getTitle(propertyKey: string): string {
    const property = this.selectedFeature?.infrastruktur.detailProperties?.get(propertyKey);
    const translateKey = property?.translateKey ?? property?.key ?? propertyKey;
    return this.translateService.instant("detailProperties.label." + translateKey);
  }

  getValue(propertyKey: string): string | undefined {
    let value = this._detailProperties.get(propertyKey);

    const property = this.selectedFeature?.infrastruktur.detailProperties?.get(propertyKey);
    // Manche Properties haben Werte wie "small", die man übersetzen möchte. Andere Properties, wie Namen oder Nummern,
    // möchte man nicht übersetzen.
    if ((value || typeof value === "boolean") && property?.isEnum) {
      if (propertyKey === DetailProperties.KATEGORIEN.key || propertyKey === DetailProperties.NETZKLASSE.key) {
        value = value
          .split(";")
          .map(v => this.translateService.instant("detailProperties.values." + v))
          .join(", ");
      } else {
        value = this.translateService.instant("detailProperties.values." + String(value));
      }
    }

    if (!value) {
      return undefined;
    }

    if (propertyKey === DetailProperties.PLACE.key) {
      let street = "";
      let postcode = "";

      if (this._detailProperties.has(DetailProperties.STREET.key)) {
        street = this._detailProperties.get(DetailProperties.STREET.key) + ",";
      }
      if (this._detailProperties.has(DetailProperties.POSTCODE.key)) {
        postcode = this._detailProperties.get(DetailProperties.POSTCODE.key) ?? "";
      }
      value = [street, postcode, value].join(" ");
    } else if (propertyKey === DetailProperties.ELEVATION.key) {
      value += " m üNHN";
    } else if (propertyKey === DetailProperties.EMAIL.key) {
      value = `<a href="mailto:${value}">${value}</a>`;
    } else if (propertyKey === DetailProperties.PHONE.key) {
      value = `<a href="tel:${value}">${value}</a>`;
    } else if (
      [
        DetailProperties.WEBSITE.key,
        DetailProperties.HOMEPAGE.key,
        DetailProperties.BUCHUNGS_URL.key,
        DetailProperties.DEEP_LINK.key,
      ].includes(propertyKey)
    ) {
      const domain = new URL(value).hostname;
      value = `<a href="${value}" target="_blank">${domain}</a>`;
    } else if (
      propertyKey === DetailProperties.LAENGE_DER_HAUPTSTRECKE.key ||
      propertyKey === DetailProperties.OFFIZIELLE_LAENGE.key
    ) {
      value = (+value / 1000).toLocaleString("de", { minimumFractionDigits: 0, maximumFractionDigits: 3 });
    } else if ([DetailProperties.VON_DATUM.key, DetailProperties.BIS_DATUM.key].includes(propertyKey)) {
      // Wir betrachten nur Datümer, daher ist die Uhrzeit oder Zeitzone egal.
      value = value.replace(/[TZ].*$/, "");
      value = new Date(value).toLocaleDateString();
    } else if ([DetailProperties.VON_UHRZEIT.key, DetailProperties.BIS_UHRZEIT.key].includes(propertyKey)) {
      value = new Date(value).toLocaleTimeString();
    } else if (
      [
        DetailProperties.GEBUEHR_TAG.key,
        DetailProperties.GEBUEHR_MONAT.key,
        DetailProperties.GEBUEHR_JAHR.key,
      ].includes(propertyKey)
    ) {
      value = String(value).padStart(3);
      value = value.substring(0, value.length - 2) + "," + value.substring(value.length - 2) + " €";
    } else if (propertyKey === DetailProperties.LINES.key) {
      value = this.getGroupedLinesWithSamePrefix(value);
    }
    return value;
  }

  /**
   * Erstellt <p> Elemente pro Satz an Einträgen, die den gleichen alphabetischen Präfix haben. Die Einträge sind alle
   * Kommasepariert. Also z.B.:
   * "a1,b1,123,a2"  -->  "<p>123</p><p>a1, a2</p><p>b1</p>"
   */
  private getGroupedLinesWithSamePrefix(value: string): string {
    // Präfixe ermitteln nach denen gruppiert werden soll
    const prefixe = value.replace(/\d/g, "").replace(/,*/, ",").split(",");
    const uniquePrefixe = [...new Set(prefixe)];

    const groups = new Map<string, string[]>();
    const items = value.split(",");

    // Nach Präfixen gruppieren
    uniquePrefixe.forEach(prefix => {
      items.forEach(item => {
        const prefixMatch = item.match(new RegExp(`^${prefix}[0-9].*`));
        if (prefixMatch) {
          // Gruppe zum Präfix ermitteln und wenns der erste Eintrag dieser Gruppe ist, dann neue Liste in der Map erstellen.
          let group = groups.get(prefix);
          if (!group) {
            group = [];
            groups.set(prefix, group);
          }
          group.push(item);
        }
      });
    });

    // Pro (nicht leerer) Gruppe einen in sich alphabetisch sortierten kommaseparierten String bauen. Diese Liste ist
    // dann aber selbst noch nicht sortiert, kann also ["z1, z2", "a1, a2"] sein statt ["a1, a2", "z1, z2"].
    const groupStrings: string[] = [];
    groups.forEach((group, prefix) => {
      if (group.length > 0) {
        // Nur Gruppen in denen es Liniennamen gibt, sollen sortiert und in einen kommaseparierten String konvertiert werden.
        groupStrings.push(group.sort().join(", "));
      }
    });

    // Nur für Gruppen mit mehr Elementen <p> Elemente erzeugen. Ansonsten sieht das komisch aus und weicht von allen
    // anderen einzeiligen Einträgen ab.
    if (groupStrings.length === 1) {
      return groupStrings[0];
    } else {
      return groupStrings
        .sort()
        .map(group => "<p>" + group + "</p>")
        .join("");
    }
  }

  getIcon(key: string): string {
    return (
      this.selectedFeature?.infrastruktur?.detailProperties?.get(key)?.icon ?? DetailProperties.DEFAULT_PROPERTY_ICON
    );
  }

  getSvgFilterMatrix(): string {
    const rgb = this.colorService.toNormalizedRgbValues(this.iconColor);
    return `0 0 0 0 ${rgb[0]}
    0 0 0 0 ${rgb[1]}
    0 0 0 0 ${rgb[2]}
    0 0 0 1 0`;
  }
}
