import { NgModule } from "@angular/core";
import { KarteComponent } from "./smart-components/karte/karte.component";
import { MarkerLayerComponent } from "./smart-components/marker-layer/marker-layer.component";
import { KarteControlsSidebarComponent } from "./smart-components/karte-controls-sidebar/karte-controls-sidebar.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { KarteIconButtonComponent } from "./dumb-components/karte-icon-button/karte-icon-button.component";
import { LayerSelectionMenuComponent } from "./smart-components/layer-selection-menu/layer-selection-menu.component";
import { MatRadioModule } from "@angular/material/radio";
import { CommonModule } from "@angular/common";
import { TileLayerComponent } from "./smart-components/tile-layer/tile-layer.component";
import { TranslateModule } from "@ngx-translate/core";
import { RouteLayerComponent } from "./smart-components/route-layer/route-layer.component";
import { KarteContextMenuComponent } from "./smart-components/karte-context-menu/karte-context-menu.component";
import { MatMenuModule } from "@angular/material/menu";
import { HintergrundebenenComponent } from "./smart-components/hintergrundebenen/hintergrundebenen.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { HintergrundebenePreviewComponent } from "./dumb-components/hintergrundebene-preview/hintergrundebene-preview.component";
import { KarteAttributionsComponent } from "./dumb-components/karte-attributions/karte-attributions.component";
import { LocateMeLayerComponent } from "./smart-components/locate-me-layer/locate-me-layer.component";
import { HoehenprofilLayerComponent } from "./smart-components/hoehenprofil-layer/hoehenprofil-layer.component";
import { KarteViewOnlyComponent } from "./smart-components/karte-view-only/karte-view-only.component";
import { RRPBWCommonModule } from "../common/common.module";
import { GrenzeLayerComponent } from "./smart-components/grenze-layer/grenze-layer.component";
import { PoiLayerComponent } from "./smart-components/poi-layer/poi-layer.component";
import { PoiToggleMenuComponent } from "./dumb-components/poi-toggle-menu/poi-toggle-menu.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { GeoserverWmsLayerComponent } from "src/app/karte/smart-components/geoserver-wms-layer/geoserver-wms-layer.component";
import { PoiTooltipComponent } from "./smart-components/poi-tooltip/poi-tooltip.component";
import { RadnetzToggleMenuComponent } from "./dumb-components/radnetz-toggle-menu/radnetz-toggle-menu.component";
import { DetailsComponent } from "src/app/karte/dumb-components/details/details.component";
import { MatListModule } from "@angular/material/list";

@NgModule({
  declarations: [
    KarteComponent,
    KarteControlsSidebarComponent,
    KarteIconButtonComponent,
    MarkerLayerComponent,
    LayerSelectionMenuComponent,
    TileLayerComponent,
    RouteLayerComponent,
    KarteContextMenuComponent,
    HintergrundebenenComponent,
    HintergrundebenePreviewComponent,
    KarteAttributionsComponent,
    LocateMeLayerComponent,
    HoehenprofilLayerComponent,
    KarteViewOnlyComponent,
    GrenzeLayerComponent,
    PoiLayerComponent,
    PoiToggleMenuComponent,
    GeoserverWmsLayerComponent,
    PoiTooltipComponent,
    RadnetzToggleMenuComponent,
    DetailsComponent,
  ],
  exports: [
    KarteComponent,
    KarteControlsSidebarComponent,
    MarkerLayerComponent,
    TileLayerComponent,
    RouteLayerComponent,
    LayerSelectionMenuComponent,
    HoehenprofilLayerComponent,
    KarteViewOnlyComponent,
    GrenzeLayerComponent,
    GrenzeLayerComponent,
    PoiLayerComponent,
    GeoserverWmsLayerComponent,
    DetailsComponent,
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    CommonModule,
    TranslateModule,
    MatMenuModule,
    MatSidenavModule,
    RRPBWCommonModule,
    MatCheckboxModule,
    MatListModule,
  ],
})
export class KarteModule {}
