<mat-form-field appearance="outline">
  <input
    #routeTeilenInput
    matInput
    [value]="currentUrl"
    [readonly]="true"
    title="{{ 'routenplaner.routeTeilenDialog.inputTitle' | translate }}"
    aria-describedby="routeTeilenUrlKopierenButton"
  />
</mat-form-field>
<button
  class="focus"
  id="routeTeilenUrlKopierenButton"
  mat-flat-button
  color="primary"
  (click)="onCopy()"
  [cdkCopyToClipboard]="currentUrl"
  [attr.aria-label]="'radroutenplaner.routeTeilenDialog.copy' | translate"
>
  <mat-icon>content_copy</mat-icon>
</button>
