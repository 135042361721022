import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { MapMarkerService } from "../../services/map-marker.service";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Geometry, Point } from "ol/geom";
import { MapMarker } from "../../entities/map-marker";
import { Feature } from "ol";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";
import { GeoLocation } from "../../../common/entities/geo-location";
import CircleStyle from "ol/style/Circle";
import { ColorTone, RrpBwColors } from "../../../common/utils/rrp-bw-colors";
import { DragInteraction } from "../../interactions/drag-interaction";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import { KarteComponent } from "../karte/karte.component";
import { distinctUntilChanged } from "rxjs";

@Component({
  selector: "rrpbw-marker-layer",
  templateUrl: "./marker-layer.component.html",
  styleUrls: ["./marker-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkerLayerComponent implements OnInit, OnDestroy, MapMarkerService {
  readonly featureLayer: VectorLayer<VectorSource<Geometry>>;
  readonly dragInteraction: DragInteraction;

  markerIdCounter = 0;

  @Output()
  markerPreview: EventEmitter<MapMarker> = new EventEmitter();

  @Output()
  markerEdited: EventEmitter<MapMarker> = new EventEmitter();

  constructor(private karteBaseService: KarteBaseService) {
    this.featureLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      zIndex: 100,
    });
    this.dragInteraction = new DragInteraction(this.featureLayerSource);
  }

  ngOnInit(): void {
    this.karteBaseService.addLayer(this.featureLayer);
    this.karteBaseService.addInteraction(this.dragInteraction);
    this.karteBaseService
      .poiHover()
      .pipe(distinctUntilChanged())
      .subscribe(p => this.dragInteraction.setActive(!p));

    this.dragInteraction.addEventListener("drag", event => {
      const markerId = (event.target as DragInteraction).dragFeature.getId() as number;
      const [easting, northing] = (event.target as DragInteraction).dragCoordinate;
      this.markerPreview.emit(new MapMarker(markerId, new GeoLocation(easting, northing)));
    });
    this.dragInteraction.addEventListener("drag:end", event => {
      const markerId = (event.target as DragInteraction).dragFeature.getId() as number;
      const [easting, northing] = (event.target as DragInteraction).dragFeature.getGeometry()!.getExtent();
      this.markerEdited.emit(new MapMarker(markerId, new GeoLocation(easting, northing)));
    });
  }

  ngOnDestroy(): void {
    this.karteBaseService.removeLayer(this.featureLayer);
    this.karteBaseService.removeInteraction(this.dragInteraction);
  }

  createPinMarker(geoLocation: GeoLocation, label: string): MapMarker {
    return this.createMarker(geoLocation, label, false);
  }

  createPointMarker(geoLocation: GeoLocation, label: string): MapMarker {
    return this.createMarker(geoLocation, label, true);
  }

  clearLayer(): void {
    this.featureLayerSource.clear();
  }

  get featureLayerSource(): VectorSource<Geometry> {
    return this.featureLayer.getSource()!;
  }

  private createMarker(geoLocation: GeoLocation, label: string, isPointStyle: boolean): MapMarker {
    this.markerIdCounter++;
    const marker = new MapMarker(this.markerIdCounter, geoLocation);

    const feature = new Feature({
      geometry: new Point(marker.geoLocation.coordinates),
    });
    feature.setId(marker.id);
    feature.set(KarteComponent.CONTEXTMENU_SENSITIVE_PROPERTY, true);

    if (isPointStyle) {
      feature.setStyle(MarkerLayerComponent.generatePointMarkerStyle(label));
    } else {
      feature.setStyle(MarkerLayerComponent.generatePinMarkerStyle(label));
    }

    this.featureLayerSource.addFeature(feature);

    return marker;
  }

  private static generatePinMarkerStyle(label: string): Style {
    return new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: "assets/poi-icons/map-pin.svg",
        scale: 0.25,
        color: RrpBwColors.getPrimaryColor(ColorTone.Tone900),
      }),
      text: new Text({
        text: label,
        fill: new Fill({
          color: RrpBwColors.getPrimaryContrastColor(ColorTone.Tone900),
        }),
        stroke: new Stroke({
          color: RrpBwColors.getPrimaryContrastColor(ColorTone.Tone900),
          width: 0.5,
        }),
        offsetY: -16,
        textAlign: "center",
        scale: 1.3,
      }),
    });
  }

  private static generatePointMarkerStyle(label: string): Style {
    return new Style({
      image: new CircleStyle({
        radius: 10,
        fill: new Fill({
          color: RrpBwColors.getPrimaryColor(ColorTone.Tone900),
        }),
      }),
      text: new Text({
        text: label,
        fill: new Fill({
          color: RrpBwColors.getPrimaryContrastColor(ColorTone.Tone900),
        }),
        stroke: new Stroke({
          color: RrpBwColors.getPrimaryContrastColor(ColorTone.Tone900),
          width: 0.5,
        }),
        textAlign: "center",
        scale: 1.3,
      }),
    });
  }
}
