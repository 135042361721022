<div class="sidenav-widget-header">
  <h1 class="sidenav-widget-title bold">{{ "routenplaner.kartePreview.title" | translate }}</h1>
  <button
    mat-button
    class="button-highlight label-button-all-caps button-small-padding sidenav-widget-button focus"
    (click)="onClick()"
  >
    {{ "routenplaner.kartePreview.ansehen" | translate }}
  </button>
</div>
<rrpbw-karte-view-only
  (clicked)="onClick()"
  [attr.aria-label]="'routenplaner.kartePreview.title' | translate"
  tabindex="0"
  class="focus"
>
  <rrpbw-route-layer #routeLayer></rrpbw-route-layer>
  <rrpbw-marker-layer #markerLayer></rrpbw-marker-layer>
</rrpbw-karte-view-only>
