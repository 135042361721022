<div class="headerContainer">
  <h1 *ngIf="title">{{ title | translate }}</h1>
  <p *ngIf="description">{{ description | translate }}</p>
</div>
<div class="templateContainer">
  <template #child></template>
</div>
<button class="button-highlight button-schliessen focus" color="primary" mat-stroked-button (click)="closed.emit()">
  {{ "dialogWrapper.schließen" | translate }}
</button>
