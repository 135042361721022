import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  static readonly NOTIFICATION_DURATION: number = 5000;

  private currentNotificationKey: string | undefined = undefined;

  constructor(private matSnackBar: MatSnackBar, private translateService: TranslateService) {}

  notify(messageKey: string, interpolateParams: any = undefined): void {
    if (messageKey === this.currentNotificationKey) {
      return;
    }

    this.translateService
      .get(messageKey, interpolateParams)
      .pipe(take(1))
      .subscribe(translation => {
        this.currentNotificationKey = messageKey;
        const snackBar = this.matSnackBar.open(translation, "", {
          duration: NotificationService.NOTIFICATION_DURATION,
          panelClass: "simple-snack-bar",
        });
        snackBar.afterDismissed().subscribe(() => {
          this.currentNotificationKey = undefined;
        });
      });
  }

  notifyPermanent(messageKey: string): void {
    if (messageKey === this.currentNotificationKey) {
      return;
    }

    this.translateService
      .get(messageKey)
      .pipe(take(1))
      .subscribe(translation => {
        this.currentNotificationKey = messageKey;
        const snackBar = this.matSnackBar.open(translation, this.translateService.instant("toast.close"), {
          duration: -1,
          panelClass: "simple-snack-bar",
        });
        snackBar.afterDismissed().subscribe(() => {
          this.currentNotificationKey = undefined;
        });
      });
  }
}
