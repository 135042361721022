import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { KarteContextMenuService } from "../../services/karte-context-menu.service";
import { KarteContextMenuEntry } from "../../entities/karte-context-menu-entry";
import { GeoLocation } from "../../../common/entities/geo-location";
import { Subject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { isMobileDevice } from "../../../common/utils/device";

interface ScreenLocation {
  x: number;
  y: number;
}

@Component({
  selector: "rrpbw-karte-context-menu",
  templateUrl: "./karte-context-menu.component.html",
  styleUrls: ["./karte-context-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KarteContextMenuComponent implements OnInit, OnDestroy, KarteContextMenuService {
  @ViewChild(MatMenuTrigger, { static: true })
  contextMenu: MatMenuTrigger;

  @Input()
  entries: KarteContextMenuEntry[] = [];

  @Input()
  useSymbols = false;

  contextMenuGeoLocation: GeoLocation;
  $openContextMenu: Subject<void> = new Subject();
  menuOpen: boolean = false;
  private _contextMenuLocation: ScreenLocation = { x: 0, y: 0 };
  isMobileDevice: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    window.addEventListener("click", () => this.contextMenu.closeMenu());
    this.isMobileDevice = isMobileDevice();

    this.subscriptions.push(
      this.contextMenu.menuClosed.subscribe(() => {
        this.menuOpen = false;
        this.$openContextMenu.next();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get contextMenuLocation(): ScreenLocation {
    return this._contextMenuLocation;
  }

  openContextMenu(location: GeoLocation, screenCoordinates: number[]): void {
    if (this.entries.length === 0) {
      return;
    }

    this.contextMenuGeoLocation = location;
    this._contextMenuLocation = { x: screenCoordinates[0], y: screenCoordinates[1] };

    if (!this.menuOpen) {
      this.contextMenu.openMenu();
      this.menuOpen = true;
    } else {
      this.$openContextMenu.pipe(take(1)).subscribe(() => {
        this.contextMenu.openMenu();
        this.menuOpen = true;
      });
    }
  }

  closeContextMenu(): void {
    this.contextMenu.closeMenu();
  }
}
