export class Hintergrundebene {
  private static readonly OSM_ATTRIBUTION = "hintergrundkarten.osmLayerAttributionLabel";

  private static readonly LUFTBILDER_ATTRIBUTIONS = "hintergrundkarten.luftbilderLayerAttributionLabel";

  public static readonly OSM = new Hintergrundebene(
    "osm",
    "hintergrundkarten.osm",
    "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    Hintergrundebene.OSM_ATTRIBUTION,
    18
  );

  public static readonly LUFTBILDER = new Hintergrundebene(
    "aerial",
    "hintergrundkarten.luftbilder",
    "/api/luftbilder/{z}/{x}/{y}",
    Hintergrundebene.LUFTBILDER_ATTRIBUTIONS,
    19
  );

  constructor(
    public id: string,
    public label: string,
    public url: string,
    public attribution: string,
    public maxZoom: number
  ) {}
}
