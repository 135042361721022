<rrpbw-informationen-anzeige-layout [smallTopMargin]="true">
  <div class="route-header-container">
    <rrpbw-karte-view-only class="route-preview">
      <rrpbw-simple-feature-layer
        *ngIf="fahrradroute.geometry"
        [geometry]="fahrradroute.geometry"
        [style]="ROUTE_STYLE_THIN"
      >
      </rrpbw-simple-feature-layer>
      <rrpbw-hoehenprofil-layer #hoehenprofilLayer></rrpbw-hoehenprofil-layer>
    </rrpbw-karte-view-only>

    <ng-container *ngIf="(isSmartphoneLayout$ | async) && routeMitWegpunkten.route.hasElevationData">
      <h2 class="hoehenprofil-title mt-m mb-xxs">{{ "hoehenprofil.title" | translate }}</h2>
      <rrpbw-hoehenprofil-tooltip
        [hoveredHoehenprofilLocation]="hoveredHoehenprofilLocation"
        [withAttributeTooltip]="false"
        [isSmartphoneLayout]="true"
        class="mb-xs"
      ></rrpbw-hoehenprofil-tooltip>
      <rrpbw-hoehenprofil
        [routeMitWegpunkten]="routeMitWegpunkten"
        [withAttributeVisualization]="false"
        (hoehenprofilHovered)="onHoehenprofilHovered($event)"
        (selectedElementIndex)="onHoehenprofilElementSelected($event)"
      ></rrpbw-hoehenprofil>
      <mat-divider class="mt-m"></mat-divider>
    </ng-container>

    <div class="route-header-info">
      <h1>{{ fahrradroute.name }}</h1>
      <div class="kurzbeschreibung" [innerHTML]="fahrradroute.kurzbeschreibung"></div>

      <div class="route-details">
        <mat-divider></mat-divider>
        <rrpbw-routendetails
          [evenlySpaces]="true"
          [distance]="fahrradroute.laenge"
          [totalAscend]="fahrradroute.anstieg"
          [totalDescend]="fahrradroute.abstieg"
        ></rrpbw-routendetails>
        <mat-divider></mat-divider>
      </div>

      <div class="download-buttons mt-m">
        <button mat-flat-button color="primary" (click)="onSaveAsGpx()" class="gpx-button button-all-caps focus">
          {{ "routeSpeichernDialog.gpxDownload" | translate }}
        </button>
        <button mat-flat-button color="primary" (click)="onSaveAsKml()" class="kml-button button-all-caps focus">
          {{ "routeSpeichernDialog.kmlDownload" | translate }}
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="(isSmartphoneLayout$ | async) === false && routeMitWegpunkten.route.hasElevationData">
    <h2 class="hoehenprofil-title mt-m mb-xxs">{{ "hoehenprofil.title" | translate }}</h2>
    <rrpbw-hoehenprofil-tooltip
      [hoveredHoehenprofilLocation]="hoveredHoehenprofilLocation"
      [withAttributeTooltip]="false"
      class="mb-xs"
    ></rrpbw-hoehenprofil-tooltip>
    <rrpbw-hoehenprofil
      [routeMitWegpunkten]="routeMitWegpunkten"
      [withAttributeVisualization]="false"
      (hoehenprofilHovered)="onHoehenprofilHovered($event)"
      (selectedElementIndex)="onHoehenprofilElementSelected($event)"
    ></rrpbw-hoehenprofil>
    <mat-divider class="mt-m"></mat-divider>
  </ng-container>

  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.beschreibung"
    [content]="fahrradroute.beschreibung"
    labelKey="detailProperties.label.beschreibung"
  ></rrpbw-fahrradroute-detail-item>
  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.kategorie"
    [content]="'detailProperties.values.' + fahrradroute.kategorie | translate"
    labelKey="detailProperties.label.kategorie"
  ></rrpbw-fahrradroute-detail-item>
  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.tourenkategorie"
    [content]="'detailProperties.values.' + fahrradroute.tourenkategorie | translate"
    labelKey="detailProperties.label.tourenkategorie"
  ></rrpbw-fahrradroute-detail-item>
  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.verantwortlich"
    [content]="fahrradroute.verantwortlich"
    labelKey="detailProperties.label.verantwortlich_name"
  ></rrpbw-fahrradroute-detail-item>
  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.homepage"
    [content]="
      '<a href=&quot;' + fahrradroute.homepage + '&quot; target=&quot;_blank&quot;>' + fahrradroute.homepage + '</a>'
    "
    labelKey="detailProperties.label.homepage"
  ></rrpbw-fahrradroute-detail-item>
  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.offizielleLaenge"
    [content]="fahrradroute.offizielleLaenge | distance"
    labelKey="detailProperties.label.offizielle_laenge"
  ></rrpbw-fahrradroute-detail-item>
  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.lizenz"
    [content]="
      fahrradroute.lizenz + (fahrradroute.lizenzNamensnennung ? ' (' + fahrradroute.lizenzNamensnennung + ')' : '')
    "
    labelKey="detailProperties.label.lizenz"
  ></rrpbw-fahrradroute-detail-item>
  <rrpbw-fahrradroute-detail-item
    *ngIf="fahrradroute.zusaetzlicheInformationen"
    [content]="fahrradroute.zusaetzlicheInformationen"
    labelKey="detailProperties.label.info"
  ></rrpbw-fahrradroute-detail-item>
  <div class="page-header-container" headerContent>
    <ng-container [ngTemplateOutlet]="backButton"></ng-container>
    <button
      *ngIf="!navigationFromRoutenplaner()"
      class="header-button"
      color="primary"
      mat-button
      [attr.aria-label]="'fahrradrouten.details.routeuebernehmen' | translate"
      [routerLink]="'/routenplaner'"
      [queryParams]="{ fahrradrouten: '[' + fahrradroute.id + ']', zoomToRoute: fahrradroute.id }"
    >
      {{ "fahrradrouten.details.routeuebernehmen" | translate }}
      <mat-icon color="primary">open_in_new</mat-icon>
    </button>
  </div>

  <div footerContent class="mt-s">
    <ng-container [ngTemplateOutlet]="backButton"></ng-container>
  </div>
</rrpbw-informationen-anzeige-layout>

<ng-template #backButton>
  <button
    *ngIf="
      (navigationFromApp() ? 'fahrradrouten.details.zurueck' : 'fahrradrouten.details.zurUebersicht')
        | translate as buttonText
    "
    class="header-button no-padding-left"
    color="primary"
    mat-button
    [attr.aria-label]="buttonText"
    (click)="onNavigateBack()"
  >
    <mat-icon color="primary">navigate_before</mat-icon>
    {{ buttonText }}
  </button>
</ng-template>
