<div class="display-flex fahrverhalten-slider-container">
  <label>Fahrverhalten: </label>
  <div dir="rtl" style="display: inline-block">
    <mat-slider
      class="focus"
      min="0.6"
      max="1.4"
      step="0.2"
      color="primary"
      [displayWith]="getDisplayText"
      [value]="selectedFahrverhalten.faktor"
      (input)="onFahrverhaltenChanged($event)"
    ></mat-slider>
  </div>
  <span class="ml-5"> {{ selectedFahrverhalten.displayText }}</span>
</div>
