import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "colorToCss",
})
export class ColorToCssPipe implements PipeTransform {
  public static convertToCss = (baseColor: number[] | undefined): any => {
    if (baseColor && baseColor.length >= 4) {
      return `rgba(${baseColor[0]},${baseColor[1]},${baseColor[2]},${baseColor[3]})`;
    } else if (baseColor && baseColor.length >= 3) {
      return `rgba(${baseColor[0]},${baseColor[1]},${baseColor[2]},1)`;
    } else {
      return "rgba(0,0,0,0)";
    }
  };

  transform(value: number[] | undefined): string {
    return ColorToCssPipe.convertToCss(value);
  }
}
