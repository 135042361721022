import { Directive } from "@angular/core";

@Directive({
  selector:
    "button[rrpbwAccessabilityTabCircleElement], " +
    "input[rrpbwAccessabilityTabCircleElement], " +
    "mat-checkbox[rrpbwAccessabilityTabCircleElement], " +
    "rrpbw-hintergrundebene-preview[rrpbwAccessabilityTabCircleElement]",
})
export class AccessabilityTabCircleElementDirective {}
