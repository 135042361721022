import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { KarteService } from "../../services/karte.service";
import { isMobileDevice } from "../../../common/utils/device";

@Component({
  selector: "rrpbw-karte-controls-sidebar",
  templateUrl: "./karte-controls-sidebar.component.html",
  styleUrls: ["./karte-controls-sidebar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KarteControlsSidebarComponent {
  @Input()
  isKarteGenorded: boolean = true;

  @Input()
  isLayerMenuOpen: boolean = false;

  @Output()
  layerMenuButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  isMobileDevice: boolean = isMobileDevice();

  constructor(private karteService: KarteService) {}

  onLayerButtonClicked(): void {
    this.layerMenuButtonClicked.emit();
  }

  onZoomInButtonClicked(): void {
    this.karteService.zoomIn();
  }

  onZoomOutButtonClicked(): void {
    this.karteService.zoomOut();
  }

  onLocateMeClicked(): void {
    this.karteService.locateMe();
  }

  onEinnordenButtonClicked(): void {
    this.karteService.einnorden();
  }
}
