import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "radroutenplaner",
  },
  {
    path: "",
    loadChildren: () => import("../content-pages/content-pages.module").then(m => m.ContentPagesModule),
  },
  {
    path: "radroutenplaner",
    loadChildren: () => import("../routenplaner/routenplaner.module").then(m => m.RoutenplanerModule),
  },
  {
    path: "radvis",
    loadChildren: () => import("../radvis-viewer/radvis-viewer.module").then(m => m.RadvisViewerModule),
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "radroutenplaner",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
