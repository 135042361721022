import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Stroke, Style } from "ol/style";
import { ColorTone, GrayscaleTone, RrpBwColors } from "src/app/common/utils/rrp-bw-colors";
import { FahrradroutenService } from "src/app/fahrradrouten/services/fahrradrouten.service";
import { FahrradroutenFilterService } from "src/app/fahrradrouten/services/fahrradrouten-filter.service";
import { Observable, of, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { tap } from "rxjs/operators";
import { Geometry } from "ol/geom";
import { FAHRRADROUTEN_PATH } from "src/app/fahrradrouten/fahrradrouten-routing.module";
import { Fahrradroute } from "src/app/fahrradrouten/entities/fahrradroute";

@Component({
  selector: "rrpbw-fahrradrouten",
  templateUrl: "./fahrradrouten.component.html",
  styleUrls: ["./fahrradrouten.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrradroutenComponent implements OnInit, OnDestroy {
  static readonly ROUTE_STYLE_THIN = [
    new Style({
      stroke: new Stroke({
        width: 4,
        color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone1, 0.75),
      }),
    }),
    new Style({
      stroke: new Stroke({
        width: 3,
        color: RrpBwColors.getPrimaryColor(ColorTone.Tone500, 0.75),
      }),
    }),
  ];

  @ViewChild(MatPaginator)
  paginator: MatPaginator | null = null;

  readonly ROUTE_STYLE_THIN = FahrradroutenComponent.ROUTE_STYLE_THIN;

  allFahrradrouten: Fahrradroute[] = [];
  allFahrradroutenKategorien: string[] = [];
  allFahrradroutenTourenkategorien: string[] = [];

  fahrradrouten: Fahrradroute[] = [];
  loading = false;

  private subscriptions: Subscription[] = [];

  public pageSize: number = 12;
  public page: number = 1;

  constructor(
    public router: Router,
    public fahrradroutenService: FahrradroutenService,
    public fahrradroutenFilterService: FahrradroutenFilterService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.fahrradroutenService.getAllRoutes().subscribe({
      next: fahrradrouten => {
        this.allFahrradrouten = fahrradrouten;
        this.allFahrradroutenKategorien = [
          FahrradroutenFilterService.ALL,
          ...new Set(this.allFahrradrouten.map(f => f.kategorie).filter(k => k != null)),
        ];
        this.allFahrradroutenTourenkategorien = [
          FahrradroutenFilterService.ALL,
          ...new Set(this.allFahrradrouten.map(f => f.tourenkategorie).filter(k => k != null)),
        ];

        const kategorieFilter = this.allFahrradroutenKategorien.includes(FahrradroutenFilterService.LANDESRADFERNWEG)
          ? FahrradroutenFilterService.LANDESRADFERNWEG
          : FahrradroutenFilterService.ALL;
        this.fahrradroutenFilterService.setKategorieFilter(kategorieFilter);

        this.updateFilteredFahrradrouten();
      },
      complete: () => {
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      },
    });

    this.subscriptions.push(
      this.fahrradroutenFilterService.$filterKategorie.subscribe(() => this.updateFilteredFahrradrouten()),
      this.fahrradroutenFilterService.$filterTourenkategorie.subscribe(() => this.updateFilteredFahrradrouten()),
      this.fahrradroutenFilterService.$filterVariantenkategorie.subscribe(() => this.updateFilteredFahrradrouten()),
      this.fahrradroutenFilterService.$filterVolltext.subscribe(() => this.updateFilteredFahrradrouten())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  updateFilteredFahrradrouten(): void {
    this.fahrradrouten = this.fahrradroutenFilterService.filter(this.allFahrradrouten);
    this.page = 0;
    if (this.paginator) {
      this.paginator.length = this.fahrradrouten.length;
    }
    this.changeDetectorRef.markForCheck();
  }

  onFahrradrouteClicked(id: number): void {
    this.router.navigateByUrl(`/${FAHRRADROUTEN_PATH}/` + id);
  }

  onPageChanged(pageEvent: PageEvent): void {
    this.pageSize = pageEvent.pageSize;
    this.page = pageEvent.pageIndex;
  }

  getRouteGeom(route: Fahrradroute): Observable<Geometry | undefined> {
    if (route.geometry) {
      return of(route.geometry);
    }
    return this.fahrradroutenService.getRouteGeom(route.id).pipe(tap(geom => (route.geometry = geom)));
  }
}
