import { Injectable } from "@angular/core";
import { Adresse } from "../entities/adresse";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { GeoLocation } from "../../common/entities/geo-location";
import { MAP_PROJECTION } from "../../constants";
import { fromLonLat } from "ol/proj";

export class OrtssucheDto {
  constructor(public readonly features: OrtssucheFeatureDto[]) {}
}

export class OrtssucheFeatureDto {
  constructor(
    public readonly properties: OrtssucheFeaturePropertiesDto,
    public readonly geometry: OrtssucheGeometryDto
  ) {}
}

export class OrtssucheGeometryDto {
  constructor(public readonly coordinates: [number, number]) {}
}

export class OrtssucheFeaturePropertiesDto {
  constructor(public readonly text: string) {}
}

@Injectable({
  providedIn: "root",
})
export class AdresseRepository {
  // Doku zur BKG-API: https://sg.geodatenzentrum.de/web_public/gdz/dokumentation/deu/geokodierungsdienst.pdf

  private static readonly BKG_URL = "/api/ortssuche";

  private static readonly QUERY_ADRESSEN_RESULT_COUNT = 10;
  private static readonly QUERY_ADRESSEN_FILTER = "-qualitaet:c";
  private static readonly QUERY_BBOX_FILTER = [...fromLonLat([6.8075, 47.0488]), ...fromLonLat([11.2641, 50.304])];

  constructor(private httpClient: HttpClient) {}

  queryAdressen(query: string): Observable<Adresse[]> {
    // Die BKG-Suche hat ganz eigene Sonderzeichen, die escaped werden müssen (siehe Doku):
    query = query.replace(/([+\-!(){}[\]^"~*?:\\]|(&&)|(\|\|))/g, "\\$1");

    return this.httpClient
      .get<OrtssucheDto>(AdresseRepository.BKG_URL, {
        params: {
          query: query,
          count: AdresseRepository.QUERY_ADRESSEN_RESULT_COUNT,
          srsName: MAP_PROJECTION,
          filter: AdresseRepository.QUERY_ADRESSEN_FILTER,
          bbox: AdresseRepository.QUERY_BBOX_FILTER.join(","),
        },
      })
      .pipe(
        map(response =>
          response.features.map(
            feature =>
              new Adresse(
                new GeoLocation(feature.geometry.coordinates[0], feature.geometry.coordinates[1]),
                feature.properties.text
              )
          )
        )
      );
  }
}
