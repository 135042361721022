import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { ChartDataset, ChartOptions } from "chart.js";
import { formatMeter } from "src/app/common/utils/distance-formatter";
import { GrayscaleTone, RrpBwColors } from "src/app/common/utils/rrp-bw-colors";
import { AbstractHoehenprofilComponent } from "src/app/common/dumb-components/abstract-hoehenprofil.component";

@Component({
  selector: "rrpbw-hoehenprofil",
  templateUrl: "./hoehenprofil.component.html",
  styleUrls: ["./hoehenprofil.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoehenprofilComponent extends AbstractHoehenprofilComponent implements OnInit {
  @ViewChild("chartCanvas", { static: true })
  chartCanvas: any;

  @Input()
  withAttributeVisualization = true;

  showCanvas: boolean = false;
  currentCaretX: number = 0;

  constructor() {
    super(false, true);
  }

  lineChartDatasets: ChartDataset[] = [
    {
      data: [],
      borderWidth: 5,
      borderColor: RrpBwColors.getPrimaryColor(500),
      backgroundColor: RrpBwColors.getPrimaryColor(500, 0.1),
      fill: "start",
      spanGaps: true,
      normalized: true,
      parsing: false,
      indexAxis: "x",
    },
  ];

  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    parsing: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        right: 20,
        left: 20,
        top: 10,
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "top",
        grid: {
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone4),
        },
        ticks: {
          autoSkip: true,
          minRotation: 0,
          maxRotation: 0,
          maxTicksLimit: 8,
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone11),
          callback: (value, index, values) => {
            if (values.length - 1 === index || index === 0) {
              return "";
            }
            return formatMeter(value as number);
          },
        },
      },
      y: {
        type: "linear",
        grid: {
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone4),
        },
        ticks: {
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone11),
          maxTicksLimit: 6,
          callback: (value, index, values) => {
            return `${Math.round(value as number)} m`;
          },
        },
      },
    },
    hover: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        position: "average",
        enabled: false,
        external: () => {}, // Leere Funktion damit wir zwar den vertikalen Strich haben, aber kein default Tooltip im Plot
      },
      legend: {
        display: false,
      },
      decimation: {
        enabled: true,
        algorithm: "lttb",
        samples: 100,
        threshold: 100,
      },
    },
  };

  ngOnInit(): void {
    const context = this.chartCanvas.nativeElement.getContext("2d")!;
    this.initChart(context);
  }

  onKeyDown(event: KeyboardEvent): void {
    this.chart.tooltip!.title = [];
    this.chart.tooltip!.beforeBody = [];
    this.chart.tooltip!.afterBody = [];
    this.chart.tooltip!.body = [];
    this.chart.tooltip!.footer = [];

    this.chart.tooltip!.opacity = 1;

    if (event.key === "ArrowRight") {
      this.currentCaretX += 20;
    } else if (event.key === "ArrowLeft") {
      this.currentCaretX -= 20;
    }

    if (["ArrowRight", "ArrowLeft"].includes(event.key)) {
      this.currentCaretX = Math.max(this.chart.scales.x.left, Math.min(this.chart.scales.x.right, this.currentCaretX));
      this.chart.tooltip!.caretX = this.currentCaretX;
      this.chart.draw();

      const max = this.chart.scales.x.max;
      const relativeCaretPosition =
        (this.currentCaretX - this.chart.scales.x.left) / (this.chart.scales.x.right - this.chart.scales.x.left);
      const relativeDistance = max * relativeCaretPosition;
      const relativeDistances = this.distanzen.map(distance => Math.abs(distance - relativeDistance));
      const index = relativeDistances.indexOf(Math.min(...relativeDistances));
      this.onHover(relativeDistance, this.routeMitWegpunkten!.route.elevations[index]);
    }
  }
}
