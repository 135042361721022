import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { KarteService } from "../../services/karte.service";
import VectorLayer from "ol/layer/Vector";
import { Circle, Geometry, Point } from "ol/geom";
import VectorSource from "ol/source/Vector";
import { LocateMeService } from "../../services/locate-me.service";
import { MAP_PROJECTION } from "../../../constants";
import Geolocation from "ol/Geolocation";
import { Feature } from "ol";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { NotificationService } from "../../../common/services/notification.service";
import { ColorTone, RrpBwColors } from "../../../common/utils/rrp-bw-colors";

@Component({
  selector: "rrpbw-locate-me-layer",
  templateUrl: "./locate-me-layer.component.html",
  styleUrls: ["./locate-me-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocateMeLayerComponent implements OnInit, OnDestroy {
  static readonly POSITION_FEATURE_STYLE = new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: RrpBwColors.getAccentColor(ColorTone.Tone500, 0.75),
      }),
      stroke: new Stroke({
        color: RrpBwColors.getAccentContrastColor(ColorTone.Tone500, 0.75),
        width: 2,
      }),
    }),
  });

  static readonly ACCURACY_FEATURE_STYLE = new Style({
    fill: new Fill({
      color: RrpBwColors.getAccentColor(ColorTone.Tone500, 0.1),
    }),
    stroke: new Stroke({
      color: RrpBwColors.getAccentColor(ColorTone.Tone500, 0.5),
      width: 2,
    }),
  });

  readonly layer: VectorLayer<VectorSource<Geometry>>;

  geoLocation: Geolocation;
  positionFeature: Feature<Point>;
  accuracyFeature: Feature<Circle>;

  constructor(
    public locateMeService: LocateMeService,
    public karteService: KarteService,
    public notificationService: NotificationService
  ) {
    this.positionFeature = new Feature<Point>();
    this.positionFeature.setStyle(LocateMeLayerComponent.POSITION_FEATURE_STYLE);

    this.accuracyFeature = new Feature<Circle>();
    this.accuracyFeature.setStyle(LocateMeLayerComponent.ACCURACY_FEATURE_STYLE);

    this.layer = new VectorLayer({
      source: new VectorSource<Geometry>({
        features: [this.positionFeature, this.accuracyFeature],
      }),
    });
  }

  ngOnInit(): void {
    this.geoLocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: MAP_PROJECTION,
    });
    this.geoLocation.on("change:position", () => {
      const position = this.geoLocation.getPosition();

      if (position) {
        this.positionFeature.setGeometry(new Point(position));
        this.geoLocation.setTracking(false);
      }
    });
    this.geoLocation.on("change:accuracyGeometry", () => {
      const position = this.geoLocation.getPosition();
      const accuracy = this.geoLocation.getAccuracy();
      if (position && accuracy) {
        const circle = new Circle(position, accuracy);

        this.accuracyFeature.setGeometry(circle);

        this.karteService.zoomToExtent(circle.getExtent());
      }
    });
    this.geoLocation.on("error", () => {
      this.notificationService.notify("locateMeLayer.error");
    });

    this.karteService.addLayer(this.layer);

    this.locateMeService.$locateMe.subscribe(() => this.locateMe());
  }

  ngOnDestroy(): void {
    this.karteService.removeLayer(this.layer);
  }

  locateMe(): void {
    this.geoLocation.setTracking(true);
  }
}
