import { BehaviorSubject, Observable } from "rxjs";
import { DetailProperties } from "src/app/karte/entities/detail-properties";
import { FeatureLike } from "ol/Feature";

export interface MapIconStyleInfo {
  mapIcon: string;
  scale: number;
  minZoom: number;
}
export class Infrastruktur {
  private _selected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @param labelKey Key für die Übersetzung des Titels dieser Infrastruktur.
   * @param icon Icon für das Auswahlmenü. Dies ist *nicht* das Icon auf der Karte (siehe dazu unten).
   * @param zIndex Z-Index des Layers.
   * @param layerWorkspace Workspace des WMS-Dienstes.
   * @param layerNames Layer-Namen im WMS-Dienst.
   * @param layerWorkspaceWFS Workspace des WFS-Dienstes. Überlicherweise gleich des WMS-Workspaces.
   * @param layerNamesWFS Layer-Namen im WFS-Dienst. Überlicherweise gleich derer des WMS-Workspaces.
   * @param detailProperties DetailProperties zur schönen Anzeige von Attributen.
   * @param hasSignatur Ob der layer mit einer Sld gestyled werden kann, default false
   * @param iconColor Farbe des Icons (genauer gesagt Hintergrundfarbe des Pins).
   * @param minZoom Zoom ab dem die Icons sichtbar sind.
   * @param mapIcon Icon auf der Karte und in der Detailansicht.
   * @param mapIconStyleInfoFn Function die auf Basis eines Features Info für das Styling liefert. Wird dann statt "mapIcon" benutzt.
   */
  constructor(
    public labelKey: string,
    public icon: string,
    public zIndex: number,
    public layerWorkspace?: string,
    public layerNames?: string[],
    private layerWorkspaceWFS?: string,
    private layerNamesWFS?: string[],
    public detailProperties?: DetailProperties,
    public hasSignatur: boolean = false,
    public iconColor: string = "",
    public minZoom: number = 0,
    public mapIcon: string = "",
    public mapIconStyleInfoFn: ((f: FeatureLike) => MapIconStyleInfo) | undefined = undefined
  ) {}

  public get hasData(): boolean {
    return !!this.layerWorkspace && !!this.layerNames && this.layerNames.length > 0;
  }

  public get selected$(): Observable<boolean> {
    return this._selected$.asObservable();
  }

  public select(selected: boolean): void {
    this._selected$.next(selected);
  }

  public deselect(): void {
    this.select(false);
  }

  public isSelected(): boolean {
    return this._selected$.getValue();
  }

  getLayerDescriptor(index: number): string | undefined {
    if (!this.hasData) {
      return undefined;
    }
    return this.layerWorkspace + ":" + this.layerNames![index];
  }

  getFullWFSLayerDescriptor(): string | undefined {
    return `${this.getWFSWorkspace()}:${this.getWFSLayerNames()}`;
  }

  getWFSWorkspace(): string | undefined {
    return this.layerWorkspaceWFS ? this.layerWorkspaceWFS : this.layerWorkspace;
  }

  getWFSLayerNames(): string[] | undefined {
    return this.layerNamesWFS ? this.layerNamesWFS : this.layerNames;
  }

  getLabelKeyWithoutPrefix(): string {
    return this.labelKey.split(".").pop()!;
  }
}
