<!-- SVG Filtermatrix zum Einfärben der Icons. Ansonsten müsste man mit CSS Filtern hantieren, die wesentlich unpraktischer sind -->
<svg class="hide">
  <defs>
    <filter id="colorize" color-interpolation-filters="sRGB">
      <feColorMatrix type="matrix" [attr.values]="getSvgFilterMatrix()" />
    </filter>
  </defs>
</svg>

<button
  *ngIf="showHeading"
  class="mat-drawer-button-back focus"
  (click)="close.emit()"
  mat-flat-button
  [attr.aria-label]="'routenplaner.optionsMenu.close' | translate"
>
  <div class="close-button-content-wrapper">
    <div class="name-label">
      <span aria-hidden="true">{{ getValue(DetailProperties.NAME.key) }}</span>
      <span class="cdk-visually-hidden">{{ ariaLabelNameWithValue }}</span>
    </div>
    <div>
      <span class="layer-label" aria-hidden="true">{{ layerName }}</span>
      <span class="cdk-visually-hidden">{{ ariaLabelLayerWithValue }}</span>
    </div>
  </div>
  <mat-icon class="contrast">close</mat-icon>
</button>

<div class="content">
  <div>
    <div *ngFor="let key of propertyKeys" class="property-list-item">
      <mat-icon class="property-key-icon" title="{{ getTitle(key) }}">{{ getIcon(key) }}</mat-icon>
      <div>
        <span class="property-key-label">{{ getTitle(key) }}</span>
        <div class="property-key-value" [innerHTML]="getValue(key) ?? '-'"></div>
      </div>
    </div>
  </div>
  <div *ngIf="getValue(DetailProperties.SOURCE.key)" class="m-m">
    {{ "detailProperties.sourceLabel" | translate }}:
    <a [href]="getValue(DetailProperties.SOURCE_URL.key)" target="_blank">{{
      getValue(DetailProperties.SOURCE.key)
    }}</a>
  </div>
</div>
