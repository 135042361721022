<div class="poi-menu-entry pl-m" *ngFor="let entry of menuEntries">
  <mat-checkbox
    rrpbwAccessabilityTabCircleElement
    color="primary"
    (change)="entry.select($event.checked)"
    [checked]="entry.selected$ | async"
  >
    <div>
      <mat-icon>{{ entry.icon }}</mat-icon>
      <span>{{ entry.labelKey | translate }}</span>
    </div>
  </mat-checkbox>
</div>
