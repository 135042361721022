import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from "@angular/core";
import Map from "ol/Map";
import { View } from "ol";
import { MAP_PROJECTION } from "../../../constants";
import { KarteComponent } from "../karte/karte.component";
import { Extent } from "ol/extent";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import { Interaction } from "ol/interaction";
import BaseLayer from "ol/layer/Base";
import { Hintergrundebene } from "../../entities/hintergrundebene";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { TranslateService } from "@ngx-translate/core";
import { TooltipEvent } from "src/app/karte/services/karte.service";
import { Observable, of } from "rxjs";

@Component({
  selector: "rrpbw-karte-view-only",
  templateUrl: "./karte-view-only.component.html",
  styleUrls: ["./karte-view-only.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: KarteBaseService, useExisting: forwardRef(() => KarteViewOnlyComponent) }],
})
export class KarteViewOnlyComponent implements AfterViewInit, KarteBaseService {
  static readonly FIT_PADDING: number = 32;
  static readonly FIT_PADDING_THUMBNAIL = 8;

  @Output()
  clicked: EventEmitter<void> = new EventEmitter();

  @Input()
  thumbailLayout: boolean = false;

  mapId: string;
  map: Map;
  attribution: string;

  constructor(translationService: TranslateService) {
    translationService.get(Hintergrundebene.OSM.attribution).subscribe(translation => (this.attribution = translation));
    this.mapId = "map" + KarteComponent.MAP_COUNTER;
    KarteComponent.MAP_COUNTER++;

    this.map = new Map({
      view: new View({
        projection: MAP_PROJECTION,
        center: KarteComponent.INITIAL_VIEW_CENTER,
        zoom: KarteComponent.INITIAL_VIEW_ZOOM,
      }),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: Hintergrundebene.OSM.url,
            maxZoom: Hintergrundebene.OSM.maxZoom,
          }),
        }),
      ],
      controls: [],
      interactions: [],
    });
  }

  ngAfterViewInit(): void {
    this.map.setTarget(this.mapId);
    this.zoomToExtent(KarteComponent.BADEN_WUERTTEMBERG_EXTENT);
  }

  zoomToExtent(extent: Extent): void {
    this.map.updateSize();
    this.map.getView().fit(extent, {
      duration: KarteComponent.ZOOM_DURATION,
      padding: this.thumbailLayout
        ? [
            KarteViewOnlyComponent.FIT_PADDING_THUMBNAIL,
            KarteViewOnlyComponent.FIT_PADDING_THUMBNAIL,
            KarteViewOnlyComponent.FIT_PADDING_THUMBNAIL,
            KarteViewOnlyComponent.FIT_PADDING_THUMBNAIL + 10,
          ]
        : [
            KarteViewOnlyComponent.FIT_PADDING + 16,
            KarteViewOnlyComponent.FIT_PADDING,
            KarteViewOnlyComponent.FIT_PADDING,
            KarteViewOnlyComponent.FIT_PADDING,
          ],
    });
  }

  addLayer(layer: BaseLayer): void {
    this.map.addLayer(layer);
  }

  removeLayer(layer: BaseLayer): void {
    this.map.removeLayer(layer);
  }

  addInteraction(interaction: Interaction): void {}

  removeInteraction(interaction: Interaction): void {}

  center(coordinates: number[]): void {
    this.map.getView().animate({
      center: coordinates,
      duration: 0,
    });
  }

  viewExtent(): Extent {
    return this.map.getView().calculateExtent(this.map.getSize());
  }

  poiHover(): Observable<TooltipEvent | undefined> {
    return of(undefined);
  }

  getZoomForResolution(res: number): number | undefined {
    return this.map.getView().getZoomForResolution(res);
  }
}
