export enum BelagArt {
  ASPHALT = "ASPHALT",
  BETON = "BETON",
  NATURSTEINPFLASTER = "NATURSTEINPFLASTER",
  BETONSTEINPFLASTER_PLATTENBELAG = "BETONSTEINPFLASTER_PLATTENBELAG",
  WASSERGEBUNDENE_DECKE = "WASSERGEBUNDENE_DECKE",
  UNGEBUNDENE_DECKE = "UNGEBUNDENE_DECKE",
  SONSTIGER_BELAG = "SONSTIGER_BELAG",
  UNBEKANNT = "UNBEKANNT",
}

// eslint-disable-next-line no-redeclare
export namespace BelagArt {
  export const colors: Map<BelagArt, number[]> = new Map([
    [BelagArt.ASPHALT, [64, 0, 75, 1]],
    [BelagArt.BETON, [153, 112, 171, 1]],
    [BelagArt.BETONSTEINPFLASTER_PLATTENBELAG, [194, 165, 207, 1]],
    [BelagArt.NATURSTEINPFLASTER, [231, 212, 232, 1]],
    [BelagArt.WASSERGEBUNDENE_DECKE, [0, 68, 27, 1]],
    [BelagArt.UNGEBUNDENE_DECKE, [27, 120, 55, 1]],
    [BelagArt.SONSTIGER_BELAG, [166, 219, 160, 1]],
    [BelagArt.UNBEKANNT, [217, 240, 211, 1]],
  ]);
}
