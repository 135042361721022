import VectorSource from "ol/source/Vector";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import { StyleFunction } from "ol/style/Style";

export abstract class AbstractFeatureLayerComponent {
  static readonly ZINDEX: number = 15000;

  protected abstract featureLayer: VectorLayer<VectorSource>;

  protected abstract getStyleFunction(scale?: number, saturation?: number, zIndex?: number): StyleFunction;

  selectedFeatures: Feature<Geometry>[] = [];

  deselectAll(): void {
    this.selectedFeatures.forEach(f => f.setStyle(undefined));
    this.selectedFeatures = [];
    this.featureLayer.setStyle(this.getStyleFunction(1.25));
  }

  selectFeature(feature: Feature<Geometry> | undefined): void {
    this.deselectAll();
    if (!feature) {
      return;
    }

    this.featureLayer.setStyle(this.getStyleFunction(1.25, 0.75));
    if (this.hasFeature(feature)) {
      feature.setStyle(this.getStyleFunction(1.5, 1, 2));
      this.selectedFeatures.push(feature);
      this.featureLayer.setZIndex(AbstractFeatureLayerComponent.ZINDEX + 1);
    } else {
      this.featureLayer.setZIndex(AbstractFeatureLayerComponent.ZINDEX);
    }
  }

  hasFeature(feature: Feature<Geometry>): boolean {
    return this.featureLayer.getSource()?.hasFeature(feature) || false;
  }
}
