import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Feature, Map as OlMap, MapBrowserEvent, View } from "ol";
import { fromLonLat } from "ol/proj";
import { KarteService, TooltipEvent } from "../../services/karte.service";
import { Observable, of, Subject, Subscription, zip } from "rxjs";
import BaseLayer from "ol/layer/Base";
import { ScaleLine } from "ol/control";
import { MAP_PROJECTION } from "../../../constants";
import { GeoLocation } from "../../../common/entities/geo-location";
import { Hintergrundebene } from "../../entities/hintergrundebene";
import { HintergrundebenenService } from "../../services/hintergrundebenen.service";
import { LocateMeService } from "../../services/locate-me.service";
import { boundingExtent, Extent, getCenter } from "ol/extent";
import { defaults, Interaction } from "ol/interaction";
import { debounceTime, map, throttleTime } from "rxjs/operators";
import { KarteContextMenuService } from "../../services/karte-context-menu.service";
import { KarteContextMenuEntry } from "../../entities/karte-context-menu-entry";
import { Geometry, MultiLineString, MultiPoint, Point } from "ol/geom";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import { LayoutService, ScreenLayout } from "../../../common/services/layout.service";
import RenderFeature from "ol/render/Feature";
import { isIOSDevice } from "../../../common/utils/device";
import { TranslateService } from "@ngx-translate/core";
import { PoiRepository } from "../../repositories/poi.repository";
import { LayerQueryService } from "src/app/karte/services/layer-query.service";
import { NotificationService } from "../../../common/services/notification.service";
import { PoiSelectionService } from "../../services/poi-selection.service";
import { GeoserverFeatureService } from "src/app/karte/services/geoserver-feature.service";
import { SelectedInfrastrukturFeature } from "src/app/karte/entities/selected-infrastruktur-feature";
import { DetailProperties } from "src/app/karte/entities/detail-properties";
import { UrlStorageKeys, UrlStorageRepository } from "src/app/common/repositories/url-storage.repository";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";
import GeometryType from "src/app/common/utils/geometry-type";
import { Signatur } from "src/app/karte/entities/signatur";

@Component({
  selector: "rrpbw-karte",
  templateUrl: "./karte.component.html",
  styleUrls: ["./karte.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: KarteService, useExisting: forwardRef(() => KarteComponent) },
    { provide: KarteBaseService, useExisting: forwardRef(() => KarteComponent) },
  ],
})
export class KarteComponent implements OnInit, OnDestroy, AfterViewInit, KarteService {
  static readonly CONTEXTMENU_SENSITIVE_PROPERTY = "contextmenuSensitive";

  // Um sicherzustellen, dass jede Map-Instanz eine eigene ID hat, nehmen wir hier einen counter, der hochgezählt wird.
  static MAP_COUNTER: number = 0;

  static readonly RESIZE_DEBOUNCE: number = 50;
  static readonly ZOOM_DURATION: number = 250;
  static readonly ZOOM_OFFSET: number = 0.5;
  static readonly FIT_PADDING_DESKTOP: number = 192;
  static readonly FIT_PADDING_SMARTPHONE: number = 32;
  static readonly FIT_PADDING_TOP_OFFSET: number = 16;
  static readonly INITIAL_VIEW_CENTER: number[] = fromLonLat([8, 51]);
  static readonly INITIAL_VIEW_ZOOM: number = 5.8;
  static readonly KEYBOARD_CENTER_SHIFT: number = 0.02;
  static readonly LAYER_BETT_UND_BIKE = "rrpbw-poi:bett_und_bike";
  static readonly LAYER_HALTESTELLEN = "rrpbw-poi:haltestellen";
  static readonly LAYER_RADSERVICE = "rrpbw-poi:radservice-punkte-point";
  static readonly BADEN_WUERTTEMBERG_EXTENT: number[] = boundingExtent([
    fromLonLat([7.501, 49.803]),
    fromLonLat([10.517, 47.525]),
  ]);

  static readonly HOVER_HIT_TOLERANCE = 10;

  static readonly CENTER_ANIMATION_DURATION: number = 250;

  DetailProperties = DetailProperties;

  @Input()
  hintergrundebenen: Hintergrundebene[] = [Hintergrundebene.OSM];

  @Input()
  defaultContextMenuEntries: KarteContextMenuEntry[] = [];

  @Input()
  disableContextMenu: boolean = false;

  @Input()
  infrastrukturen: Infrastruktur[] = [];

  @Input()
  selektierbarePoiInfrastrukturen: Infrastruktur[] = [];

  @Input()
  featureContextMenuEntries: KarteContextMenuEntry[];

  @Input()
  openContextMenu: Observable<
    [geoLocation: GeoLocation, screenCoordinates: number[], feature: Feature<Geometry> | undefined]
  > = of();

  @Input()
  signatur: Signatur | undefined;

  @Output()
  featureSelected: EventEmitter<SelectedInfrastrukturFeature | null> = new EventEmitter();

  @ViewChild("defaultContextMenu", { static: true })
  defaultContextMenu: KarteContextMenuService;

  @ViewChild("featureContextMenu", { static: true })
  featureContextMenu: KarteContextMenuService;

  @ViewChild("featureDetailContextMenu", { static: true })
  featureDetailContextMenu: KarteContextMenuService;

  @ContentChildren("poiLayerComponent")
  poiQueryServices: LayerQueryService[];

  @ViewChildren("geoserverLayer")
  geoserverFeatureServices: GeoserverFeatureService[];

  $pointerMoveSubject: Subject<MapBrowserEvent<UIEvent>> = new Subject<MapBrowserEvent<UIEvent>>();
  $poiHoverSubject: Subject<TooltipEvent | undefined> = new Subject<TooltipEvent | undefined>();

  featureDetailContextMenuEntries: KarteContextMenuEntry[] = [];

  attribution: string = "";
  isKarteGenorded: boolean = true;
  resizeObserver: ResizeObserver;
  subscriptions: Subscription[] = [];

  mapId: string;
  map: OlMap;

  zoomPadding: number = KarteComponent.FIT_PADDING_DESKTOP;

  $resizeDebounce: Subject<void> = new Subject();

  private featureDetailContextMenuSubscription: Subscription;

  constructor(
    public hintergrundebenenService: HintergrundebenenService,
    public locateMeService: LocateMeService,
    public changeDetectorRef: ChangeDetectorRef,
    public layoutService: LayoutService,
    public translateService: TranslateService,
    public notificationService: NotificationService,
    public poiRepository: PoiRepository,
    public poiSelectionService: PoiSelectionService,
    public urlStorageRepository: UrlStorageRepository
  ) {
    this.mapId = "map" + KarteComponent.MAP_COUNTER;
    KarteComponent.MAP_COUNTER++;

    this.map = new OlMap({
      view: new View({
        projection: MAP_PROJECTION,
        center: KarteComponent.INITIAL_VIEW_CENTER,
        zoom: KarteComponent.INITIAL_VIEW_ZOOM,
      }),
      interactions: defaults({ onFocusOnly: false }),
      layers: [],
      controls: [new ScaleLine()],
    });
  }

  ngOnInit(): void {
    this.map.getView().on("change:rotation", () => {
      this.isKarteGenorded = this.map.getView().getRotation() === 0;
      this.changeDetectorRef.detectChanges();
    });
    this.resizeObserver = new ResizeObserver(() => this.$resizeDebounce.next());

    this.subscriptions.push(
      this.$resizeDebounce.pipe(debounceTime(KarteComponent.RESIZE_DEBOUNCE)).subscribe(() => this.map.updateSize())
    );

    this.subscriptions.push(
      this.urlStorageRepository.findQueryParameter(UrlStorageKeys.HINTERGRUNDKARTE).subscribe(id => {
        const selectedLayerId = (id as string) ?? this.hintergrundebenen[0]?.id;
        this.selectLayer(selectedLayerId);
      })
    );

    if (!this.disableContextMenu) {
      this.map.on("click", event => {
        const radius = 6;
        const bottomLeft = this.map.getCoordinateFromPixel([event.pixel[0] - radius, event.pixel[1] + radius]);
        const topRight = this.map.getCoordinateFromPixel([event.pixel[0] + radius, event.pixel[1] - radius]);
        const extent = bottomLeft.concat(topRight);

        const clickedCoordinate = event.coordinate;
        const geoLocation = new GeoLocation(clickedCoordinate[0], clickedCoordinate[1]);
        const screenCoordinates = [event.originalEvent.clientX, event.originalEvent.clientY];

        if (this.featureDetailContextMenuSubscription) {
          this.featureDetailContextMenuSubscription.unsubscribe();
        }

        this.featureDetailContextMenuSubscription = zip(
          this.geoserverFeatureServices
            .filter(service => service.isActive())
            .map(service => service.getFeatures(extent))
        )
          .pipe(map(x => x.flat()))
          .subscribe((selections: SelectedInfrastrukturFeature[]) => {
            // Durch Verwendung der Map werden doppelte Features (gleiche Property 'id' oder (falls nicht vorhanden) gleiche Id) entdoppelt
            // Doppelte Features enstehen, da dieselben Kanten in mehreren RadVIS-Netz-Layern vorhanden sein können (Bei mehreren Netzklassen für ein Kante)
            const selectableFeatures = new Map<string, SelectedInfrastrukturFeature>();

            selections.forEach(s => {
              selectableFeatures.set(s.feature.get("id") ?? s.feature.getId(), s);
            });

            selections = Array.from(selectableFeatures.values());

            if (selections.length === 0) {
              this.featureDetailContextMenuEntries = [];
              this.featureSelected.emit(null);
            } else if (selections.length === 1) {
              this.featureSelected.emit(selections[0]);
            } else {
              const karteContextMenuEntriesUnsorted = selections.map(s => {
                // Hier einfach den ersten Layernamen nehmen, da wir derzeit nicht wissen von welchen der Layer das Feature genau her kommt.
                const layerName = s.feature.get("layerNames")[0];
                const displayText = this.translateService.instant("radvisViewer.layerNames." + layerName);
                return new KarteContextMenuEntry(
                  displayText,
                  () => {
                    this.featureSelected.emit(s);
                    this.featureDetailContextMenuEntries = [];
                  },
                  s.infrastruktur.icon
                );
              });
              this.featureDetailContextMenuEntries = karteContextMenuEntriesUnsorted.sort((a, b) =>
                a.string.localeCompare(b.string)
              );
              this.changeDetectorRef.detectChanges();
              this.featureDetailContextMenu.openContextMenu(geoLocation, screenCoordinates);
            }
          });
      });
    }

    this.subscriptions.push(
      this.layoutService.screenLayout.subscribe(screenLayout => {
        if (screenLayout === ScreenLayout.Smartphone) {
          this.zoomPadding = KarteComponent.FIT_PADDING_SMARTPHONE;
        } else {
          this.zoomPadding = KarteComponent.FIT_PADDING_DESKTOP;
        }
      })
    );

    this.subscriptions.push(
      ...this.selektierbarePoiInfrastrukturen.map(poiInfrastruktur => {
        return poiInfrastruktur.selected$.subscribe((checked: boolean) => {
          if (checked) {
            if ((this.map.getView().getZoom() ?? 0) < (poiInfrastruktur.minZoom ?? 0)) {
              const translatedLabel = this.translateService.instant(poiInfrastruktur.labelKey);
              this.notificationService.notify("toast.poiZoomLevel", { title: translatedLabel });
            }
          } else {
            const anyInfrastrukturSelected = this.selektierbarePoiInfrastrukturen.find(i => i.isSelected());
            if (!anyInfrastrukturSelected || this.poiSelectionService.selectedPoiInfrastruktur === poiInfrastruktur) {
              this.poiSelectionService.deselectPoi();
              this.featureSelected.next(null);
            }
          }
        });
      })
    );

    this.subscriptions.push(
      ...this.getInfrastrukturenWithPois().map(infrastruktur => {
        return infrastruktur.selected$.subscribe(() => {
          const activePoiLayer = this.getSelectedInfrastrukturenWithPois().map(i => i.getFullWFSLayerDescriptor());
          this.urlStorageRepository.setQueryParameter(UrlStorageKeys.INFRASTRUKTUREN, activePoiLayer);
        });
      })
    );

    this.loadInfrastrukturSelectionFromUrl();
  }

  ngAfterViewInit(): void {
    this.map.setTarget(this.mapId);
    this.resizeObserver.observe(document.querySelector("#" + this.mapId)!);
    this.zoomToExtent(KarteComponent.BADEN_WUERTTEMBERG_EXTENT);

    this.map.on("click", event => this.checkPoiClicked(event));
    this.map.on("pointermove", event => this.$pointerMoveSubject.next(event));
    this.map.on("pointerdrag", () => this.$poiHoverSubject.next(undefined));
    this.map.getView().on("change:resolution", () => {
      this.$poiHoverSubject.next(undefined);
    });

    this.subscriptions.push(
      this.$pointerMoveSubject
        .asObservable()
        .pipe(throttleTime(50))
        .subscribe(event => this.checkPoiHover(event))
    );
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    this.poiSelectionService.deselectPoi();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  poiHover(): Observable<TooltipEvent | undefined> {
    return this.$poiHoverSubject.asObservable();
  }

  addLayer(layer: BaseLayer): void {
    this.map.addLayer(layer);
  }

  addInteraction(interaction: Interaction): void {
    this.map.addInteraction(interaction);
  }

  removeLayer(layer: BaseLayer): void {
    this.map.removeLayer(layer);
  }

  removeInteraction(interaction: Interaction): void {
    this.map.removeInteraction(interaction);
  }

  zoomIn(): void {
    const zoom = this.map.getView()?.getZoom();
    if (zoom) {
      this.map.getView().animate({ zoom: zoom + KarteComponent.ZOOM_OFFSET, duration: KarteComponent.ZOOM_DURATION });
    }
  }

  zoomOut(): void {
    const zoom = this.map.getView()?.getZoom();
    if (zoom) {
      this.map.getView().animate({ zoom: zoom - KarteComponent.ZOOM_OFFSET, duration: KarteComponent.ZOOM_DURATION });
    }
  }

  zoomToExtent(extent: Extent): void {
    this.map.updateSize();
    this.map.getView().fit(extent, {
      duration: KarteComponent.ZOOM_DURATION,
      padding: [
        this.zoomPadding + KarteComponent.FIT_PADDING_TOP_OFFSET,
        this.zoomPadding,
        this.zoomPadding,
        this.zoomPadding,
      ],
    });
  }

  onAddBackgroundLayer(layer: BaseLayer): void {
    this.map.getLayers().insertAt(0, layer);
  }

  /**
   * Wir brauchen das nur für iOS Geräte, da diese das contextmenu-Event nicht unterstützen.
   */
  onLongPress(touchEvent: TouchEvent): void {
    if (!isIOSDevice()) {
      return;
    }

    const simulatedEvent = new MouseEvent("contextmenu", {
      screenX: touchEvent.changedTouches[0].screenX,
      screenY: touchEvent.changedTouches[0].screenY,
      clientX: touchEvent.changedTouches[0].clientX,
      clientY: touchEvent.changedTouches[0].clientY,
      bubbles: true,
      cancelable: true,
      relatedTarget: touchEvent.target,
    });

    touchEvent.target!.dispatchEvent(simulatedEvent);
  }

  onContextMenu(event: MouseEvent): void {
    event.preventDefault();

    this.defaultContextMenu.closeContextMenu();
    this.featureContextMenu.closeContextMenu();

    const clickedCoordinate = this.map.getEventCoordinate(event);
    const geoLocation = new GeoLocation(clickedCoordinate[0], clickedCoordinate[1]);
    const screenCoordinates = [event.pageX, event.pageY];

    const hasFeatureContextmenu = this.map.forEachFeatureAtPixel(
      this.map.getEventPixel(event),
      (feature: Feature<Geometry> | RenderFeature) => {
        if (feature.get(KarteComponent.CONTEXTMENU_SENSITIVE_PROPERTY)) {
          this.featureContextMenu.openContextMenu(geoLocation, screenCoordinates);

          return true;
        }

        return false;
      }
    );

    if (hasFeatureContextmenu) {
      return;
    }

    this.defaultContextMenu.openContextMenu(geoLocation, screenCoordinates);
  }

  onHintergrundebeneSelected(layerId: string): void {
    this.selectLayer(layerId);
  }

  locateMe(): void {
    this.locateMeService.locateMe();
  }

  einnorden(): void {
    this.map.getView().setRotation(0);
  }

  center(coordinates: number[]): void {
    this.map.getView().animate({
      center: coordinates,
      duration: KarteComponent.CENTER_ANIMATION_DURATION,
    });
  }

  viewExtent(): Extent {
    return this.map.getView().calculateExtent(this.map.getSize());
  }

  onKeydown(event: KeyboardEvent): void {
    const center = this.map.getView().getCenter()!;

    if (event.key === "ArrowLeft" || event.key === "Left") {
      center[0] -= KarteComponent.KEYBOARD_CENTER_SHIFT;
    }
    if (event.key === "ArrowUp" || event.key === "Up") {
      center[1] += KarteComponent.KEYBOARD_CENTER_SHIFT;
    }
    if (event.key === "ArrowRight" || event.key === "Right") {
      center[0] += KarteComponent.KEYBOARD_CENTER_SHIFT;
    }
    if (event.key === "ArrowDown" || event.key === "Down") {
      center[1] -= KarteComponent.KEYBOARD_CENTER_SHIFT;
    }

    this.map.getView().setCenter(center);
  }

  getCurrentResolution(): number | undefined {
    return this.map?.getView().getResolution();
  }

  getSelectedInfrastrukturenWithPois(): Infrastruktur[] {
    return this.getInfrastrukturenWithPois().filter(p => p.isSelected());
  }

  getInfrastrukturenWithPois(): Infrastruktur[] {
    return [...this.infrastrukturen, ...this.selektierbarePoiInfrastrukturen];
  }

  private checkPoiHover(event: MapBrowserEvent<any>): void {
    const poiFeatures = this.getPoisAtPixel(event.pixel);

    if (!poiFeatures || poiFeatures.length === 0) {
      this.$poiHoverSubject.next(undefined);
    } else {
      const feature = poiFeatures[0] as Feature<Geometry>;

      const poiQueryService = this.poiQueryServices.find(service => service.hasFeature(feature));
      const poiLayerLabelKey = poiQueryService?.getInfrastruktur().labelKey;

      let coordinate;
      switch (feature.getGeometry()?.getType()) {
        case GeometryType.POINT:
          coordinate = (feature.getGeometry() as Point).getCoordinates();
          break;
        case GeometryType.MULTI_POINT:
          coordinate = (feature.getGeometry() as MultiPoint).getCoordinates()[0];
          break;
        case GeometryType.MULTI_LINE_STRING:
          coordinate = getCenter((feature.getGeometry() as MultiLineString).getLineString(0).getExtent());
      }

      if (!coordinate) {
        this.$poiHoverSubject.next(undefined);
      } else {
        const delta = poiQueryService?.getTooltipDisplacement() || [0, 0];
        const pixel = this.map.getPixelFromCoordinate(coordinate);
        this.$poiHoverSubject.next({
          pixel: [pixel[0] + delta[0], pixel[1] + delta[1]],
          text: this.getTextOfPoiFeature(poiLayerLabelKey, feature),
        });
      }
    }
  }

  private checkPoiClicked(event: MapBrowserEvent<any>): void {
    const poiFeatures = this.getPoisAtPixel(event.pixel);
    if (!poiFeatures || poiFeatures.length === 0) {
      this.poiSelectionService.deselectPoi();
      this.featureSelected.next(null);
    } else {
      const feature = poiFeatures[0] as Feature<Geometry>;
      const poiQueryService = this.poiQueryServices.find(service => service.hasFeature(feature));

      if (!poiQueryService) {
        return;
      }

      const poiInfrastruktur = poiQueryService.getInfrastruktur();
      if (!poiInfrastruktur) {
        return;
      }

      this.poiSelectionService.selectPoi(feature, poiInfrastruktur);
      this.featureSelected.next({
        feature: feature,
        infrastruktur: poiInfrastruktur,
      } as SelectedInfrastrukturFeature);
    }
  }

  private getPoisAtPixel(pixel: number[]): (Feature<Geometry> | RenderFeature)[] {
    const features = this.map.getFeaturesAtPixel(pixel, {
      hitTolerance: KarteComponent.HOVER_HIT_TOLERANCE,
      layerFilter: l => this.poiQueryServices.some(queryService => queryService.isLayer(l)),
    });
    return features;
  }

  private getTextOfPoiFeature(poiLayerLabelKey: string | undefined, feature: Feature<Geometry>): string {
    const layerName = poiLayerLabelKey ? this.translateService.instant(poiLayerLabelKey) + ": " : "";
    return layerName + feature.get("name") || "";
  }

  private selectLayer(layerId: string): void {
    this.urlStorageRepository.setQueryParameter(UrlStorageKeys.HINTERGRUNDKARTE, layerId);
    this.hintergrundebenenService.selectLayer(layerId);
    this.translateService
      .get(this.hintergrundebenen.find(hintergrundebene => hintergrundebene.id === layerId)?.attribution ?? "")
      .subscribe(translation => {
        this.attribution = translation;

        if (layerId !== Hintergrundebene.OSM.id) {
          this.attribution += ", " + this.translateService.instant(Hintergrundebene.OSM.attribution);
        }
      });
  }

  private loadInfrastrukturSelectionFromUrl(): void {
    this.urlStorageRepository
      .getQueryParameter<string[]>(UrlStorageKeys.INFRASTRUKTUREN)
      ?.map(infrastrukturFromQuery => {
        return this.getInfrastrukturenWithPois().find(i => i.getFullWFSLayerDescriptor() === infrastrukturFromQuery);
      })
      .forEach(infrastruktur => infrastruktur?.select(true));
  }

  getZoomForResolution(res: number): number | undefined {
    return this.map.getView().getZoomForResolution(res);
  }
}
