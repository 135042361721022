import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";
import { Circle, Fill, Style } from "ol/style";
import { Feature } from "ol";
import { MapHoehenprofilService } from "../../services/map-hoehenprofil.service";
import { fromLonLat } from "ol/proj";
import { Route } from "src/app/common/entities/route";
import { ColorTone, RrpBwColors } from "../../../common/utils/rrp-bw-colors";
import { containsXY } from "ol/extent";
import { KarteBaseService } from "src/app/common/services/karte-base.service";

@Component({
  selector: "rrpbw-hoehenprofil-layer",
  templateUrl: "./hoehenprofil-layer.component.html",
  styleUrls: ["./hoehenprofil-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoehenprofilLayerComponent implements OnInit, OnDestroy, MapHoehenprofilService {
  readonly layer: VectorLayer<VectorSource<Point>>;

  markedPointIndex: number | undefined;
  routeCoordinates: number[][];

  constructor(public karteService: KarteBaseService) {
    this.layer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      zIndex: 80,
      style: new Style({
        image: new Circle({
          fill: new Fill({
            color: RrpBwColors.getAccentColor(ColorTone.Tone500),
          }),
          radius: 5,
        }),
      }),
    });
  }

  get layerSource(): VectorSource<Point> {
    return this.layer.getSource()!;
  }

  ngOnInit(): void {
    this.karteService.addLayer(this.layer);
  }

  ngOnDestroy(): void {
    this.karteService.removeLayer(this.layer);
  }

  setRoute(route: Route): void {
    this.clearLayer();
    this.routeCoordinates = route.coordinates;
  }

  clearLayer(): void {
    this.layerSource.clear();
  }

  highlightPointAtIndex(index: number | undefined): void {
    this.clearLayer();
    this.markedPointIndex = index;

    if (index != null) {
      const coordinates = fromLonLat(this.routeCoordinates[index]);
      this.layerSource.addFeature(new Feature(new Point(coordinates)));

      const viewExtent = this.karteService.viewExtent();
      if (!containsXY(viewExtent, coordinates[0], coordinates[1])) {
        this.karteService.center(coordinates);
      }
    }
  }
}
