import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { Dialog } from "../../../common/services/dialog.service";
import { NotificationService } from "../../../common/services/notification.service";

@Component({
  selector: "rrpbw-route-teilen-dialog",
  templateUrl: "./route-teilen-dialog.component.html",
  styleUrls: ["./route-teilen-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteTeilenDialogComponent implements Dialog, AfterViewInit {
  readonly title: string = "routenplaner.routeTeilenDialog.dialogTitle";
  readonly description: string = "routenplaner.routeTeilenDialog.dialogDescription";

  @ViewChild("routeTeilenInput", { static: true })
  routeTeilenInput: any;

  currentUrl: string;

  @Output()
  readonly closed: EventEmitter<void> = new EventEmitter();

  constructor(private notificationService: NotificationService) {
    this.currentUrl = window.location.href;
  }

  ngAfterViewInit(): void {
    this.routeTeilenInput.nativeElement.setSelectionRange(0, 0);
  }

  onCopy(): void {
    this.notificationService.notify("routenplaner.routeTeilenDialog.snackBarMessage");
    this.closed.emit();
  }
}
