import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from "@angular/core";
import { fromEvent, Subscription, timer } from "rxjs";

@Directive({
  selector: "[rrpbwAdditionalTouchEvents]",
})
export class AdditionalTouchEventsDirective implements OnDestroy {
  static readonly LONG_PRESS_THRESHOLD = 750;

  @Output()
  longPress: EventEmitter<TouchEvent> = new EventEmitter();

  subscriptions: Subscription[] = [];

  constructor(private elementRef: ElementRef) {
    let timerSubscription: Subscription;

    this.subscriptions.push(
      fromEvent(elementRef.nativeElement, "touchstart").subscribe((event: any) => {
        timerSubscription?.unsubscribe();

        timerSubscription = timer(AdditionalTouchEventsDirective.LONG_PRESS_THRESHOLD).subscribe(() => {
          this.longPress.emit(event);
        });
      }),
      fromEvent(elementRef.nativeElement, "touchend").subscribe(() => {
        timerSubscription.unsubscribe();
      }),
      fromEvent(elementRef.nativeElement, "touchmove").subscribe(() => {
        timerSubscription.unsubscribe();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
