import { DetailProperty } from "src/app/karte/entities/detail-property";

export class DetailProperties {
  static readonly DEFAULT_PROPERTY_ICON = "info";

  static readonly NAME: DetailProperty = { key: "name" };
  // Kontaktinfos
  static readonly PHONE: DetailProperty = { key: "phone", icon: "phone" };
  static readonly EMAIL: DetailProperty = { key: "email", icon: "alternate_email" };
  static readonly WEBSITE: DetailProperty = { key: "website", icon: "public" };
  // Adress- und Ortsinformationen
  static readonly STREET: DetailProperty = { key: "street", icon: "home" };
  static readonly STREET_NAME: DetailProperty = { key: "strassen_n", icon: "home" };
  static readonly STREET_NUMBER: DetailProperty = { key: "strassen_0", icon: "home" };
  static readonly PLACE: DetailProperty = { key: "place", icon: "place" };
  static readonly POSTCODE: DetailProperty = { key: "postcode" };
  static readonly GEMEINDE_NAME: DetailProperty = { key: "gemeinde_n", icon: "place" };
  static readonly LANDKREIS_NAME: DetailProperty = { key: "landkreis_", icon: "place" };
  // POI
  static readonly SOURCE: DetailProperty = { key: "src_text" };
  static readonly SOURCE_URL: DetailProperty = { key: "src_url" };
  static readonly ACCOMMODATION: DetailProperty = { key: "accommodation", icon: "hotel" };
  static readonly GASTRONOMY: DetailProperty = { key: "gastronomy", icon: "restaurant" };
  static readonly SIZE: DetailProperty = { key: "size", icon: "straighten", isEnum: true };
  static readonly OPERATOR: DetailProperty = { key: "operator", icon: "group" };
  static readonly SUPPLIER: DetailProperty = { key: "supplier", icon: "engineering" };
  static readonly ELEVATION: DetailProperty = { key: "elevation", icon: "landscape" };
  static readonly LINES: DetailProperty = { key: "route_short_names", icon: "route" };
  static readonly ROUTE_TYPES: DetailProperty = { key: "route_types" };
  static readonly ROUTE_TYPES_NAMES: DetailProperty = { key: "route_types_names", icon: "train" };
  static readonly DEEP_LINK: DetailProperty = { key: "deep_link", icon: "public" };
  // Massnahmen - Properties
  static readonly BEZEICHNUNG: DetailProperty = { key: "bezeichnun" }; // Bezeichnung (Überschrift)
  static readonly ID: DetailProperty = { key: "id" }; // Massnahme Konzept Id
  static readonly UMSETZUNGSSTATUS: DetailProperty = { key: "umsetzungs", icon: "playlist_add_check", isEnum: true }; // Umsetzungsstatus
  static readonly KATEGORIEN: DetailProperty = { key: "massnahmen", icon: "category", isEnum: true }; // Kategorien
  static readonly BAULASTTRAEGER: DetailProperty = { key: "baulasttra", icon: "engineering" }; // Baulastträger (Name)
  static readonly DURCHFUEHRUNGSZEITRAUM: DetailProperty = { key: "durchfuehr", icon: "schedule" }; // Durchführungszeitraum
  static readonly MARKIERUNGSZUSTAENDIGER: DetailProperty = { key: "markierung", icon: "person" }; // Markierungszuständiger (Name)
  static readonly UNTERHALTSZUSTAENDIGER: DetailProperty = { key: "unterhalts", icon: "person" }; // Unterhaltszuständiger (Name)
  static readonly QUELLE: DetailProperty = { key: "konzeption", icon: "source", isEnum: true }; // Quelle
  // Netzinfos
  static readonly NETZKLASSE: DetailProperty = { key: "netzklasse", isEnum: true };
  static readonly WEGNIVEAU: DetailProperty = { key: "wege_nivea", isEnum: true };
  static readonly BELEUCHTUNG: DetailProperty = { key: "beleuchtun", icon: "tungsten", isEnum: true };
  static readonly UMFELD: DetailProperty = { key: "umfeld", icon: "maps_home_work", isEnum: true };
  static readonly STRASSENQUERSCHNITT: DetailProperty = { key: "strassenqu", icon: "hide_source", isEnum: true };
  static readonly BELAGART: DetailProperty = { key: "belag_art", isEnum: true };
  static readonly OBERFLAECHE_BESCHAFFENHEIT: DetailProperty = { key: "oberflaech", isEnum: true };
  static readonly BORDSTEIN: DetailProperty = { key: "bordstein", isEnum: true };
  static readonly RADVERKEHR_FUEHRUNG: DetailProperty = { key: "radverkehr", isEnum: true };
  static readonly BENUTZUNGSPFLICHT: DetailProperty = { key: "benutzungs", isEnum: true };
  static readonly KFZ_PARKEN_FORM: DetailProperty = { key: "parken_for", icon: "local_parking", isEnum: true };
  static readonly KFZ_PARKEN_TYP: DetailProperty = { key: "parken_typ", icon: "local_parking", isEnum: true };
  static readonly BREITE: DetailProperty = { key: "breite", icon: "straighten" };
  // Wegweisende Beschilderung
  static readonly PFOSTEN_NR: DetailProperty = { key: "pfosten_nr", icon: "signpost" };
  // Fahrradrouten
  static readonly KURZBESCHREIBUNG: DetailProperty = { key: "kurzbeschreibung" };
  static readonly FAHRRADROUTE_BESCHREIBUNG: DetailProperty = { key: "beschreibung" };
  static readonly TOURENKATEGORIE: DetailProperty = { key: "tourenkategorie", isEnum: true };
  static readonly KATEGORIE: DetailProperty = { key: "kategorie", isEnum: true };
  static readonly VARIANTENKATEGORIE: DetailProperty = { key: "variante_kategorie", isEnum: true };
  static readonly VERANTWORTLICH: DetailProperty = { key: "verantwortlich_name", icon: "person" };
  static readonly HOMEPAGE: DetailProperty = { key: "homepage", icon: "public" };
  static readonly OFFIZIELLE_LAENGE: DetailProperty = { key: "offizielle_laenge", icon: "straighten" };
  static readonly LAENGE_DER_HAUPTSTRECKE: DetailProperty = { key: "laenge", icon: "straighten" };
  static readonly ANSTIEG: DetailProperty = { key: "anstieg", icon: "trending_up" };
  static readonly ABSTIEG: DetailProperty = { key: "abstieg", icon: "trending_down" };
  static readonly LIZENZ: DetailProperty = { key: "lizenz", icon: "assignment" };
  static readonly LIZENZNAMENSNENNUNG: DetailProperty = { key: "lizenz_namensnennung", icon: "assignment" };
  static readonly ZUSAETLICHE_INFORMATIONEN: DetailProperty = { key: "info" };

  // Barrieren
  static readonly BARRIERENFORM: DetailProperty = { key: "barrieren_", isEnum: true };
  static readonly BARRIERENDURCHFAHRBREITE: DetailProperty = { key: "verbleiben", isEnum: true, icon: "straighten" };
  static readonly ABSPERRANLAGENSICHERUNG: DetailProperty = { key: "sicherung", isEnum: true };
  static readonly ABSPERRANLAGENMARKIERUNG: DetailProperty = {
    key: "markierung",
    isEnum: true,
    translateKey: "barrieren_markierung",
  };

  // Bis2
  static readonly ANZ_TAGE: DetailProperty = { key: "anz_tage" };
  static readonly ARBEITSSTELLEN_NUMMER: DetailProperty = { key: "arbeitsstellen_nummer" };
  static readonly ART_DER_ARBEITSSTELLE: DetailProperty = { key: "art_der_arbeitsstelle" };
  static readonly ART_DER_MASSNAHME: DetailProperty = { key: "art_der_massnahme" };
  static readonly AUSFUEHRUNGS_ZEITEN: DetailProperty = { key: "ausfuehrungs_zeiten", icon: "punch_clock" };
  static readonly BEZEICHNUNG_OEFFENTLICH: DetailProperty = { key: "bezeichnung_oeffentlich" };
  static readonly BIS_DATUM: DetailProperty = { key: "bis_datum", icon: "free_cancellation" };
  static readonly BIS_UHRZEIT: DetailProperty = { key: "bis_uhrzeit", icon: "timer_off" };
  static readonly DOKUMENT_1: DetailProperty = { key: "dokument_1" };
  static readonly DOKUMENT_2: DetailProperty = { key: "dokument_2" };
  static readonly DOKUMENT_3: DetailProperty = { key: "dokument_3" };
  static readonly STRASSENKLASSE: DetailProperty = { key: "strassenklasse" };
  static readonly LAENGE: DetailProperty = { key: "laenge", icon: "straighten", translateKey: "bis2_laenge" };
  static readonly LANDKREIS: DetailProperty = { key: "landkreis" };
  static readonly NACHTBAUSTELLE: DetailProperty = { key: "nachtbaustelle", isEnum: true };
  static readonly STRASSENNUMMER: DetailProperty = { key: "strassennummer", icon: "place" };
  static readonly REGIERUNGSBEZIRK: DetailProperty = { key: "regierungsbezirk" };
  static readonly STATUS: DetailProperty = { key: "status" };
  static readonly STRASSE: DetailProperty = { key: "strasse", icon: "place" };
  static readonly URL: DetailProperty = { key: "url" };
  static readonly VON_DATUM: DetailProperty = { key: "von_datum", icon: "calendar_month" };
  static readonly VON_UHRZEIT: DetailProperty = { key: "von_uhrzeit", icon: "timer" };
  static readonly ZUSATZBUCHSTABE: DetailProperty = { key: "zusatzbuchstabe" };
  static readonly KONTAKT_EMAIL: DetailProperty = { key: "kontakt_email" };

  static readonly FAHRSTREIFEN_NUTZUNG: DetailProperty = { key: "fahrstreifen_nutzung" };
  static readonly FERNZIEL: DetailProperty = { key: "fernziel", icon: "place" };
  static readonly LAGEBESCHREIBUNG: DetailProperty = { key: "lagebeschreibung" };
  static readonly NACHBKM: DetailProperty = { key: "nachbkm" };
  static readonly RSA_REGELPLAN: DetailProperty = { key: "rsa_regelplan" };
  static readonly SPERRUNGS_TYP: DetailProperty = { key: "sperrungs_typ" };
  static readonly VEKEHRLICHE_AUSWIRKUNG: DetailProperty = { key: "vekehrliche_auswirkung" };
  static readonly VEROEFFENTLICHUNGS_DATUM: DetailProperty = {
    key: "veroeffentlichungs_datum",
    icon: "calendar_month",
  };

  static readonly VONBKM: DetailProperty = { key: "vonbkm" };
  static readonly WEITERE_HINWEISE_VERKEHRSFUEHRUNG: DetailProperty = { key: "weitere_hinweise_verkehrsfuehrung" };
  static readonly MAX_BREITE: DetailProperty = { key: "max_breite", icon: "straighten" };
  static readonly MAX_GESCHWINDIGKEIT: DetailProperty = { key: "max_geschwindigkeit", icon: "speed" };
  static readonly MAX_GEWICHT: DetailProperty = { key: "max_gewicht" };
  static readonly MAX_HOEHE: DetailProperty = { key: "max_hoehe", icon: "straighten" };

  static readonly ARBEITSSTELLEN_PHASEN_UMLEITUNG_ID: DetailProperty = { key: "arbeitsstellen_phasen_umleitung_id" };
  static readonly BESCHREIBUNG: DetailProperty = { key: "beschreibung" };
  static readonly MEHRWEG: DetailProperty = { key: "mehrweg" };
  static readonly MEHRZEIT: DetailProperty = { key: "mehrzeit" };

  // Serviceangebote - Shared
  static readonly BETREIBER: DetailProperty = { key: "betreiber", icon: "person" }; //	String	true	0/1
  static readonly ZUSTAENDIG: DetailProperty = { key: "zustaendig", icon: "person" }; //	Long	true	0/1
  static readonly SERVICEANGEBOT_BESCHREIBUNG: DetailProperty = { key: "beschreibu" }; //	String	true	0/1
  static readonly SERVICEANGEBOT_STATUS: DetailProperty = {
    key: "status",
    isEnum: true,
  };

  // Serviceangebote - Abstellanlagen
  static readonly QUELL_SYSTEM: DetailProperty = { key: "quell_syst", isEnum: true }; //	String	true	0/1
  static readonly ANZAHL_STELLPLAETZE: DetailProperty = { key: "anzahl_ste" }; //	Integer	true	0/1
  static readonly ANZAHL_SCHLIESSFAECHER: DetailProperty = { key: "anzahl_sch" }; //	Integer	true	0/1
  static readonly ANZAHL_LADEMOEGLICHKEITEN: DetailProperty = { key: "anzahl_lad" }; //	Integer	true	0/1
  static readonly UEBERWACHT: DetailProperty = { key: "ueberwacht", isEnum: true, icon: "security" }; //	String	true	0/1
  static readonly IST_BIKE_AND_RIDE: DetailProperty = { key: "ist_bike_a", isEnum: true }; //	Boolean	true	0/1
  static readonly GROESSENKL: DetailProperty = { key: "groessenkl" }; //	String	true	0/1
  static readonly STELLPLATZART: DetailProperty = { key: "stellplatz" }; //	String	true	0/1
  static readonly UEBERDACHT: DetailProperty = { key: "ueberdacht", isEnum: true, icon: "roofing" }; //	Boolean	true	0/1
  static readonly GEBUEHR_TAG: DetailProperty = { key: "gebuehr_ta", icon: "euro" }; //	Integer	true	0/1
  static readonly GEBUEHR_MONAT: DetailProperty = { key: "gebuehr_mo", icon: "euro" }; //	Integer	true	0/1
  static readonly GEBUEHR_JAHR: DetailProperty = { key: "gebuehr_ja", icon: "euro" }; //	Integer	true	0/1
  static readonly WEITERE_INFORMATIONEN: DetailProperty = { key: "weitere_in" }; //	String	true	0/1

  // Serviceangebote - Leihstation
  static readonly ANZAHL_FAHRRAEDER: DetailProperty = { key: "anzahl_fah" }; //	Integer	true	0/1
  static readonly ANZAHL_PEDELECS: DetailProperty = { key: "anzahl_ped" }; //	Integer	true	0/1
  static readonly ANZAHL_ABSTELLMOEGLICHKEITEN: DetailProperty = { key: "anzahl_abs" }; //	Integer	true	0/1
  static readonly FREIES_ABSTELLEN: DetailProperty = { key: "freies_abs", isEnum: true }; //	Boolean	true	0/1
  static readonly BUCHUNGS_URL: DetailProperty = { key: "buchungs_u", icon: "public" }; //	String	true	0/1

  // Serviceangebote - Servicestation
  static readonly SERVICESTATION_NAME: DetailProperty = { key: "name" }; //	String	true	0/1
  static readonly GEBUEHREN: DetailProperty = { key: "gebuehren", isEnum: true, icon: "euro" }; //	Boolean	true	0/1
  static readonly OEFFNUNGSZEITEN: DetailProperty = { key: "oeffnungsz", icon: "access_time" }; //	String	true	0/1
  static readonly MARKE: DetailProperty = { key: "marke" }; //	String	true	0/1
  static readonly LUFTPUMPE: DetailProperty = { key: "luftpumpe", isEnum: true }; //	Boolean	true	0/1
  static readonly KETTENWERKZEUG: DetailProperty = { key: "kettenwerk", isEnum: true }; //	Boolean	true	0/1
  static readonly WERKZEUG: DetailProperty = { key: "werkzeug", isEnum: true, icon: "construction" }; //	Boolean	true	0/1
  static readonly FAHRRADHALTERUNG: DetailProperty = { key: "fahrradhal", isEnum: true }; //	Boolean	true	0/1
  static readonly SERVICESTATION_TYP: DetailProperty = { key: "typ", isEnum: true }; //	String	true	0/1

  constructor(private properties: DetailProperty[]) {}

  public isVisible(propertyKey: string): boolean {
    return this.visiblePropertyKeys.includes(propertyKey);
  }

  public filterDetailProperties(properties: { [p: string]: any }): Map<string, string> {
    const allPropertyEntries = Object.entries(properties).filter(p => p[0] !== "geometry") as [string, string][];
    const filteredAndSortedEntries = allPropertyEntries
      // filter empty values
      .filter(entry => entry[0] != null && entry[1] != null)
      .filter(entry => entry[1].toString().trim() !== "")
      .filter(entry => entry[0].toString().trim() !== "")
      // apply additinal Filters
      .filter(
        entry =>
          entry[0] !== DetailProperties.ELEVATION.key ||
          (entry[0] === DetailProperties.ELEVATION.key && +entry[1] !== 0)
      )
      .sort(
        (a, b) =>
          // Anhand der Sortierung der sichtbaren properties sortieren
          this.visiblePropertyKeys.indexOf(a[0]) - this.visiblePropertyKeys.indexOf(b[0])
      );
    return new Map<string, string>(filteredAndSortedEntries);
  }

  public get(key: string): DetailProperty | undefined {
    return this.properties.find(prop => prop.key === key);
  }

  private get visiblePropertyKeys(): String[] {
    return this.properties.map(p => p.key);
  }
}
