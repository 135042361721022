import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from "@angular/core";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Geometry } from "ol/geom";
import { Stroke, Style } from "ol/style";
import { Feature } from "ol";
import { isObservable, Observable, Subscription } from "rxjs";

@Component({
  selector: "rrpbw-simple-feature-layer",
  templateUrl: "./simple-feature-layer.component.html",
  styleUrls: ["./simple-feature-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleFeatureLayerComponent implements AfterViewInit, OnDestroy, OnChanges {
  readonly featureLayer: VectorLayer<VectorSource<Geometry>>;

  @Input()
  style: Style | Style[] | Observable<Style | Style[]>;

  @Input()
  zoomToFeature?: Observable<void>;

  @Input()
  geometry: Geometry | Observable<Geometry | undefined> | undefined;

  subscriptions: Subscription[] = [];

  styleSubscription: Subscription | undefined;

  static readonly DEFAULT_STYLE_RED = [
    new Style({
      stroke: new Stroke({
        width: 3,
        color: "red",
      }),
    }),
  ];

  constructor(private karteBaseService: KarteBaseService) {
    this.featureLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style: SimpleFeatureLayerComponent.DEFAULT_STYLE_RED,
      zIndex: 50,
    });
  }

  ngAfterViewInit(): void {
    this.updateStyle();

    if (isObservable(this.geometry)) {
      this.subscriptions.push(this.geometry.subscribe(g => this.addFeature(g)));
    } else {
      this.addFeature(this.geometry);
    }

    if (this.zoomToFeature) {
      this.subscriptions.push(this.zoomToFeature.subscribe(() => this.zoom()));
    }
  }

  ngOnDestroy(): void {
    this.karteBaseService.removeLayer(this.featureLayer);
    this.subscriptions.forEach(s => s.unsubscribe());
    this.styleSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.style) {
      this.updateStyle();
    }
  }

  private updateStyle(): void {
    if (isObservable(this.style)) {
      this.styleSubscription?.unsubscribe();
      this.styleSubscription = this.style.subscribe(style => this.featureLayer.setStyle(style));
    } else {
      this.featureLayer.setStyle(this.style);
    }
  }

  private addFeature(geometry: Geometry | undefined | null): void {
    if (geometry) {
      this.featureLayer.getSource()?.addFeature(new Feature(geometry));
      this.karteBaseService.addLayer(this.featureLayer);
      if (!this.zoomToFeature) {
        setTimeout(() => this.karteBaseService.zoomToExtent(geometry.getExtent()), 100);
      }
    }
  }

  zoom(): void {
    const geometry = this.featureLayer.getSource()?.getFeatures()[0].getGeometry();
    if (geometry) {
      this.karteBaseService.zoomToExtent(geometry.getExtent());
    }
  }
}
