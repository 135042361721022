<rrpbw-informationen-anzeige-layout [smallTopMargin]="true">
  <h1>{{ "fahrradrouten.heading" | translate }}</h1>
  <div *ngIf="!loading">
    <rrpbw-fahrradrouten-filter
      [allFahrradroutenKategorien]="allFahrradroutenKategorien"
      [allFahrradroutenTourenkategorien]="allFahrradroutenTourenkategorien"
    ></rrpbw-fahrradrouten-filter>
  </div>

  <div *ngIf="!loading" class="routencard-container">
    <div *ngIf="allFahrradrouten.length === 0" class="mt-xl mb-m">
      {{ "fahrradrouten.list.keineRouten" | translate }}
    </div>
    <div *ngIf="allFahrradrouten.length !== 0 && fahrradrouten.length === 0" class="mt-xl mb-m">
      {{ "fahrradrouten.list.keineGefiltertenRouten" | translate }}
    </div>
    <mat-card
      *ngFor="let route of fahrradrouten.slice(pageSize * page, pageSize * (page + 1))"
      class="routencard"
      tabindex="0"
      (click)="onFahrradrouteClicked(route.id)"
      (keydown.enter)="onFahrradrouteClicked(route.id)"
    >
      <rrpbw-karte-view-only [thumbailLayout]="true" class="route-thumbnail">
        <rrpbw-simple-feature-layer [geometry]="getRouteGeom(route)" [style]="ROUTE_STYLE_THIN">
        </rrpbw-simple-feature-layer>
      </rrpbw-karte-view-only>
      <mat-card-header>
        <mat-card-subtitle class="routendetails">
          <rrpbw-property-label
            [text]="route.laenge | distance"
            [ariaLabel]="'routendetails.distance' | translate"
            icon="settings_ethernet"
          ></rrpbw-property-label>
          <rrpbw-property-label
            [text]="route.anstieg | distance"
            [ariaLabel]="'routendetails.elevationUp' | translate"
            icon="north_east"
          ></rrpbw-property-label>
          <rrpbw-property-label
            [text]="route.abstieg | distance"
            [ariaLabel]="'routendetails.elevationDown' | translate"
            icon="south_east"
          ></rrpbw-property-label>
        </mat-card-subtitle>
        <mat-card-title class="routencard-title">{{ route.name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content class="routencard-content">
        <div [innerHTML]="route.kurzbeschreibung"></div>
      </mat-card-content>
    </mat-card>
  </div>
  <mat-paginator
    [pageSizeOptions]="[12, 24, 48]"
    showFirstLastButtons
    [attr.aria-label]="'paginator.choosePage' | translate"
    (page)="onPageChanged($event)"
  ></mat-paginator>
  <div class="spinner-container" *ngIf="loading">
    <mat-spinner color="primary"></mat-spinner>
  </div>
</rrpbw-informationen-anzeige-layout>
