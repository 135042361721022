import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "rrpbw-meldeplattform",
  templateUrl: "./meldeplattform.component.html",
  styleUrls: ["./meldeplattform.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeldeplattformComponent {
  constructor(private activatedRoute: ActivatedRoute) {}

  public generateEmailLink(): string {
    const pfostennummer = this.activatedRoute.snapshot.queryParamMap.get("pfostennummer") ?? "<bitte angeben>";
    return `mailto:radnetz@nvbw.de?subject=Meldung%20f%C3%BCr%20RadNETZ-Beschilderung%20an%20Wegweiser-ID%20${pfostennummer}`;
  }
}
