<div cdkDropList class="drag-list" (cdkDropListDropped)="onReorder($event)">
  <ng-container *ngFor="let adresse of adresseAutocomplete; index as index">
    <div
      class="drag-container row"
      [class.mb-s]="index === 0 || index === zielIndex - 1"
      [class.mb-m]="index < zielIndex - 1 && index !== 0"
      [class.display-none]="index !== 0 && index !== zielIndex && !zwischenzieleSichtbar"
      cdkDrag
      [cdkDragDisabled]="!showZwischenziele"
      (cdkDragEnded)="onDragEnded()"
    >
      <mat-icon
        tabindex="0"
        *ngIf="showZwischenziele"
        class="contrast drag-handle focus"
        (keydown)="onKeydown($event, index)"
        (mousedown)="onMousedown()"
        (mousemove)="onMousemove()"
        (mouseup)="onMouseup()"
        cdkDragHandle
        [attr.aria-label]="'wegpunktEditor.dragHandle' | translate"
        >drag_handle
      </mat-icon>
      <rrpbw-adresse-suche
        class="contrast"
        [label]="getLabel(index)"
        [labelPrefix]="getLabelPrefix(index)"
        [itemFn]="adresseQueryChangedFn"
        [$item]="adresse.$item"
        [alwaysShowDeleteButton]="alwaysShowDeleteButton(index)"
        (itemSelected)="onWegpunktItemSelected($event, index)"
        (cleared)="onAdresseCleared(index)"
        (deleteButtonClicked)="onRemoveWegpunktClicked(index)"
      >
      </rrpbw-adresse-suche>
    </div>
    <div *ngIf="index === 0 && adresseAutocomplete.length > 2" class="row mb-xs">
      <button
        *ngIf="showZwischenziele && canSwitchWegpunkte"
        mat-button
        class="switch-button mr-s inZwischenzieleZeile focus"
        (click)="onSwitchWegpunkteClicked()"
        [attr.aria-label]="'wegpunktEditor.richtungUmdrehen' | translate"
      >
        <mat-icon class="contrast">import_export</mat-icon>
      </button>
      <rrpbw-divider
        *ngIf="!zwischenzieleAusgeblendet"
        tabindex="0"
        [contrast]="true"
        icon="arrow_drop_down"
        (click)="toggleZwischenzieleAusblenden()"
        (keydown)="onKeyboardSubmit($event, toggleZwischenzieleAusblenden, this)"
        [ariaLabel]="zwischenzieleAusblendenToggleLabel | translate : { amount: adresseAutocomplete.length - 2 }"
        >{{
          zwischenzieleAusblendenToggleLabel | translate : { amount: adresseAutocomplete.length - 2 }
        }}</rrpbw-divider
      >
      <rrpbw-divider
        *ngIf="zwischenzieleAusgeblendet"
        tabindex="0"
        [contrast]="true"
        svgIcon="open-arrows"
        (click)="toggleZwischenzieleAusblenden()"
        (keydown)="onKeyboardSubmit($event, toggleZwischenzieleAusblenden, this)"
        [ariaLabel]="zwischenzieleEinblendenAriaLabel | translate : { amount: adresseAutocomplete.length - 2 }"
        >{{
          zwischenzieleEinblendenToggleLabel | translate : { amount: adresseAutocomplete.length - 2 }
        }}</rrpbw-divider
      >
    </div>
    <div *ngIf="index === zielIndex - 1" class="row mb-xs" [class.display-none]="!zwischenzieleSichtbar">
      <button
        *ngIf="!showZwischenziele && canSwitchWegpunkte"
        mat-button
        class="switch-button mr-xs focus"
        (click)="onSwitchWegpunkteClicked()"
        [attr.aria-label]="'wegpunktEditor.richtungUmdrehen' | translate"
      >
        <mat-icon class="contrast">import_export</mat-icon>
      </button>
      <rrpbw-divider
        tabindex="0"
        [class.plus-button]="showZwischenziele"
        [contrast]="true"
        (click)="onAddWegpunktClicked(zielIndex - 1)"
        (keydown)="onKeyboardSubmit($event, onAddWegpunktClicked, this, zielIndex - 1)"
        [ariaLabel]="'wegpunktEditor.zwischenzieleToggle.wegpunktHinzufügen' | translate"
      ></rrpbw-divider>
    </div>
  </ng-container>
</div>
