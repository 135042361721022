declare let _paq: any;

export class Analytics {
  static pushEvent(action: string, category: string, name?: string, value?: number): void {
    if (typeof _paq === "undefined") {
      return;
    }
    _paq.push(["trackEvent", category, action, name, value]);
  }
}
