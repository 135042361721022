<mat-tab-group [(selectedIndex)]="selectedTabIndex" [disablePagination]="true" [animationDuration]="0">
  <mat-tab label="{{ 'routenplaner.karteBedienhinweis.routenplanung' | translate }}" *ngIf="!routeMitWegpunkten">
    <rrpbw-mobile-karte-bedienhinweis (routenplanungBearbeiten)="routenplanungBearbeiten.emit()">
    </rrpbw-mobile-karte-bedienhinweis>
  </mat-tab>
  <mat-tab label="{{ 'routenplaner.details' | translate }}" *ngIf="!!routeMitWegpunkten">
    <div class="tab-container">
      <rrpbw-routendetails
        [duration]="routeMitWegpunkten.route?.duration"
        [distance]="routeMitWegpunkten.route?.distance"
        [totalAscend]="routeMitWegpunkten.route?.totalAscend"
        [totalDescend]="routeMitWegpunkten.route?.totalDescend"
      ></rrpbw-routendetails>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'routenplaner.hoehenprofil' | translate }}" *ngIf="!!routeMitWegpunkten">
    <div class="tab-container">
      <rrpbw-mobile-hoehenprofil
        *ngIf="selectedTabIndex === getTabIndex(SelectableTab.HOEHENPROFIL)"
        [routeMitWegpunkten]="routeMitWegpunkten"
        (selectedElementIndex)="onHoehenprofilPointMarked($event)"
      ></rrpbw-mobile-hoehenprofil>
    </div>
  </mat-tab>
  <mat-tab label="{{ 'routenplaner.pois' | translate }}" *ngIf="!!selectedPoi">
    <rrpbw-details
      [showHeading]="false"
      [selectedFeature]="selectedPoi"
      [layerName]="selectedPoi?.infrastruktur?.labelKey ?? '' | translate"
      [iconPath]="selectedPoi?.infrastruktur?.mapIcon ?? ''"
      [iconColor]="selectedPoi?.infrastruktur?.iconColor ?? ''"
    ></rrpbw-details>
  </mat-tab>
  <mat-tab *ngIf="fahrradroutenAnzeigen" label="{{ 'routenplaner.fahrradrouten.fahrradrouten' | translate }}">
    <div class="tab-container">
      <rrpbw-fahrradroute-auswahl class="mt-s" [isSmartphoneLayout]="true"></rrpbw-fahrradroute-auswahl>
    </div>
  </mat-tab>
</mat-tab-group>
