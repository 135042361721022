import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DownloadService {
  constructor() {}

  download(data: string, filename: string, mimeType = "text/text"): void {
    const downloadElement = document.createElement("a");

    downloadElement.setAttribute("href", "data:" + mimeType + ";charset=utf-8," + data);
    downloadElement.setAttribute("download", filename);

    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
  }
}
