export enum Radverkehrsfuehrung {
  FUEHRUNG_AUF_FAHRBAHN_VIER_MEHRSTREIFIGE_FAHRBAHN = "FUEHRUNG_AUF_FAHRBAHN_VIER_MEHRSTREIFIGE_FAHRBAHN",
  FUEHRUNG_AUF_FAHRBAHN_ZWEISTREIFIGE_FAHRBAHN = "FUEHRUNG_AUF_FAHRBAHN_ZWEISTREIFIGE_FAHRBAHN",

  PIKTOGRAMMKETTE = "PIKTOGRAMMKETTE",
  SCHUTZSTREIFEN = "SCHUTZSTREIFEN",
  RADFAHRSTREIFEN = "RADFAHRSTREIFEN",
  RADFAHRSTREIFEN_MIT_FREIGABE_BUSVERKEHR = "RADFAHRSTREIFEN_MIT_FREIGABE_BUSVERKEHR",
  BUSFAHRSTREIFEN_MIT_FREIGABE_RADVERKEHR = "BUSFAHRSTREIFEN_MIT_FREIGABE_RADVERKEHR",
  MEHRZWECKSTREIFEN = "MEHRZWECKSTREIFEN",

  FUEHRUNG_IN_T20_ZONE = "FUEHRUNG_IN_T20_ZONE",
  FUEHRUNG_IN_T30_ZONE = "FUEHRUNG_IN_T30_ZONE",
  FUEHRUNG_IN_VERKEHRSBERUHIGTER_BEREICH = "FUEHRUNG_IN_VERKEHRSBERUHIGTER_BEREICH",

  FUEHRUNG_IN_FAHRRADSTRASSE = "FUEHRUNG_IN_FAHRRADSTRASSE",
  FUEHRUNG_IN_FAHRRADZONE = "FUEHRUNG_IN_FAHRRADZONE",

  EINBAHNSTRASSE_MIT_FREIGABE_RADVERKEHR_MEHR_WENIGER_30 = "EINBAHNSTRASSE_MIT_FREIGABE_RADVERKEHR_MEHR_WENIGER_30",
  EINBAHNSTRASSE_OHNE_FREIGABE_RADVERKEHR_MEHR_ALS_30 = "EINBAHNSTRASSE_OHNE_FREIGABE_RADVERKEHR_MEHR_ALS_30",
  EINBAHNSTRASSE_OHNE_FREIGABE_RADVERKEHR_MEHR_WENIGER_30 = "EINBAHNSTRASSE_OHNE_FREIGABE_RADVERKEHR_MEHR_WENIGER_30",

  BEGEGNUNGSZONE = "BEGEGNUNGSZONE",
  FUEHRUNG_IN_FUSSG_ZONE_RAD_FREI = "FUEHRUNG_IN_FUSSG_ZONE_RAD_FREI",
  FUEHRUNG_IN_FUSSG_ZONE_RAD_NICHT_FREI = "FUEHRUNG_IN_FUSSG_ZONE_RAD_NICHT_FREI",
  FUEHRUNG_IN_FUSSG_ZONE_RAD_ZEITW_FREI = "FUEHRUNG_IN_FUSSG_ZONE_RAD_ZEITW_FREI",

  OEFFENTLICHE_STRASSE_MIT_FREIGABE_ANLIEGER = "OEFFENTLICHE_STRASSE_MIT_FREIGABE_ANLIEGER",
  BETRIEBSWEG_FORST = "BETRIEBSWEG_FORST",
  BETRIEBSWEG_LANDWIRTSCHAFT_SELBSTSTAENDIG = "BETRIEBSWEG_LANDWIRTSCHAFT_SELBSTSTAENDIG",
  BETRIEBSWEG_LANDWIRTSCHAFT_STRASSENBEGLEITEND = "BETRIEBSWEG_LANDWIRTSCHAFT_STRASSENBEGLEITEND",
  BETRIEBSWEG_WASSERWIRTSCHAFT = "BETRIEBSWEG_WASSERWIRTSCHAFT",
  SONSTIGER_BETRIEBSWEG = "SONSTIGER_BETRIEBSWEG",

  SONDERWEG_RADWEG_SELBSTSTAENDIG = "SONDERWEG_RADWEG_SELBSTSTAENDIG",
  GEHWEG_RAD_FREI_SELBSTSTAENDIG = "GEHWEG_RAD_FREI_SELBSTSTAENDIG",
  GEH_RADWEG_GETRENNT_SELBSTSTAENDIG = "GEH_RADWEG_GETRENNT_SELBSTSTAENDIG",
  GEH_RADWEG_GEMEINSAM_SELBSTSTAENDIG = "GEH_RADWEG_GEMEINSAM_SELBSTSTAENDIG",
  GEM_RAD_GEHWEG_MIT_GEHWEG_GEGENRICHTUNG_FREI_SELBSTSTAENDIG = "GEM_RAD_GEHWEG_MIT_GEHWEG_GEGENRICHTUNG_FREI_SELBSTSTAENDIG",

  SONDERWEG_RADWEG_STRASSENBEGLEITEND = "SONDERWEG_RADWEG_STRASSENBEGLEITEND",
  GEM_RAD_GEHWEG_MIT_GEHWEG_GEGENRICHTUNG_FREI_STRASSENBEGLEITEND = "GEM_RAD_GEHWEG_MIT_GEHWEG_GEGENRICHTUNG_FREI_STRASSENBEGLEITEND",
  GEHWEG_RAD_FREI_STRASSENBEGLEITEND = "GEHWEG_RAD_FREI_STRASSENBEGLEITEND",
  GEH_RADWEG_GETRENNT_STRASSENBEGLEITEND = "GEH_RADWEG_GETRENNT_STRASSENBEGLEITEND",
  GEH_RADWEG_GEMEINSAM_STRASSENBEGLEITEND = "GEH_RADWEG_GEMEINSAM_STRASSENBEGLEITEND",

  SONSTIGE_STRASSE_WEG = "SONSTIGE_STRASSE_WEG",

  UNBEKANNT = "UNBEKANNT",
}

// eslint-disable-next-line no-redeclare
export namespace Radverkehrsfuehrung {
  export const colors: Map<Radverkehrsfuehrung, number[]> = new Map([
    [Radverkehrsfuehrung.FUEHRUNG_AUF_FAHRBAHN_VIER_MEHRSTREIFIGE_FAHRBAHN, [165, 0, 38, 1]],
    [Radverkehrsfuehrung.FUEHRUNG_AUF_FAHRBAHN_ZWEISTREIFIGE_FAHRBAHN, [165, 0, 38, 1]],

    [Radverkehrsfuehrung.PIKTOGRAMMKETTE, [215, 48, 39, 1]],
    [Radverkehrsfuehrung.SCHUTZSTREIFEN, [215, 48, 39, 1]],
    [Radverkehrsfuehrung.RADFAHRSTREIFEN, [215, 48, 39, 1]],
    [Radverkehrsfuehrung.RADFAHRSTREIFEN_MIT_FREIGABE_BUSVERKEHR, [215, 48, 39, 1]],
    [Radverkehrsfuehrung.BUSFAHRSTREIFEN_MIT_FREIGABE_RADVERKEHR, [215, 48, 39, 1]],
    [Radverkehrsfuehrung.MEHRZWECKSTREIFEN, [215, 48, 39, 1]],

    [Radverkehrsfuehrung.FUEHRUNG_IN_T20_ZONE, [244, 109, 67, 1]],
    [Radverkehrsfuehrung.FUEHRUNG_IN_T30_ZONE, [244, 109, 67, 1]],
    [Radverkehrsfuehrung.FUEHRUNG_IN_VERKEHRSBERUHIGTER_BEREICH, [244, 109, 67, 1]],

    [Radverkehrsfuehrung.FUEHRUNG_IN_FAHRRADSTRASSE, [253, 174, 97, 1]],
    [Radverkehrsfuehrung.FUEHRUNG_IN_FAHRRADZONE, [253, 174, 97, 1]],

    [Radverkehrsfuehrung.EINBAHNSTRASSE_MIT_FREIGABE_RADVERKEHR_MEHR_WENIGER_30, [254, 224, 144, 1]],
    [Radverkehrsfuehrung.EINBAHNSTRASSE_OHNE_FREIGABE_RADVERKEHR_MEHR_ALS_30, [254, 224, 144, 1]],
    [Radverkehrsfuehrung.EINBAHNSTRASSE_OHNE_FREIGABE_RADVERKEHR_MEHR_WENIGER_30, [254, 224, 144, 1]],

    [Radverkehrsfuehrung.BEGEGNUNGSZONE, [255, 255, 191, 1]],
    [Radverkehrsfuehrung.FUEHRUNG_IN_FUSSG_ZONE_RAD_FREI, [255, 255, 191, 1]],
    [Radverkehrsfuehrung.FUEHRUNG_IN_FUSSG_ZONE_RAD_NICHT_FREI, [255, 255, 191, 1]],
    [Radverkehrsfuehrung.FUEHRUNG_IN_FUSSG_ZONE_RAD_ZEITW_FREI, [255, 255, 191, 1]],

    [Radverkehrsfuehrung.OEFFENTLICHE_STRASSE_MIT_FREIGABE_ANLIEGER, [116, 173, 209, 1]],
    [Radverkehrsfuehrung.BETRIEBSWEG_FORST, [116, 173, 209, 1]],
    [Radverkehrsfuehrung.BETRIEBSWEG_LANDWIRTSCHAFT_SELBSTSTAENDIG, [116, 173, 209, 1]],
    [Radverkehrsfuehrung.BETRIEBSWEG_LANDWIRTSCHAFT_STRASSENBEGLEITEND, [116, 173, 209, 1]],
    [Radverkehrsfuehrung.BETRIEBSWEG_WASSERWIRTSCHAFT, [116, 173, 209, 1]],
    [Radverkehrsfuehrung.SONSTIGER_BETRIEBSWEG, [116, 173, 209, 1]],

    [Radverkehrsfuehrung.SONDERWEG_RADWEG_SELBSTSTAENDIG, [69, 117, 180, 1]],
    [Radverkehrsfuehrung.GEHWEG_RAD_FREI_SELBSTSTAENDIG, [69, 117, 180, 1]],
    [Radverkehrsfuehrung.GEH_RADWEG_GETRENNT_SELBSTSTAENDIG, [69, 117, 180, 1]],
    [Radverkehrsfuehrung.GEH_RADWEG_GEMEINSAM_SELBSTSTAENDIG, [69, 117, 180, 1]],
    [Radverkehrsfuehrung.GEM_RAD_GEHWEG_MIT_GEHWEG_GEGENRICHTUNG_FREI_SELBSTSTAENDIG, [69, 117, 180, 1]],

    [Radverkehrsfuehrung.SONDERWEG_RADWEG_STRASSENBEGLEITEND, [49, 54, 149, 1]],
    [Radverkehrsfuehrung.GEM_RAD_GEHWEG_MIT_GEHWEG_GEGENRICHTUNG_FREI_STRASSENBEGLEITEND, [49, 54, 149, 1]],
    [Radverkehrsfuehrung.GEHWEG_RAD_FREI_STRASSENBEGLEITEND, [49, 54, 149, 1]],
    [Radverkehrsfuehrung.GEH_RADWEG_GETRENNT_STRASSENBEGLEITEND, [49, 54, 149, 1]],
    [Radverkehrsfuehrung.GEH_RADWEG_GEMEINSAM_STRASSENBEGLEITEND, [49, 54, 149, 1]],

    [Radverkehrsfuehrung.SONSTIGE_STRASSE_WEG, [224, 243, 248, 1]],

    [Radverkehrsfuehrung.UNBEKANNT, [52, 222, 188, 1]],
  ]);
}
