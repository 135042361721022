<div class="radnetz-menu-entry pl-m">
  <mat-checkbox
    rrpbwAccessabilityTabCircleElement
    color="primary"
    [checked]="radNetzSelected"
    (change)="radNetzVisibilityChanged.emit($event.checked)"
  >
    {{ "layerSelectionMenu.radnetzToggleMenu.radnetzEinblende" | translate }}
  </mat-checkbox>
</div>
<div class="mb-m">
  <div class="legend-item">
    <span> {{ "layerSelectionMenu.radnetzToggleMenu.radnetzAlltagUndFreizeit" | translate }} </span>
    <div class="legend-color-box orange"></div>
  </div>
  <div class="legend-item">
    <span> {{ "layerSelectionMenu.radnetzToggleMenu.radnetzAlltag" | translate }} </span>
    <div class="legend-color-box red"></div>
  </div>
  <div class="legend-item">
    <span> {{ "layerSelectionMenu.radnetzToggleMenu.radnetzFreizeit" | translate }} </span>
    <div class="legend-color-box green"></div>
  </div>
</div>
