import PointerInteraction from "ol/interaction/Pointer";
import { Feature, MapBrowserEvent } from "ol";
import VectorSource from "ol/source/Vector";
import { Geometry } from "ol/geom";

export class DragInteraction extends PointerInteraction {
  static readonly LEFT_MOUSE_BUTTON = 0;

  private _dragCoordinate: number[];
  private _dragFeature: Feature<Geometry>;
  private dragStarted: boolean = false;

  constructor(public source: VectorSource<any>) {
    super();
  }

  get dragCoordinate(): number[] {
    return this._dragCoordinate;
  }

  get dragFeature(): Feature<Geometry> {
    return this._dragFeature;
  }

  protected handleDragEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
    const deltaX = mapBrowserEvent.coordinate[0] - this._dragCoordinate[0];
    const deltaY = mapBrowserEvent.coordinate[1] - this._dragCoordinate[1];

    const geometry = this._dragFeature.getGeometry();
    geometry!.translate(deltaX, deltaY);

    this._dragCoordinate[0] = mapBrowserEvent.coordinate[0];
    this._dragCoordinate[1] = mapBrowserEvent.coordinate[1];
    this.dispatchEvent("drag");
  }

  protected handleUpEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
    if (!this.dragStarted) {
      return true;
    }

    const element = mapBrowserEvent.map.getTargetElement();
    element.style.cursor = "grab";
    this.dispatchEvent("drag:end");
    return true;
  }

  protected handleDownEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
    const feature = mapBrowserEvent.map.forEachFeatureAtPixel(mapBrowserEvent.pixel, hitFeature => {
      if (this.source.getFeatures().some(feature => feature === hitFeature)) {
        return hitFeature;
      }
      return undefined;
    });

    if (mapBrowserEvent.originalEvent.button !== DragInteraction.LEFT_MOUSE_BUTTON) {
      return false;
    }

    if (feature) {
      this._dragCoordinate = mapBrowserEvent.coordinate;
      this._dragFeature = feature as Feature<Geometry>;
      const element = mapBrowserEvent.map.getTargetElement();
      element.style.cursor = "move";
      this.dragStarted = true;
      this.dispatchEvent("drag:start");
    }

    return !!feature;
  }

  protected handleMoveEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
    const element = mapBrowserEvent.map.getTargetElement();
    const pixel = mapBrowserEvent.map.getEventPixel(mapBrowserEvent.originalEvent);
    const hitFeatures = mapBrowserEvent.map.getFeaturesAtPixel(pixel);
    if (hitFeatures.some(hitFeature => this.source.getFeatures().some(feature => feature === hitFeature))) {
      element.style.cursor = "grab";
    } else {
      element.style.cursor = "default";
    }
  }
}
