import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";

@Component({
  selector: "rrpbw-karte-icon-button",
  templateUrl: "./karte-icon-button.component.html",
  styleUrls: ["./karte-icon-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KarteIconButtonComponent implements OnDestroy {
  @Input()
  public icon: string = "";

  @Input()
  public ariaLabel: string = "";

  @Input()
  public selected: boolean = false;

  @Output()
  public clicked: EventEmitter<void> = new EventEmitter<void>();

  ngOnDestroy(): void {
    this.clicked.complete();
  }
}
