import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FahrradroutenService } from "src/app/fahrradrouten/services/fahrradrouten.service";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Subscription, Observable } from "rxjs";
import { Router } from "@angular/router";
import { FAHRRADROUTEN_PATH } from "src/app/fahrradrouten/fahrradrouten-routing.module";
import { Fahrradroute } from "src/app/fahrradrouten/entities/fahrradroute";
import { FahrradroutenAuswahlService } from "src/app/fahrradrouten/services/fahrradrouten-auswahl.service";
import { map } from "rxjs/operators";

@Component({
  selector: "rrpbw-fahrradroute-auswahl",
  templateUrl: "./fahrradroute-auswahl.component.html",
  styleUrls: ["./fahrradroute-auswahl.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrradrouteAuswahlComponent implements OnDestroy, OnInit {
  routen: Fahrradroute[] = [];
  inputFormControl: FormControl;
  loading = false;

  selectedFahrradrouten$: Observable<Fahrradroute[]>;

  filteredOptions: Fahrradroute[] = [];
  routeDisplayName = (route: Fahrradroute): string => route.name;

  @Input()
  isSmartphoneLayout = false;

  subscriptions: Subscription[] = [];

  constructor(
    private service: FahrradroutenService,
    private changeDetectorRef: ChangeDetectorRef,
    private fahrradroutenAuswahlService: FahrradroutenAuswahlService,
    private router: Router
  ) {
    this.loading = true;
    this.inputFormControl = new FormControl("");
  }

  private get unselectedRouten(): Fahrradroute[] {
    return this.routen.filter(o => !this.fahrradroutenAuswahlService.selectedFahrradroutenIds.includes(o.id));
  }

  private filter(value: string | null | Fahrradroute): Fahrradroute[] {
    if (value) {
      if ((value as Fahrradroute).name) {
        return this.unselectedRouten;
      }
      return this.unselectedRouten.filter(option =>
        option.name.toLowerCase().includes((value as string).toLowerCase())
      );
    }
    return this.unselectedRouten;
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.fahrradroutenAuswahlService.selectFahrradroute(value.id);
    this.inputFormControl.reset("");
  }

  onRouteRemoved(fahrradroute: Fahrradroute): void {
    this.fahrradroutenAuswahlService.deselectFahrradroute(fahrradroute.id);
    this.filteredOptions = this.filter(this.inputFormControl.value);
    this.fahrradroutenAuswahlService.highlightRoute(undefined);
  }

  // Das Standardverhalten von Autocomplete bei Focus wird hier überschrieben, weil sonst beim intialen Focus und
  // bei Auswahl eines Wertes und erneutem Klick auf das Formularfeld das Options-Panel nicht mehr angezeigt wird
  onInputFocus(event: Event, trigger: MatAutocompleteTrigger): void {
    this.inputFormControl.reset("");
    event.stopPropagation();
    trigger.openPanel();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.service.getAllRoutes().subscribe({
        next: routen => {
          this.routen = routen;
          this.selectedFahrradrouten$ = this.fahrradroutenAuswahlService.selectedFahrradroutenIds$.pipe(
            map(ids => this.routen.filter(r => ids?.includes(r.id)))
          );
          this.changeDetectorRef.detectChanges();
        },
        complete: () => (this.loading = false),
      })
    );

    this.subscriptions.push(
      this.inputFormControl.valueChanges.subscribe(v => {
        this.filteredOptions = this.filter(v);
        this.changeDetectorRef.markForCheck();
      })
    );

    this.filteredOptions = this.filter("");
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onHover(route: Fahrradroute): void {
    this.fahrradroutenAuswahlService.highlightRoute(route.id);
  }

  onUnHover(): void {
    this.fahrradroutenAuswahlService.highlightRoute(undefined);
  }

  onZoomToFahrradroute(route: Fahrradroute, event: Event): void {
    event.stopPropagation();
    this.fahrradroutenAuswahlService.zoomToRoute(route.id);
  }

  onClickRoute(route: Fahrradroute): void {
    this.router.navigate([FAHRRADROUTEN_PATH, route.id]);
  }
}
