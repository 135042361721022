import { Stringer } from "./stringer";
import { toLonLat } from "ol/proj";
import { Coordinate, distance } from "ol/coordinate";

export class GeoLocation implements Stringer {
  private readonly _coordinates: [number, number];

  static fromCoordinates(coordinates: [number, number] | Coordinate): GeoLocation {
    return new GeoLocation(coordinates[0], coordinates[1]);
  }

  constructor(easting: number, northing: number) {
    this._coordinates = [easting, northing];
  }

  isEqualTo(geoLocation: GeoLocation): boolean {
    return this.coordinates[0] === geoLocation.coordinates[0] && this.coordinates[1] === geoLocation.coordinates[1];
  }

  distanceTo(geoLocation: GeoLocation): number {
    return distance(this.coordinates, geoLocation.coordinates);
  }

  get coordinates(): [number, number] {
    return this._coordinates;
  }

  get string(): string {
    const longLat = toLonLat(this.coordinates);
    return `[${longLat[0].toFixed(5)}, ${longLat[1].toFixed(5)}]`;
  }

  get sortingKey(): string {
    return this.string;
  }
}
