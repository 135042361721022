import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { InfrastrukturRepository } from "src/app/karte/repositories/infrastruktur.repository";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";
import { Geometry, LineString, MultiLineString, MultiPoint, Point } from "ol/geom";
import { WKT } from "ol/format";
import { LAT_LON_PROJECTION, MAP_PROJECTION, RADVIS_DATA_PROJECTION } from "src/app/constants";
import { Environment } from "src/app/common/utils/environment";
import { Subscription, zip } from "rxjs";
import { LayerQueryService } from "src/app/karte/services/layer-query.service";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Feature } from "ol";
import { Layer } from "ol/layer";
import { Source } from "ol/source";
import { Icon, Stroke, Style } from "ol/style";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import { PoiSelectionService } from "src/app/karte/services/poi-selection.service";
import { ColorService } from "src/app/common/services/color.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Extent } from "ol/extent";
import { featureCollection, FeatureCollection } from "@turf/turf";
import { transformExtent } from "ol/proj";
import { Route } from "src/app/common/entities/route";
import { DetailProperties } from "src/app/karte/entities/detail-properties";
import { TranslateService } from "@ngx-translate/core";
import { Pixel } from "ol/pixel";
import { AbstractFeatureLayerComponent } from "src/app/karte/smart-components/poi-layer/abstract-feature-layer.component";
import { StyleFunction } from "ol/style/Style";
import { FeatureLike } from "ol/Feature";
import GeometryType from "src/app/common/utils/geometry-type";

@Component({
  selector: "rrpbw-lastenrad-barrieren-layer",
  templateUrl: "./lastenrad-barrieren-layer.component.html",
  styleUrls: ["./lastenrad-barrieren-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastenradBarrierenLayerComponent
  extends AbstractFeatureLayerComponent
  implements OnChanges, OnInit, OnDestroy, LayerQueryService
{
  static readonly GEOSERVER_URL_TEMPLATE: string =
    "/api/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames={workspace}:{layer}&outputFormat=application/json";

  readonly barrieren: Infrastruktur;
  readonly featureLayer: VectorLayer<VectorSource<Geometry>>;

  readonly subscriptions: Subscription[] = [];

  selectedFeatures: Feature<Geometry>[] = [];

  @Input()
  route: Route;

  constructor(
    infrastrukturRepository: InfrastrukturRepository,
    public karteBaseService: KarteBaseService,
    public poiSelectionService: PoiSelectionService,
    public colorService: ColorService,
    public httpClient: HttpClient,
    public translateService: TranslateService
  ) {
    super();
    this.barrieren = infrastrukturRepository.barrierenInfrastruktur;
    this.featureLayer = new VectorLayer({ style: new Style(), zIndex: AbstractFeatureLayerComponent.ZINDEX });
    this.featureLayer.setMinZoom(this.barrieren.minZoom);
    this.featureLayer.setStyle(this.getStyleFunction());
  }

  ngOnInit(): void {
    this.karteBaseService.addLayer(this.featureLayer);
    this.subscriptions.push(this.poiSelectionService.$selectPoi.subscribe(feature => this.selectFeature(feature)));
  }

  ngOnChanges(): void {
    const headers = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
    this.featureLayer.setSource(
      new VectorSource({
        format: new GeoJSON(),
        loader: (extent, res, projection) => {
          const urlSearchParams = new URLSearchParams();
          urlSearchParams.append(
            "CQL_FILTER",
            this.getCqlFilter(transformExtent(extent, projection, RADVIS_DATA_PROJECTION))
          );
          zip(
            this.barrieren.layerNames!.map(layerName =>
              this.httpClient.post<FeatureCollection>(
                LastenradBarrierenLayerComponent.GEOSERVER_URL_TEMPLATE.replace(
                  "{workspace}",
                  this.barrieren.layerWorkspace!
                ).replace("{layer}", layerName),
                urlSearchParams,
                { headers }
              )
            )
          )
            .pipe(map(x => featureCollection(x.map(y => y.features).flat())))
            .subscribe({
              next: data => {
                const features = new GeoJSON().readFeatures(data, {
                  dataProjection: RADVIS_DATA_PROJECTION,
                  featureProjection: MAP_PROJECTION,
                });
                features.forEach(f =>
                  f.set(
                    DetailProperties.NAME.key,
                    this.translateService.instant(
                      "detailProperties.values." + f.get(DetailProperties.BARRIERENFORM.key)
                    )
                  )
                );
                this.source.clear(true);
                this.source.addFeatures(features);
              },
              error: error => {
                this.source.removeLoadedExtent(extent);
                console.error(error);
              },
            });
        },
        strategy: bboxStrategy,
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.karteBaseService.removeLayer(this.featureLayer);
  }

  get source(): VectorSource<Geometry> {
    return this.featureLayer.getSource()!;
  }

  getCqlFilter(extent: Extent): string {
    const lineString = new LineString(this.route.coordinates.map(coor => [coor[0], coor[1]]));
    lineString.transform(LAT_LON_PROJECTION, RADVIS_DATA_PROJECTION);
    return `BBOX(the_geom,${extent.join(",")}) AND isWithinDistance(the_geom,${new WKT().writeGeometry(
      lineString
    )},${Environment.barriereToRouteDistance()}) = true`;
  }

  getInfrastruktur(): Infrastruktur {
    return this.barrieren;
  }

  isLayer(l: Layer<Source>): boolean {
    return l === this.featureLayer;
  }

  protected getStyleFunction(scale: number = 1, saturation: number = 1, zIndex: number = 0): StyleFunction {
    const lineColor = this.colorService.toPaleBrightHex("#ce0071", saturation);

    return (feature: FeatureLike): Style[] => {
      const styles = [
        // linestyle
        new Style({
          zIndex: zIndex,
          stroke: new Stroke({
            color: lineColor,
            width: 5 * scale,
          }),
        }),
      ];

      const barriereIcon = new Icon({
        src: "assets/poi-icons/icon-barriere.svg",
        scale: scale,
        opacity: saturation,
      });
      switch (feature.getGeometry()?.getType()) {
        case GeometryType.MULTI_LINE_STRING:
          (feature.getGeometry() as MultiLineString).getLineStrings().forEach(lineString => {
            const iconPosition = lineString.getCoordinateAt(0.5);
            styles.push(
              new Style({
                zIndex: zIndex,
                geometry: new Point(iconPosition),
                image: barriereIcon,
              })
            );
          });
          break;
        case GeometryType.MULTI_POINT:
          (feature.getGeometry() as MultiPoint).getPoints().forEach(point => {
            styles.push(
              new Style({
                zIndex: zIndex,
                geometry: point,
                image: barriereIcon,
              })
            );
          });
          break;
      }
      return styles;
    };
  }

  getTooltipDisplacement(): Pixel {
    return [0, 22];
  }
}
