import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { MeldeplattformComponent } from "src/app/meldeplattform/smart-components/meldeplattform/meldeplattform.component";

const routes: Routes = [{ path: "meldeplattform", component: MeldeplattformComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeldeplattformRoutingModule {}
