import { Stringer } from "../../common/entities/stringer";
import { GeoLocation } from "../../common/entities/geo-location";

export class KarteContextMenuEntry implements Stringer {
  constructor(public string: string, public callbackFn: (geoLocation: GeoLocation) => void, public symbol?: string) {}

  get sortingKey(): string {
    return this.string;
  }
}
