import { KarteBaseService } from "src/app/common/services/karte-base.service";
import { Pixel } from "ol/pixel";

export interface TooltipEvent {
  pixel: Pixel;
  text: string;
}

export abstract class KarteService extends KarteBaseService {
  abstract zoomIn(): void;

  abstract zoomOut(): void;

  abstract locateMe(): void;

  abstract einnorden(): void;
}
