import { Pipe, PipeTransform } from "@angular/core";
import { formatMeter } from "../utils/distance-formatter";

@Pipe({
  name: "distance",
})
export class DistancePipe implements PipeTransform {
  transform(value?: number): string {
    if (value == null) {
      return "-";
    } else {
      return formatMeter(value);
    }
  }
}
