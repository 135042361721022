import { register } from "ol/proj/proj4";
// @ts-ignore
import proj4 from "proj4";

// Definiere die Projektion von EPSG:25832 und registriere sie bei OpenLayers, damit bestimmte Transformationen funktioniert
proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
register(proj4);

export const MAP_PROJECTION: string = "EPSG:3857";
export const LAT_LON_PROJECTION: string = "EPSG:4326";
export const RADVIS_DATA_PROJECTION: string = "EPSG:25832"; // UTM32 - KRS der RadVIS-Daten

export const INPUT_DEBOUNCE_TIME: number = 300;

export const SNACK_BAR_DURATION: number = 2500;
