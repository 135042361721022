import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Navigation, PRIMARY_OUTLET, Router } from "@angular/router";
import { FahrradroutenComponent } from "src/app/fahrradrouten/smart-components/fahrradrouten/fahrradrouten.component";
import GeometryType from "src/app/common/utils/geometry-type";
import { LineString, MultiLineString } from "ol/geom";
import { LAT_LON_PROJECTION, MAP_PROJECTION } from "src/app/constants";
import { MapHoehenprofilService } from "src/app/karte/services/map-hoehenprofil.service";
import { LayoutService, ScreenLayout } from "src/app/common/services/layout.service";
import { map, Observable } from "rxjs";
import GPX from "ol/format/GPX";
import KML from "ol/format/KML";
import { DownloadService } from "../../../common/services/download.service";
import { Feature } from "ol";
import { RouteMitWegpunkten } from "../../../common/entities/route-mit-wegpunkten";
import { HoveredHoehenprofilLocation } from "../../../common/entities/hoehenprofil-hover-location";
import { Route } from "../../../common/entities/route";
import { Fahrradroute } from "src/app/fahrradrouten/entities/fahrradroute";
import { Location } from "@angular/common";
import { FAHRRADROUTEN_PATH } from "src/app/fahrradrouten/fahrradrouten-routing.module";

@Component({
  selector: "rrpbw-fahrradroute-deatil",
  templateUrl: "./fahrradroute-detail.component.html",
  styleUrls: ["./fahrradroute-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrradrouteDetailComponent implements OnInit {
  ROUTE_STYLE_THIN = FahrradroutenComponent.ROUTE_STYLE_THIN;

  fahrradroute: Fahrradroute;
  routeMitWegpunkten: RouteMitWegpunkten;
  hoveredHoehenprofilLocation: HoveredHoehenprofilLocation | undefined;
  isSmartphoneLayout$: Observable<boolean>;

  readonly gpxFormatter: GPX;
  readonly kmlFormatter: KML;
  @ViewChild("hoehenprofilLayer")
  private mapHoehenprofilService: MapHoehenprofilService;

  private prevNavigation: Navigation | null | undefined;

  constructor(
    layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private downloadService: DownloadService,
    private location: Location,
    private router: Router
  ) {
    this.isSmartphoneLayout$ = layoutService.screenLayout.pipe(map(layout => layout === ScreenLayout.Smartphone));
    this.prevNavigation = this.router.getCurrentNavigation()?.previousNavigation;
    this.gpxFormatter = new GPX();
    this.kmlFormatter = new KML();
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(data => {
      this.fahrradroute = data.fahrradroute;

      let coordinates: number[][] = [];
      let geometry = this.fahrradroute.geometry;
      if (geometry) {
        // Die Koordinaten müssen für eine korrekte Längenberechnung in Lat-Lon-Werte umgewandelt werden.
        geometry = geometry.clone().transform(MAP_PROJECTION, LAT_LON_PROJECTION);

        switch (geometry?.getType()) {
          case GeometryType.LINE_STRING:
            coordinates = (geometry as LineString).getCoordinates();
            break;
          case GeometryType.MULTI_LINE_STRING:
            coordinates = (geometry as MultiLineString).getCoordinates()[0];
            break;
        }
      }

      const route = new Route(coordinates);
      this.routeMitWegpunkten = {
        route: route,
      } as RouteMitWegpunkten;
      this.updateRouteOnHoehenprofilLayer(this.routeMitWegpunkten);

      this.changeDetectorRef.detectChanges();
    });

    this.updateRouteOnHoehenprofilLayer(this.routeMitWegpunkten);
  }

  onHoehenprofilHovered(hoverLocation: HoveredHoehenprofilLocation | undefined): void {
    this.hoveredHoehenprofilLocation = hoverLocation;
  }

  onHoehenprofilElementSelected(index: number | undefined): void {
    this.mapHoehenprofilService.highlightPointAtIndex(index);
  }

  private updateRouteOnHoehenprofilLayer(routeMitWegpunkten: RouteMitWegpunkten): void {
    if (this.mapHoehenprofilService && routeMitWegpunkten && routeMitWegpunkten.route.hasElevationData) {
      this.mapHoehenprofilService.setRoute(routeMitWegpunkten.route);
    }
  }

  onSaveAsGpx(): void {
    const encodedGpxString = encodeURIComponent(
      this.gpxFormatter.writeFeatures([
        new Feature(this.fahrradroute?.geometry?.clone().transform(MAP_PROJECTION, LAT_LON_PROJECTION)),
      ])
    );
    this.downloadService.download(encodedGpxString, "radroutenplaner-bw-export.gpx", "text/gpx-xml");
  }

  onSaveAsKml(): void {
    const encodedKmlString = encodeURIComponent(
      this.kmlFormatter.writeFeatures([
        new Feature(this.fahrradroute?.geometry?.clone().transform(MAP_PROJECTION, LAT_LON_PROJECTION)),
      ])
    );
    this.downloadService.download(
      encodedKmlString,
      "radroutenplaner-bw-export.kml",
      "application/vnd.google-earth.kml+xml"
    );
  }

  onNavigateBack(): void {
    if (this.navigationFromApp()) {
      this.location.back();
    } else {
      this.router.navigate([FAHRRADROUTEN_PATH]);
    }
  }

  navigationFromApp(): boolean {
    return !!this.prevNavigation;
  }

  navigationFromRoutenplaner(): boolean {
    return this.prevNavigation?.finalUrl?.root.children[PRIMARY_OUTLET].segments[0].path === "radroutenplaner";
  }
}
