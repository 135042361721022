import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FahrradroutenComponent } from "src/app/fahrradrouten/smart-components/fahrradrouten/fahrradrouten.component";
import { RRPBWCommonModule } from "../common/common.module";
import { FahrradroutenRoutingModule } from "src/app/fahrradrouten/fahrradrouten-routing.module";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { SimpleFeatureLayerComponent } from "src/app/fahrradrouten/smart-components/simple-feature-layer/simple-feature-layer.component";
import { KarteModule } from "src/app/karte/karte.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FahrradrouteDetailComponent } from "src/app/fahrradrouten/smart-components/fahrradroute-detail/fahrradroute-detail.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { FahrradrouteDetailItemComponent } from "./dumb-components/fahrradroute-detail-item/fahrradroute-detail-item.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FahrradroutenFilterComponent } from "./smart-components/fahrradrouten-filter/fahrradrouten-filter.component";
import { MatPaginatorIntl, MatPaginatorModule } from "@angular/material/paginator";
import { MatPaginatorIntlLocalizationService } from "src/app/common/services/mat-paginator-intl-localization.service";
import { FahrradroutenFeatureLayerComponent } from "src/app/fahrradrouten/smart-components/fahrradrouten-feature-layer/fahrradrouten-feature-layer.component";

@NgModule({
  declarations: [
    FahrradroutenComponent,
    SimpleFeatureLayerComponent,
    FahrradrouteDetailComponent,
    FahrradrouteDetailItemComponent,
    FahrradroutenFilterComponent,
    FahrradroutenFeatureLayerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FahrradroutenRoutingModule,
    RRPBWCommonModule,
    MatCardModule,
    MatButtonModule,
    KarteModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatPaginatorModule,
  ],
  exports: [FahrradroutenFeatureLayerComponent],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlLocalizationService }],
})
export class FahrradroutenModule {}
