import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoadingBarService {
  private $isLoading: Subject<boolean> = new Subject();
  private loadingRequests: number = 0;

  isLoading(): Observable<boolean> {
    return this.$isLoading.asObservable();
  }

  push(): Subject<void> {
    const pushSubj = new Subject<void>();
    ++this.loadingRequests;
    if (this.loadingRequests === 1) {
      this.$isLoading.next(true);
    }

    pushSubj.pipe(take(1)).subscribe(() => this.pop());
    return pushSubj;
  }

  private pop(): void {
    if (this.loadingRequests > 0) {
      --this.loadingRequests;
      if (this.loadingRequests === 0) {
        this.$isLoading.next(false);
      }
    }
  }
}
