import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { LoadingBarService } from "../../services/loading-bar.service";

@Component({
  selector: "rrpbw-loading-bar",
  templateUrl: "./loading-bar.component.html",
  styleUrls: ["./loading-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarComponent implements OnInit {
  displayBar: boolean = false;

  constructor(public loadingBarService: LoadingBarService, public changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.loadingBarService.isLoading().subscribe(isLoading => {
      this.displayBar = isLoading;
      this.changeDetectorRef.detectChanges();
    });
  }
}
