import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { Route } from "src/app/common/entities/route";
import { Radtyp } from "../../../karte/entities/radtyp";
import { RoutingPraeferenz } from "../../../karte/entities/routing-praeferenz";
import { Fahrverhalten } from "src/app/common/entities/fahrverhalten";

@Component({
  selector: "rrpbw-options-menu",
  templateUrl: "./options-menu.component.html",
  styleUrls: ["./options-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsMenuComponent {
  RoutingPraeferenz = RoutingPraeferenz;

  @Input() matDrawer: MatDrawer;
  @Input() route?: Route;
  @Input() currentRadtyp: Radtyp;
  @Input() currentFahrverhalten: Fahrverhalten;
  @Input() currentRoutingPraeferenz: RoutingPraeferenz;
  @Input() currentSteigungVermeiden: boolean;

  @Output() radtypChanged: EventEmitter<Radtyp> = new EventEmitter();
  @Output() fahrverhaltenChanged: EventEmitter<Fahrverhalten> = new EventEmitter();
  @Output() routingPraeferenzChanged: EventEmitter<RoutingPraeferenz> = new EventEmitter();
  @Output() steigungVermeidenChanged: EventEmitter<boolean> = new EventEmitter();
}
