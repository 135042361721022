import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class MatPaginatorIntlLocalizationService extends MatPaginatorIntl {
  override itemsPerPageLabel = "";
  override nextPageLabel = "";
  override previousPageLabel = "";
  override firstPageLabel = "";
  override lastPageLabel = "";
  override getRangeLabel = (page: number, pageSize: number, length: number): string =>
    `${page * pageSize + 1} - ${Math.min((page + 1) * pageSize, length)} ${this.translateService.instant(
      "paginator.of"
    )} ${length}`;

  constructor(private translateService: TranslateService) {
    super();
    translateService
      .get("paginator.itemsPerPageLabel")
      .subscribe(translation => (this.itemsPerPageLabel = translation));
    translateService.get("paginator.nextPageLabel").subscribe(translation => (this.nextPageLabel = translation));
    translateService
      .get("paginator.previousPageLabel")
      .subscribe(translation => (this.previousPageLabel = translation));
    translateService.get("paginator.firstPageLabel").subscribe(translation => (this.firstPageLabel = translation));
    translateService.get("paginator.lastPageLabel").subscribe(translation => (this.lastPageLabel = translation));
  }
}
