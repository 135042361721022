import { Stringer } from "../../common/entities/stringer";
import { GeoLocation } from "../../common/entities/geo-location";
import { fromLonLat, toLonLat } from "ol/proj";

export class Adresse implements Stringer {
  constructor(public geoLocation: GeoLocation, public anschrift?: string) {}

  get string(): string {
    return this.anschrift ?? this.geoLocation.string;
  }

  get sortingKey(): string {
    return this.anschrift ?? "";
  }

  serialize(): [number, number, string?] {
    const lonLat = toLonLat(this.geoLocation.coordinates);
    const lon = Number.parseFloat(lonLat[0].toFixed(4));
    const lat = Number.parseFloat(lonLat[1].toFixed(4));
    if (this.anschrift) {
      return [lon, lat, this.anschrift];
    }
    return [lon, lat];
  }

  deserialize([lon, lat, anschrift]: [number, number, string?]): Adresse {
    const lonLat = fromLonLat([lon, lat]);
    if (anschrift) {
      return new Adresse(new GeoLocation(lonLat[0], lonLat[1]), anschrift);
    }
    return new Adresse(new GeoLocation(lonLat[0], lonLat[1]));
  }
}
