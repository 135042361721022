<mat-form-field appearance="fill" [formGroup]="formGroup">
  <span *ngIf="labelPrefix" class="label-prefix" matPrefix>{{ labelPrefix | translate }}</span>
  <mat-label>{{ label | translate }}</mat-label>
  <input
    type="text"
    matInput
    placeholder="{{ placeholder | translate }}"
    formControlName="search"
    [matAutocomplete]="auto"
  />
  <button
    *ngIf="showDeleteButton"
    mat-button
    matSuffix
    (click)="onDeleteButtonClicked()"
    [attr.aria-label]="'adresseSuche.delete' | translate"
    class="focus"
  >
    <mat-icon class="contrast">delete</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option.value)">
    <mat-option *ngFor="let item of items" [value]="item">
      <span class="option-label">{{ item.string }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
