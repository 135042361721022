import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { MapRouteService } from "../../../karte/services/map-route.service";
import { MapMarkerService } from "../../../karte/services/map-marker.service";
import { KartePreviewService } from "../../services/karte-preview.service";
import { MapMarker } from "../../../karte/entities/map-marker";
import { Route } from "src/app/common/entities/route";

@Component({
  selector: "rrpbw-karte-preview",
  templateUrl: "./karte-preview.component.html",
  styleUrls: ["./karte-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KartePreviewComponent implements KartePreviewService {
  @Output()
  clicked: EventEmitter<void> = new EventEmitter();

  @ViewChild("routeLayer", { static: true })
  mapRouteService: MapRouteService;

  @ViewChild("markerLayer", { static: true })
  mapMarkerService: MapMarkerService;

  updateMarker(markers: (MapMarker | undefined)[]): void {
    this.mapMarkerService.clearLayer();
    if (markers.length > 0 && markers[0]) {
      this.mapMarkerService.createPinMarker(markers[0].geoLocation, "A");
    }

    for (let i = 1; i < markers.length - 1; ++i) {
      if (markers[i]) {
        this.mapMarkerService.createPointMarker(markers[i]!.geoLocation, i.toString());
      }
    }

    if (markers.length > 1 && markers[markers.length - 1]) {
      this.mapMarkerService.createPinMarker(markers[markers.length - 1]!.geoLocation, "B");
    }
  }

  updateRoute(route: Route): void {
    this.mapRouteService.updateRoute(route);
    this.mapRouteService.zoomToRoute();
  }

  clearRoute(): void {
    this.mapRouteService.clearLayer();
  }

  onClick(): void {
    this.clicked.emit();
  }
}
