import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { FahrradroutenComponent } from "src/app/fahrradrouten/smart-components/fahrradrouten/fahrradrouten.component";
import { FahrradrouteDetailComponent } from "src/app/fahrradrouten/smart-components/fahrradroute-detail/fahrradroute-detail.component";
import { FahrradroutenService } from "src/app/fahrradrouten/services/fahrradrouten.service";

export const FAHRRADROUTEN_PATH = "fahrradrouten";

const routes: Routes = [
  { path: FAHRRADROUTEN_PATH, component: FahrradroutenComponent },
  {
    path: `${FAHRRADROUTEN_PATH}/:id`,
    component: FahrradrouteDetailComponent,
    resolve: {
      fahrradroute: FahrradroutenService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FahrradroutenRoutingModule {}
