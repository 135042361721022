import { Pipe, PipeTransform } from "@angular/core";
import { millisToTimeString } from "../utils/unit-converter";

@Pipe({
  name: "duration",
})
export class DurationPipe implements PipeTransform {
  transform(value: number | undefined): string {
    return value != null ? millisToTimeString(value) : "-";
  }
}
