import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { MobileKarteTabMenuService } from "../../services/mobile-karte-tab-menu.service";
import { RouteMitWegpunkten } from "src/app/common/entities/route-mit-wegpunkten";
import { SelectedInfrastrukturFeature } from "src/app/karte/entities/selected-infrastruktur-feature";
import { Environment } from "src/app/common/utils/environment";

export class SelectableTab {
  // [index ohne route, index mit route]
  public static readonly ROUTENPLANUNG = [0, 0];
  public static readonly ROUTE_DETAILS = [undefined, 0];
  public static readonly HOEHENPROFIL = [undefined, 1];
  public static readonly POI = [1, 2];
}

@Component({
  selector: "rrpbw-mobile-karte-tab-menu",
  templateUrl: "./mobile-karte-tab-menu.component.html",
  styleUrls: ["./mobile-karte-tab-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileKarteTabMenuComponent implements MobileKarteTabMenuService {
  SelectableTab = SelectableTab;

  @Input()
  routeMitWegpunkten?: RouteMitWegpunkten;

  private _selectedPoi?: SelectedInfrastrukturFeature | undefined;
  get selectedPoi(): SelectedInfrastrukturFeature | undefined {
    return this._selectedPoi;
  }

  @Input()
  set selectedPoi(value: SelectedInfrastrukturFeature | undefined) {
    if (value) {
      this.selectedTabIndex = this.getTabIndex(SelectableTab.POI);
    }
    this._selectedPoi = value;
    this.changeDetectorRef.detectChanges();
  }

  @Output()
  routenplanungBearbeiten: EventEmitter<void> = new EventEmitter();

  @Output()
  hoehenprofilPointMarked: EventEmitter<number | undefined> = new EventEmitter();

  selectedTabIndex: number | undefined = this.getTabIndex(SelectableTab.ROUTENPLANUNG);
  fahrradroutenAnzeigen = Environment.fahrradroutenAnzeigen();

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  open(): void {
    this.selectedTabIndex = this.getTabIndex(SelectableTab.ROUTE_DETAILS);
    this.changeDetectorRef.detectChanges();
  }

  onHoehenprofilPointMarked(index: number | undefined): void {
    this.hoehenprofilPointMarked.next(index);
  }

  getTabIndex(tab: (number | undefined)[]): number | undefined {
    return tab[this.routeMitWegpunkten ? 1 : 0];
  }
}
