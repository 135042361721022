import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Radtyp } from "src/app/karte/entities/radtyp";
import { Environment } from "src/app/common/utils/environment";

@Component({
  selector: "rrpbw-radtyp-auswahl",
  templateUrl: "./radtyp-auswahl.component.html",
  styleUrls: ["./radtyp-auswahl.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadtypAuswahlComponent {
  Radtyp = Radtyp;

  @Input()
  selectedRadtyp: Radtyp = Radtyp.FAHRRAD;

  @Output()
  radtypChanged: EventEmitter<Radtyp> = new EventEmitter();

  onRadtypChanged(radtyp: Radtyp): void {
    if (this.selectedRadtyp === radtyp) {
      return;
    }

    this.selectedRadtyp = radtyp;
    this.radtypChanged.emit(radtyp);
  }

  get lastenradAnzeigen(): boolean {
    return Environment.lastenradAnzeigen();
  }
}
