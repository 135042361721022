import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import Map from "ol/Map";
import { View } from "ol";
import { MAP_PROJECTION } from "../../../constants";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { Hintergrundebene } from "../../entities/hintergrundebene";

@Component({
  selector: "rrpbw-hintergrundebene-preview",
  templateUrl: "./hintergrundebene-preview.component.html",
  styleUrls: ["./hintergrundebene-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintergrundebenePreviewComponent implements OnInit, AfterViewInit {
  static readonly INITIAL_VIEW_CENTER: number[] = fromLonLat([9.02487, 48.5262]);
  static readonly INITIAL_VIEW_ZOOM: number = 17;

  // Um sicherzustellen, dass jede Map-Instanz eine eigene ID hat, nehmen wir hier einen counter, der hochgezählt wird.
  static MAP_COUNTER: number = 0;

  @Input()
  hintergrundebene: Hintergrundebene;

  @Input()
  isSelected: boolean;

  private map: Map;
  public previewMapId;

  constructor() {
    this.previewMapId = "preview-map-" + HintergrundebenePreviewComponent.MAP_COUNTER++;
    this.map = new Map({
      view: new View({
        projection: MAP_PROJECTION,
        center: HintergrundebenePreviewComponent.INITIAL_VIEW_CENTER,
        zoom: HintergrundebenePreviewComponent.INITIAL_VIEW_ZOOM,
      }),
      layers: [],
      controls: [],
      interactions: [],
    });
  }

  ngOnInit(): void {
    this.map.setLayers([
      new TileLayer({
        source: new XYZ({
          url: this.hintergrundebene.url,
          attributions: this.hintergrundebene.attribution,
        }),
      }),
    ]);
  }

  ngAfterViewInit(): void {
    this.map.setTarget(this.previewMapId);
  }
}
