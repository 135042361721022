import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { KarteService } from "src/app/karte/services/karte.service";
import { Subscription } from "rxjs";

@Component({
  selector: "rrpbw-poi-tooltip",
  templateUrl: "./poi-tooltip.component.html",
  styleUrls: ["./poi-tooltip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoiTooltipComponent implements OnDestroy {
  subscriptions: Subscription[] = [];

  active: boolean = false;
  xCoordinate: number;
  yCoordinate: number;
  text: string;

  constructor(private karteService: KarteService, private changeDetectorRef: ChangeDetectorRef) {
    this.subscriptions.push(
      this.karteService.poiHover().subscribe(event => {
        if (event === undefined) {
          this.active = false;
          changeDetectorRef.markForCheck();
          return;
        }
        this.xCoordinate = event.pixel[0];
        this.yCoordinate = event.pixel[1];
        this.text = event.text;
        this.active = true;
        changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
