<div class="sidenav-widget-header">
  <h1 class="sidenav-widget-title bold">{{ "hoehenprofilPreview.title" | translate }}</h1>
  <button
    mat-button
    class="button-highlight label-button-all-caps button-small-padding sidenav-widget-button focus"
    (click)="onClick()"
  >
    {{ "hoehenprofilPreview.view" | translate }}
  </button>
</div>
<div class="chart-envelope focus" tabindex="0">
  <div class="chart-container">
    <canvas
      #chartCanvas
      [style.display]="!!routeMitWegpunkten ? 'block' : 'none'"
      (click)="onClick()"
      [attr.aria-label]="'hoehenprofil.preview.ariaTitle' | translate"
    ></canvas>
  </div>
</div>
