import { Injectable } from "@angular/core";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";
import { DetailProperties } from "src/app/karte/entities/detail-properties";
import { Environment } from "src/app/common/utils/environment";
import { Feature } from "ol";
import { Geometry } from "ol/geom";

@Injectable({
  providedIn: "root",
})
export class InfrastrukturRepository {
  private static readonly detailPropertiesRadVISNetz = new DetailProperties([
    DetailProperties.WEGNIVEAU,
    DetailProperties.BELEUCHTUNG,
    DetailProperties.UMFELD,
    DetailProperties.STRASSENQUERSCHNITT,
    DetailProperties.GEMEINDE_NAME,
    DetailProperties.LANDKREIS_NAME,
    DetailProperties.STREET_NAME,
    DetailProperties.STREET_NUMBER,
    DetailProperties.NETZKLASSE,
    DetailProperties.BELAGART,
    DetailProperties.OBERFLAECHE_BESCHAFFENHEIT,
    DetailProperties.BORDSTEIN,
    DetailProperties.RADVERKEHR_FUEHRUNG,
    DetailProperties.BENUTZUNGSPFLICHT,
    DetailProperties.KFZ_PARKEN_FORM,
    DetailProperties.KFZ_PARKEN_TYP,
    DetailProperties.BREITE,
  ]);

  private static readonly detailPropertiesBis2 = new DetailProperties([
    DetailProperties.ANZ_TAGE,
    DetailProperties.ARBEITSSTELLEN_NUMMER,
    DetailProperties.ART_DER_ARBEITSSTELLE,
    DetailProperties.ART_DER_MASSNAHME,
    DetailProperties.AUSFUEHRUNGS_ZEITEN,
    DetailProperties.BEZEICHNUNG_OEFFENTLICH,
    DetailProperties.BIS_DATUM,
    DetailProperties.BIS_UHRZEIT,
    DetailProperties.DOKUMENT_1,
    DetailProperties.DOKUMENT_2,
    DetailProperties.DOKUMENT_3,
    DetailProperties.LAENGE,
    DetailProperties.LANDKREIS,
    DetailProperties.NACHTBAUSTELLE,
    DetailProperties.REGIERUNGSBEZIRK,
    DetailProperties.STATUS,
    DetailProperties.STRASSE,
    DetailProperties.URL,
    DetailProperties.VON_DATUM,
    DetailProperties.VON_UHRZEIT,
    DetailProperties.KONTAKT_EMAIL,
    DetailProperties.FAHRSTREIFEN_NUTZUNG,
    DetailProperties.FERNZIEL,
    DetailProperties.LAGEBESCHREIBUNG,
    DetailProperties.NACHBKM,
    DetailProperties.RSA_REGELPLAN,
    DetailProperties.SPERRUNGS_TYP,
    DetailProperties.VEKEHRLICHE_AUSWIRKUNG,
    DetailProperties.VEROEFFENTLICHUNGS_DATUM,
    DetailProperties.VONBKM,
    DetailProperties.WEITERE_HINWEISE_VERKEHRSFUEHRUNG,
    DetailProperties.MAX_BREITE,
    DetailProperties.MAX_GESCHWINDIGKEIT,
    DetailProperties.MAX_GEWICHT,
    DetailProperties.MAX_HOEHE,
    DetailProperties.ARBEITSSTELLEN_PHASEN_UMLEITUNG_ID,
    DetailProperties.BESCHREIBUNG,
    DetailProperties.MEHRWEG,
    DetailProperties.MEHRZEIT,
  ]);

  private static readonly detailPropertiesPois = new DetailProperties([
    DetailProperties.NAME,
    DetailProperties.PHONE,
    DetailProperties.EMAIL,
    DetailProperties.WEBSITE,
    DetailProperties.STREET,
    DetailProperties.PLACE,
    DetailProperties.POSTCODE,
    DetailProperties.ACCOMMODATION,
    DetailProperties.GASTRONOMY,
    DetailProperties.SIZE,
    DetailProperties.OPERATOR,
    DetailProperties.SUPPLIER,
    DetailProperties.ELEVATION,
    DetailProperties.SOURCE,
    DetailProperties.SOURCE_URL,
  ]);

  private static readonly detailPropertiesHaltestellen = new DetailProperties([
    DetailProperties.NAME,
    DetailProperties.LINES,
    DetailProperties.ROUTE_TYPES_NAMES,
    DetailProperties.DEEP_LINK,
    DetailProperties.SOURCE,
    DetailProperties.SOURCE_URL,
  ]);

  public poiBettUndBikeInfrastruktur = new Infrastruktur(
    "layerSelectionMenu.pois.bett_und_bike",
    "hotel",
    50,
    "rrpbw-poi",
    ["bett_und_bike"],
    "rrpbw-poi",
    ["bett_und_bike"],
    InfrastrukturRepository.detailPropertiesPois,
    false,
    "#f57c00",
    Environment.poiIconZoomLevel().get("rrpbw-poi:bett_und_bike") ?? 0,
    "assets/poi-icons/bett_und_bike.svg"
  );

  public poiHaltestellenInfrastruktur = new Infrastruktur(
    "layerSelectionMenu.pois.haltestellen",
    "directions_bus",
    50,
    "rrpbw-poi",
    ["haltestellen"],
    "rrpbw-poi",
    ["haltestellen"],
    InfrastrukturRepository.detailPropertiesHaltestellen,
    false,
    "#ba2f2f",
    Environment.poiIconZoomLevel().get("rrpbw-poi:haltestellen") ?? 0,
    "assets/poi-icons/haltestellen.svg",
    f => {
      const routeTypesString = (f as Feature<Geometry>)?.get(DetailProperties.ROUTE_TYPES.key) as string;
      const routeTypes = routeTypesString.split(",");

      const poiIconsPath = "assets/poi-icons/";

      // Bahn
      if (routeTypes.some(type => ["1", "2", "12"].includes(type))) {
        return { mapIcon: `${poiIconsPath}train.svg`, scale: 1.3, minZoom: 12 };
      }

      // Straßenbahn
      if (routeTypes.some(type => ["0", "5"].includes(type))) {
        return { mapIcon: `${poiIconsPath}tram.svg`, scale: 1.05, minZoom: 14 };
      }

      // Bus
      if (routeTypes.some(type => ["3", "11"].includes(type))) {
        return { mapIcon: `${poiIconsPath}haltestellen.svg`, scale: 0.9, minZoom: 16 };
      }

      // Fähre
      if (routeTypes.includes("4")) {
        return { mapIcon: `${poiIconsPath}boat.svg`, scale: 0.9, minZoom: 16 };
      }

      // Seilbahn
      if (routeTypes.some(type => ["6", "7"].includes(type))) {
        return { mapIcon: `${poiIconsPath}ropeway.svg`, scale: 0.9, minZoom: 16 };
      }

      return { mapIcon: `${poiIconsPath}bus.svg`, scale: 0.9, minZoom: 16 };
    }
  );

  public poiRadservicepunkteInfrastruktur = new Infrastruktur(
    "layerSelectionMenu.pois.radservice-punkte-point",
    "build",
    50,
    "rrpbw-poi",
    ["radservice-punkte-point"],
    "rrpbw-poi",
    ["radservice-punkte-point"],
    InfrastrukturRepository.detailPropertiesPois,
    false,
    "#333333",
    Environment.poiIconZoomLevel().get("rrpbw-poi:radservice-punkte-point") ?? 0,
    "assets/poi-icons/radservice-punkte-point.svg"
  );

  public radnetzInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.radnetz",
    "route",
    50,
    "radvis-infrastrukturen",
    ["radvisnetz-radnetz"],
    undefined,
    undefined,
    InfrastrukturRepository.detailPropertiesRadVISNetz,
    true
  );

  public kommunalnetzInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.kommunalnetz",
    "route",
    10,
    "radvis-infrastrukturen",
    ["radvisnetz-kommunalnetz"],
    undefined,
    undefined,
    InfrastrukturRepository.detailPropertiesRadVISNetz,
    true
  );

  public kreisnetzInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.kreisnetz",
    "route",
    20,
    "radvis-infrastrukturen",
    ["radvisnetz-kreisnetz"],
    undefined,
    undefined,
    InfrastrukturRepository.detailPropertiesRadVISNetz,
    true
  );

  public radschnellverbindungenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.radschnellverbindungen",
    "speed",
    30,
    "radvis-infrastrukturen",
    ["radvisnetz-radschnellverbindungen"],
    undefined,
    undefined,
    InfrastrukturRepository.detailPropertiesRadVISNetz
  );

  public radvorrangroutenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.radvorrangrouten",
    "alt_route",
    40,
    "radvis-infrastrukturen",
    ["radvisnetz-radvorrangrouten"],
    undefined,
    undefined,
    InfrastrukturRepository.detailPropertiesRadVISNetz
  );

  public massnahmenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.massnahmen",
    "construction",
    60,
    "radvis-infrastrukturen",
    ["massnahmen-lines", "massnahmen-points"],
    undefined,
    undefined,
    new DetailProperties([
      DetailProperties.BEZEICHNUNG,
      DetailProperties.ID,
      DetailProperties.UMSETZUNGSSTATUS,
      DetailProperties.KATEGORIEN,
      DetailProperties.BAULASTTRAEGER,
      DetailProperties.DURCHFUEHRUNGSZEITRAUM,
      DetailProperties.MARKIERUNGSZUSTAENDIGER,
      DetailProperties.UNTERHALTSZUSTAENDIGER,
      DetailProperties.QUELLE,
    ])
  );

  public wegweisendeBeschilderungenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.wegweisende-beschilderungen",
    "signpost",
    50,
    "radvis-infrastrukturen",
    ["wegweisende_beschilderungen"],
    undefined,
    undefined,
    new DetailProperties([DetailProperties.PFOSTEN_NR])
  );

  public fahrradroutenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.fahrradrouten",
    "directions_bike",
    50,
    "radvis-infrastrukturen",
    ["fahrradrouten"],
    undefined,
    undefined,
    new DetailProperties([
      DetailProperties.KURZBESCHREIBUNG,
      DetailProperties.TOURENKATEGORIE,
      DetailProperties.VERANTWORTLICH,
      DetailProperties.HOMEPAGE,
      DetailProperties.OFFIZIELLE_LAENGE,
      DetailProperties.LAENGE_DER_HAUPTSTRECKE,
      DetailProperties.ANSTIEG,
      DetailProperties.ABSTIEG,
      DetailProperties.LIZENZ,
      DetailProperties.LIZENZNAMENSNENNUNG,
    ])
  );

  public barrierenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.barrieren",
    "block",
    70,
    "radvis-infrastrukturen",
    ["barrieren-lines", "barrieren-points"],
    undefined,
    undefined,
    new DetailProperties([
      DetailProperties.BARRIERENFORM,
      DetailProperties.BARRIERENDURCHFAHRBREITE,
      DetailProperties.ABSPERRANLAGENSICHERUNG,
      DetailProperties.ABSPERRANLAGENMARKIERUNG,
    ])
  );

  public bis2ArbeitsstellenPhaseFrInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.bis2arbeitsstellen-phase-fr",
    "remove_road",
    70,
    "bis2-arbeitsstellen",
    ["arbeitsstellen-phase-fr-symbol-wms", "arbeitsstellen-phase-fr-strecke-wms"],
    "bis2-arbeitsstellen",
    ["arbeitsstellen-phase-fr-punkt", "arbeitsstellen-phase-fr-linie"],
    InfrastrukturRepository.detailPropertiesBis2
  );

  public abstellanlagenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.abstellanlagen",
    "local_parking",
    70,
    "radvis-infrastrukturen",
    ["abstellanlagen"],
    undefined,
    undefined,
    new DetailProperties([
      DetailProperties.BETREIBER,
      DetailProperties.QUELL_SYSTEM,
      DetailProperties.ZUSTAENDIG,
      DetailProperties.ANZAHL_STELLPLAETZE,
      DetailProperties.ANZAHL_SCHLIESSFAECHER,
      DetailProperties.ANZAHL_LADEMOEGLICHKEITEN,
      DetailProperties.UEBERWACHT,
      DetailProperties.IST_BIKE_AND_RIDE,
      DetailProperties.GROESSENKL,
      DetailProperties.STELLPLATZART,
      DetailProperties.UEBERDACHT,
      DetailProperties.GEBUEHR_TAG,
      DetailProperties.GEBUEHR_MONAT,
      DetailProperties.GEBUEHR_JAHR,
      DetailProperties.SERVICEANGEBOT_BESCHREIBUNG,
      DetailProperties.WEITERE_INFORMATIONEN,
      DetailProperties.SERVICEANGEBOT_STATUS,
    ])
  );

  public leihstationenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.leihstationen",
    "pedal_bike",
    70,
    "radvis-infrastrukturen",
    ["leihstationen"],
    undefined,
    undefined,
    new DetailProperties([
      DetailProperties.BETREIBER,
      DetailProperties.ANZAHL_FAHRRAEDER,
      DetailProperties.ANZAHL_PEDELECS,
      DetailProperties.ANZAHL_ABSTELLMOEGLICHKEITEN,
      DetailProperties.FREIES_ABSTELLEN,
      DetailProperties.BUCHUNGS_URL,
      DetailProperties.SERVICEANGEBOT_STATUS,
    ])
  );

  public servicestationenInfrastruktur = new Infrastruktur(
    "radvisViewer.auswahlMenu.servicestationen",
    "build",
    70,
    "radvis-infrastrukturen",
    ["servicestationen"],
    undefined,
    undefined,
    new DetailProperties([
      DetailProperties.BETREIBER,
      DetailProperties.ZUSTAENDIG,
      DetailProperties.SERVICEANGEBOT_BESCHREIBUNG,
      DetailProperties.SERVICESTATION_NAME,
      DetailProperties.GEBUEHREN,
      DetailProperties.OEFFNUNGSZEITEN,
      DetailProperties.MARKE,
      DetailProperties.LUFTPUMPE,
      DetailProperties.KETTENWERKZEUG,
      DetailProperties.WERKZEUG,
      DetailProperties.FAHRRADHALTERUNG,
      DetailProperties.SERVICESTATION_TYP,
      DetailProperties.SERVICEANGEBOT_STATUS,
    ])
  );

  private sichtbareInfrastrukturenFilter = (infra: Infrastruktur): boolean => {
    return Environment.radvisViewerSichtbareInfrastrukturenLabelKeys().includes(infra.getLabelKeyWithoutPrefix());
  };

  public getAllInfrastrukturen(): Infrastruktur[] {
    return [...this.getAllPois(), ...this.getAllNonPoiInfrastrukturen()];
  }

  public getAllNonPoiInfrastrukturen(): Infrastruktur[] {
    return [...this.getAllNonPoiNonNetzInfrastrukturen(), ...this.getAllNetzInfrastrukturen()].filter(
      this.sichtbareInfrastrukturenFilter
    );
  }

  public getAllNetzInfrastrukturen(): Infrastruktur[] {
    return [this.radnetzInfrastruktur, this.kreisnetzInfrastruktur, this.kommunalnetzInfrastruktur].filter(
      this.sichtbareInfrastrukturenFilter
    );
  }

  public getAllNonPoiNonNetzInfrastrukturen(): Infrastruktur[] {
    return [
      this.radvorrangroutenInfrastruktur,
      this.radschnellverbindungenInfrastruktur,
      this.massnahmenInfrastruktur,
      this.wegweisendeBeschilderungenInfrastruktur,
      this.fahrradroutenInfrastruktur,
      this.barrierenInfrastruktur,
      this.bis2ArbeitsstellenPhaseFrInfrastruktur,
      this.abstellanlagenInfrastruktur,
      this.leihstationenInfrastruktur,
      this.servicestationenInfrastruktur,
    ].filter(this.sichtbareInfrastrukturenFilter);
  }

  public getAllPois(): Infrastruktur[] {
    return [this.poiBettUndBikeInfrastruktur, this.poiHaltestellenInfrastruktur, this.poiRadservicepunkteInfrastruktur];
  }

  public getAllNonPoiInfrastrukturenWithData(): Infrastruktur[] {
    return this.getAllNonPoiInfrastrukturen()
      .filter(i => i.hasData)
      .filter(this.sichtbareInfrastrukturenFilter);
  }

  public getAllNonPoiInfrastrukturenWithoutData(): Infrastruktur[] {
    return this.getAllNonPoiInfrastrukturen().filter(infra => !infra.hasData);
  }
}
