export function onKeyboardSubmit(
  event: KeyboardEvent,
  callback: (...args: any[]) => void,
  caller: any,
  ...args: any[]
): void {
  const enterKeyCodes = ["Enter", "Space"];
  if (enterKeyCodes.includes(event.code)) {
    callback.apply(caller, args);
  }
}
