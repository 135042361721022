import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/overlay";
import { Subscription } from "rxjs";
import { DialogWrapperComponent } from "../dumb-components/dialog-wrapper/dialog-wrapper.component";

export interface Dialog {
  readonly closed: EventEmitter<void>;
  readonly title: string;
  readonly description: string;
}

@Injectable({
  providedIn: "root",
})
export class DialogService implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(private matDialog: MatDialog) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  open(component: ComponentType<Dialog>): Dialog {
    const wrapper = this.matDialog.open(DialogWrapperComponent, {
      height: "24rem",
      width: "30rem",
      restoreFocus: false,
    });
    const result = wrapper.componentInstance.wrap(component);
    wrapper.componentInstance.title = result.instance.title;
    wrapper.componentInstance.description = result.instance.description;
    this.subscriptions.push(
      wrapper.componentInstance.closed.subscribe(() => this.close()),
      result.instance.closed.subscribe(() => this.close())
    );
    return result.instance;
  }

  close(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
    this.matDialog.closeAll();
  }
}
