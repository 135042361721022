import { GeoLocation } from "../../common/entities/geo-location";
import { Adresse } from "src/app/routenplaner/entities/adresse";

export class Poi extends Adresse {
  constructor(title: string, public geoLocation: GeoLocation, public poiName: string) {
    super(geoLocation, Poi.toAnschrift(title, poiName, geoLocation));
  }

  get string(): string {
    return this.anschrift ?? "";
  }

  private static toAnschrift(title: string, poiName: string, geoLocation: GeoLocation): string {
    return title + ": " + (poiName ?? geoLocation.string);
  }

  get sortingKey(): string {
    return this.poiName;
  }
}
