import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { InfrastrukturRepository } from "src/app/karte/repositories/infrastruktur.repository";
import { MAP_PROJECTION } from "src/app/constants";
import { map } from "rxjs/operators";
import GeoJSON from "ol/format/GeoJSON";
import { DetailProperties } from "src/app/karte/entities/detail-properties";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Geometry } from "ol/geom";
import { Feature } from "ol";
import { Fahrradroute } from "src/app/fahrradrouten/entities/fahrradroute";

@Injectable({ providedIn: "root" })
export class FahrradroutenService implements Resolve<Fahrradroute> {
  private url: string;

  constructor(private http: HttpClient, infrastrukturRepository: InfrastrukturRepository) {
    const workspace = infrastrukturRepository.fahrradroutenInfrastruktur.getWFSWorkspace();
    const wfsLayerDescriptor = infrastrukturRepository.fahrradroutenInfrastruktur.getFullWFSLayerDescriptor();
    this.url = `/api/geoserver/${workspace}/wms?SERVICE=WFS&VERSION=1.3.0&REQUEST=GetFeature&outputFormat=application%2Fjson&typeName=${wfsLayerDescriptor}&srsName=${MAP_PROJECTION}`;
  }

  getAllRoutes(): Observable<Fahrradroute[]> {
    return this.http
      .get<string>(
        `${this.url}&propertyName=${[
          DetailProperties.ID.key,
          DetailProperties.NAME.key,
          DetailProperties.KURZBESCHREIBUNG.key,
          DetailProperties.FAHRRADROUTE_BESCHREIBUNG.key,
          DetailProperties.KATEGORIE.key,
          DetailProperties.TOURENKATEGORIE.key,
          DetailProperties.VARIANTENKATEGORIE.key,
          DetailProperties.VERANTWORTLICH.key,
          DetailProperties.HOMEPAGE.key,
          DetailProperties.ABSTIEG.key,
          DetailProperties.ANSTIEG.key,
          DetailProperties.LAENGE_DER_HAUPTSTRECKE.key,
          DetailProperties.OFFIZIELLE_LAENGE.key,
          DetailProperties.LIZENZ.key,
          DetailProperties.LIZENZNAMENSNENNUNG.key,
          DetailProperties.ZUSAETLICHE_INFORMATIONEN.key,
        ].join(",")}`
      )
      .pipe(
        map(geojsonString => {
          return new GeoJSON().readFeatures(geojsonString).map(feature => this.mapFahrradroute(feature));
        })
      );
  }

  getRouteGeom(id: number): Observable<Geometry | undefined> {
    return this.http.get<string>(`${this.url}&propertyName=geom&${this.getCQLFilterParamForId(id)}`).pipe(
      map(geojsonString => {
        return new GeoJSON().readFeatures(geojsonString)[0].getGeometry();
      })
    );
  }

  getRouteGeoms(ids: number[]): Observable<Feature<Geometry>[]> {
    if (ids.length === 0) {
      return of([]);
    }
    return this.http.get<string>(`${this.url}&propertyName=geom,id&${this.getCQLFilterParamForIds(ids)}`).pipe(
      map(geojsonString => {
        return new GeoJSON().readFeatures(geojsonString);
      })
    );
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Fahrradroute> | Promise<Fahrradroute> | Fahrradroute {
    const idFromRoute = route.paramMap.get("id");
    if (!idFromRoute) {
      throw Error("Id muss in der Route gesetzt sein!");
    }
    return this.http.get<string>(`${this.url}&${this.getCQLFilterParamForId(idFromRoute)}`).pipe(
      map(geojsonString => {
        return new GeoJSON().readFeatures(geojsonString).map(feature => this.mapFahrradroute(feature))[0];
      })
    );
  }

  private mapFahrradroute(feature: Feature<Geometry>): Fahrradroute {
    return {
      id: +feature.getProperties()[DetailProperties.ID.key],
      geometry: feature.getGeometry(),
      name: feature.getProperties()[DetailProperties.NAME.key],
      kurzbeschreibung: feature.getProperties()[DetailProperties.KURZBESCHREIBUNG.key],
      beschreibung: feature.getProperties()[DetailProperties.FAHRRADROUTE_BESCHREIBUNG.key],
      kategorie: feature.getProperties()[DetailProperties.KATEGORIE.key],
      tourenkategorie: feature.getProperties()[DetailProperties.TOURENKATEGORIE.key],
      variantenkategorie: feature.getProperties()[DetailProperties.VARIANTENKATEGORIE.key],
      verantwortlich: feature.getProperties()[DetailProperties.VERANTWORTLICH.key],
      homepage: feature.getProperties()[DetailProperties.HOMEPAGE.key],
      abstieg: feature.getProperties()[DetailProperties.ABSTIEG.key],
      anstieg: feature.getProperties()[DetailProperties.ANSTIEG.key],
      laenge: feature.getProperties()[DetailProperties.LAENGE_DER_HAUPTSTRECKE.key],
      offizielleLaenge: feature.getProperties()[DetailProperties.OFFIZIELLE_LAENGE.key],
      lizenz: feature.getProperties()[DetailProperties.LIZENZ.key],
      lizenzNamensnennung: feature.getProperties()[DetailProperties.LIZENZNAMENSNENNUNG.key],
      zusaetzlicheInformationen: feature.getProperties()[DetailProperties.ZUSAETLICHE_INFORMATIONEN.key],
    };
  }

  private getCQLFilterParamForId(id: number | string): string {
    return `cql_filter=${encodeURI("id=" + id)}`;
  }

  private getCQLFilterParamForIds(ids: number[]): string {
    return `cql_filter=${encodeURI("id IN(" + ids.join(",") + ")")}`;
  }
}
