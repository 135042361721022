import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ComponentType } from "@angular/cdk/overlay";

@Component({
  selector: "rrpbw-dialog-wrapper",
  templateUrl: "./dialog-wrapper.component.html",
  styleUrls: ["./dialog-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWrapperComponent {
  @ViewChild("child", { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  title?: string;
  description?: string;

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  wrap<T>(component: ComponentType<T>): ComponentRef<T> {
    const factory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(component);
    return this.container.createComponent(factory);
  }
}
