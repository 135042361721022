<div *ngIf="showKartendatenAndStrecken && Environment.poisAnzeigen()" class="submenu">
  <h1 class="ml-m mt-s mb-s">{{ "layerSelectionMenu.poiTitle" | translate }}</h1>
  <rrpbw-poi-toggle-menu class="mt-xxs" [menuEntries]="infrastrukturen"></rrpbw-poi-toggle-menu>
</div>

<div *ngIf="showKartendatenAndStrecken" class="submenu">
  <h1 class="ml-m mt-s mb-xs">Strecken</h1>
  <rrpbw-radnetz-toggle-menu
    [radNetzSelected]="radNetzSelected"
    (radNetzVisibilityChanged)="onRadNetzVisibilityChanged($event)"
  ></rrpbw-radnetz-toggle-menu>
</div>

<div class="submenu preview-container mb-m">
  <h1 class="ml-m mt-s mb-s">{{ "layerSelectionMenu.hintergrundTitle" | translate }}</h1>
  <ng-container *ngFor="let ebene of hintergrundebenen">
    <rrpbw-hintergrundebene-preview
      tabindex="0"
      [hintergrundebene]="ebene"
      [isSelected]="ebene.id === selectedHintergrundebeneId"
      (click)="onSelectLayer(ebene.id)"
      (keydown)="onKeyboardSubmit($event, onSelectLayer, this, ebene.id)"
      rrpbwAccessabilityTabCircleElement
    >
    </rrpbw-hintergrundebene-preview>
  </ng-container>
</div>
