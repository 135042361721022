<button
  class="radtypButton focus"
  mat-mini-fab
  [class.active]="selectedRadtyp === Radtyp.FAHRRAD"
  (click)="onRadtypChanged(Radtyp.FAHRRAD)"
  [attr.aria-label]="'radtypAuswahl.fahrrad' | translate"
  [attr.aria-labelledby]="'radtypTitle'"
  title="{{ 'radtypAuswahl.fahrrad' | translate }}"
>
  <mat-icon svgIcon="biker"></mat-icon>
</button>
<button
  class="radtypButton focus"
  mat-mini-fab
  [class.active]="selectedRadtyp === Radtyp.MOUNTAINBIKE"
  (click)="onRadtypChanged(Radtyp.MOUNTAINBIKE)"
  [attr.aria-label]="'radtypAuswahl.mountainbike' | translate"
  [attr.aria-labelledby]="'radtypTitle'"
  title="{{ 'radtypAuswahl.mountainbike' | translate }}"
>
  <mat-icon svgIcon="mountainbiker"></mat-icon>
</button>
<button
  class="radtypButton focus"
  mat-mini-fab
  [class.active]="selectedRadtyp === Radtyp.RENNRAD"
  (click)="onRadtypChanged(Radtyp.RENNRAD)"
  [attr.aria-label]="'radtypAuswahl.rennrad' | translate"
  [attr.aria-labelledby]="'radtypTitle'"
  title="{{ 'radtypAuswahl.rennrad' | translate }}"
>
  <mat-icon svgIcon="racingbiker"></mat-icon>
</button>
<button
  *ngIf="lastenradAnzeigen"
  class="radtypButton focus"
  mat-mini-fab
  [class.active]="selectedRadtyp === Radtyp.LASTENRAD"
  (click)="onRadtypChanged(Radtyp.LASTENRAD)"
  [attr.aria-label]="'radtypAuswahl.lastenrad' | translate"
  [attr.aria-labelledby]="'radtypTitle'"
  title="{{ 'radtypAuswahl.lastenrad' | translate }}"
>
  <mat-icon svgIcon="cargobiker"></mat-icon>
</button>
