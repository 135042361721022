import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { KarteBaseService } from "src/app/common/services/karte-base.service";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Geometry } from "ol/geom";
import { Icon, Style } from "ol/style";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import GeoJSON from "ol/format/GeoJSON";
import { LayerQueryService } from "src/app/karte/services/layer-query.service";
import { Feature } from "ol";
import { Layer } from "ol/layer";
import { Source } from "ol/source";
import { ColorService } from "../../../common/services/color.service";
import { PoiSelectionService } from "../../services/poi-selection.service";
import { Subscription } from "rxjs";
import { Infrastruktur, MapIconStyleInfo } from "src/app/karte/entities/infrastruktur";
import { Pixel } from "ol/pixel";
import { AbstractFeatureLayerComponent } from "src/app/karte/smart-components/poi-layer/abstract-feature-layer.component";
import { StyleFunction } from "ol/style/Style";
import { FeatureLike } from "ol/Feature";

@Component({
  selector: "rrpbw-poi-layer",
  templateUrl: "./poi-layer.component.html",
  styleUrls: ["./poi-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoiLayerComponent extends AbstractFeatureLayerComponent implements OnInit, OnDestroy, LayerQueryService {
  static readonly GEOSERVER_POI_URL_TEMPLATE: string =
    "/api/geoserver/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames={workspace}:{layer}&outputFormat=application/json&bbox={bbox},EPSG:3857";

  readonly featureLayer: VectorLayer<VectorSource<Geometry>>;

  readonly subscriptions: Subscription[] = [];

  selectedFeatures: Feature<Geometry>[] = [];

  @Input()
  poiInfrastruktur: Infrastruktur;

  constructor(
    public karteBaseService: KarteBaseService,
    private colorService: ColorService,
    public poiSelectionService: PoiSelectionService
  ) {
    super();
    this.featureLayer = new VectorLayer({
      style: new Style(),
      zIndex: AbstractFeatureLayerComponent.ZINDEX,
    });
  }

  ngOnInit(): void {
    this.featureLayer.setStyle(this.getStyleFunction());
    this.featureLayer.setMinZoom(this.poiInfrastruktur.minZoom);
    this.featureLayer.setSource(
      new VectorSource({
        format: new GeoJSON(),
        url: extent =>
          PoiLayerComponent.GEOSERVER_POI_URL_TEMPLATE.replace("{workspace}", this.poiInfrastruktur.layerWorkspace!)
            .replace("{layer}", this.poiInfrastruktur.layerNames![0])
            .replace("{bbox}", extent.join(",")),
        strategy: bboxStrategy,
      })
    );
    this.karteBaseService.addLayer(this.featureLayer);

    this.subscriptions.push(this.poiSelectionService.$selectPoi.subscribe(feature => this.selectFeature(feature)));
  }

  ngOnDestroy(): void {
    this.karteBaseService.removeLayer(this.featureLayer);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get featureLayerSource(): VectorSource<Geometry> {
    return this.featureLayer.getSource()!;
  }

  getInfrastruktur(): Infrastruktur {
    return this.poiInfrastruktur;
  }

  isLayer(l: Layer<Source>): boolean {
    return l === this.featureLayer;
  }

  protected getStyleFunction(scale: number = 1, saturation: number = 1, zIndex: number = 0): StyleFunction {
    const iconColor = this.colorService.toPaleBrightHex(this.poiInfrastruktur.iconColor, saturation);

    if (this.poiInfrastruktur.mapIconStyleInfoFn != null) {
      return this.getFeatureDependentIconStyle(scale, zIndex, iconColor, this.poiInfrastruktur.mapIconStyleInfoFn);
    }

    return this.getSingleIconStyle(scale, zIndex, iconColor, this.poiInfrastruktur.mapIcon);
  }

  private getSingleIconStyle(scale: number, zIndex: number, iconColor: string, mapIcon: string): StyleFunction {
    return (): Style[] => {
      return [
        this.getBackgroundPinStyle(zIndex, "assets/poi-icons/map-pin.svg", scale, iconColor),
        this.getMapIconStyle(zIndex, mapIcon, scale),
      ];
    };
  }

  private getFeatureDependentIconStyle(
    baseScale: number,
    zIndex: number,
    iconColor: string,
    mapIconStyleInfoFn: (f: FeatureLike) => MapIconStyleInfo
  ): StyleFunction {
    return (f: FeatureLike, res: number): Style[] | Style | void => {
      const { mapIcon, scale, minZoom } = mapIconStyleInfoFn(f);
      const zoomForResolution = this.karteBaseService.getZoomForResolution(res);
      if (zoomForResolution && zoomForResolution < minZoom) {
        return;
      }
      return this.getSingleIconStyle(baseScale * scale, zIndex, iconColor, mapIcon)(f, res);
    };
  }

  private getBackgroundPinStyle(
    zIndex: number,
    pinIcon: string,
    scale: number,
    iconColor: string,
    displacementX = 0
  ): Style {
    return new Style({
      zIndex: zIndex,
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: pinIcon,
        scale: 0.33 * scale,
        color: iconColor,
        displacement: [displacementX, 0],
      }),
    });
  }

  private getMapIconStyle(zIndex: number, mapIcon: string, scale: number, displacementX = 0): Style {
    return new Style({
      zIndex: zIndex,
      image: new Icon({
        anchor: [0.5, 2.35],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: mapIcon,
        scale: 0.125 * scale,
        color: "#ffffff",
        displacement: [displacementX, 0],
      }),
    });
  }

  getTooltipDisplacement(): Pixel {
    return [0, 0];
  }
}
