import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LocateMeService implements OnDestroy {
  private _$locateMe: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this._$locateMe.complete();
  }

  public locateMe(): void {
    this._$locateMe.next();
  }

  get $locateMe(): Observable<void> {
    return this._$locateMe.asObservable();
  }
}
