import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AdresseRepository } from "../../repositories/adresse.repository";
import { Adresse } from "../../entities/adresse";
import { catchError, Observable, of, Subject, Subscription, switchMap, zip } from "rxjs";
import { MapMarkerService } from "../../../karte/services/map-marker.service";
import { MapMarker } from "../../../karte/entities/map-marker";
import { MapRouteService } from "../../../karte/services/map-route.service";
import { KarteContextMenuEntry } from "../../../karte/entities/karte-context-menu-entry";
import { GeoLocation } from "../../../common/entities/geo-location";
import { Hintergrundebene } from "../../../karte/entities/hintergrundebene";
import { Radtyp } from "src/app/karte/entities/radtyp";
import { DialogService } from "../../../common/services/dialog.service";
import { RouteTeilenDialogComponent } from "../route-teilen-dialog/route-teilen-dialog.component";
import { UrlStorageKeys, UrlStorageRepository } from "../../../common/repositories/url-storage.repository";
import { debounceTime, map, take, throttleTime } from "rxjs/operators";
import { RouteSpeichernDialogComponent } from "../route-speichern-dialog/route-speichern-dialog.component";
import { RoutingPraeferenz } from "../../../karte/entities/routing-praeferenz";
import { MapHoehenprofilService } from "../../../karte/services/map-hoehenprofil.service";
import { onKeyboardSubmit } from "../../../common/utils/accessibility";
import { KartePreviewService } from "../../services/karte-preview.service";
import { WegpunktEditorService } from "../../services/wegpunkt-editor.service";
import { LayoutService, ScreenLayout } from "src/app/common/services/layout.service";
import { MatDrawer, MatSidenav } from "@angular/material/sidenav";
import { MobileKarteTabMenuService } from "../../services/mobile-karte-tab-menu.service";
import { TitleService } from "../../../common/services/title.service";
import { PoiRepository } from "../../../karte/repositories/poi.repository";
import { Environment } from "../../../common/utils/environment";
import { Stringer } from "../../../common/entities/stringer";
import { PoiSelectionService } from "../../../karte/services/poi-selection.service";
import { WegpunkteBearbeitet } from "src/app/routenplaner/dumb-components/wegpunkt-editor/wegpunkt-editor.component";
import { InfrastrukturRepository } from "src/app/karte/repositories/infrastruktur.repository";
import { Infrastruktur } from "src/app/karte/entities/infrastruktur";
import { RouteMitWegpunkten } from "src/app/common/entities/route-mit-wegpunkten";
import { SelectedInfrastrukturFeature } from "src/app/karte/entities/selected-infrastruktur-feature";
import { Fahrverhalten } from "src/app/common/entities/fahrverhalten";
import { HoveredHoehenprofilLocation } from "src/app/common/entities/hoehenprofil-hover-location";
import { Signatur } from "src/app/karte/entities/signatur";

export enum MatDrawerContent {
  ROUTING_OPTIONS,
  POI_DETAILS,
}

@Component({
  selector: "rrpbw-routenplaner",
  templateUrl: "./routenplaner.component.html",
  styleUrls: ["./routenplaner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutenplanerComponent implements OnInit, OnDestroy {
  ScreenLayout = ScreenLayout;
  Hintergrundebene = Hintergrundebene;
  MatDrawerContent = MatDrawerContent;
  onKeyboardSubmit = onKeyboardSubmit;

  static readonly WEGPUNKT_BEARBEITET_DEBOUNCE_TIME: number = 50;
  static readonly PREVIEW_ROUTE_THROTTLE_TIME: number = 250;

  public readonly Signatur = Signatur;

  readonly onQueryChanged: (q: string) => Observable<Stringer[]> = (query: string) => this._onQueryChanged(query);
  routeMitWegpunkten?: RouteMitWegpunkten;
  markers: ([Adresse, MapMarker] | undefined)[] = [undefined, undefined];
  hoveredHoehenprofilLocation: HoveredHoehenprofilLocation | undefined;

  currentRadtyp: Radtyp = Radtyp.FAHRRAD;
  currentFahrverhalten: Fahrverhalten = Fahrverhalten.ZUEGIG;
  currentRoutingPraeferenz: RoutingPraeferenz = RoutingPraeferenz.RADNETZ_ANY;
  currentSteigungVermeiden: boolean = false;

  zoomToRoute: boolean = true;
  currentScreenLayout: ScreenLayout = ScreenLayout.Desktop;
  showHoehenprofil: boolean = false;
  matDrawerContent: MatDrawerContent | undefined = undefined;
  selectedPoi?: SelectedInfrastrukturFeature;

  previewGeoLocations: GeoLocation[];
  previewGeoLocationIndex: number | undefined;
  $previewRouteThrottle: Subject<GeoLocation[]> = new Subject();

  defaultContextMenuEntries: KarteContextMenuEntry[];
  wegpunktContextMenuEntries: KarteContextMenuEntry[];

  $onWegpunktBearbeitet: Subject<WegpunkteBearbeitet> = new Subject();

  @ViewChild("mobileKarteTabMenu", { static: false })
  mobileKarteTabMenuService: MobileKarteTabMenuService;

  @ViewChild("kartePreview", { static: false })
  kartePreviewService: KartePreviewService;

  @ViewChild("wegpunktEditor", { static: true })
  wegpunktEditorService: WegpunktEditorService;

  @ViewChild("routeLayer", { static: true })
  mapRouteService: MapRouteService;

  @ViewChild("markerLayer", { static: true })
  mapMarkerService: MapMarkerService;

  @ViewChild("hoehenprofilLayer", { static: true })
  mapHoehenprofilService: MapHoehenprofilService;

  @ViewChild("matDrawer", { static: true })
  matDrawer: MatDrawer;

  @ViewChild("matSidenav", { static: true })
  matSidenav: MatSidenav;

  subscriptions: Subscription[] = [];
  fahrradroutenAnzeigen = Environment.fahrradroutenAnzeigen();

  constructor(
    public adresseRepository: AdresseRepository,
    public urlStorageRepository: UrlStorageRepository,
    public poiRepository: PoiRepository,
    public dialogService: DialogService,
    layoutService: LayoutService,
    public titleService: TitleService,
    public poiSelectionService: PoiSelectionService,
    public infrastrukturRepository: InfrastrukturRepository,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      layoutService.screenLayout.subscribe(screenLayout => {
        this.currentScreenLayout = screenLayout;
        this.changeDetectorRef.markForCheck();
      }),
      this.$onWegpunktBearbeitet
        .pipe(debounceTime(RoutenplanerComponent.WEGPUNKT_BEARBEITET_DEBOUNCE_TIME))
        .subscribe(({ adressen, zoomToRoute }) => this.onWegpunktBearbeitet(adressen, zoomToRoute)),
      this.$previewRouteThrottle
        .pipe(
          throttleTime(RoutenplanerComponent.PREVIEW_ROUTE_THROTTLE_TIME),
          switchMap(geoLocations =>
            this.mapRouteService.previewRoute(
              geoLocations,
              this.currentRadtyp,
              this.currentRoutingPraeferenz,
              this.currentSteigungVermeiden
            )
          )
        )
        .subscribe(() => {})
    );

    this.defaultContextMenuEntries = [
      new KarteContextMenuEntry("markerLayer.contextMenu.start", geoLocation =>
        this.contextMenuStartAngelegtCallbackFn(geoLocation)
      ),
      new KarteContextMenuEntry("markerLayer.contextMenu.wegpunkt", geoLocation =>
        this.contextMenuWegpunktAngelegtCallbackFn(geoLocation)
      ),
      new KarteContextMenuEntry("markerLayer.contextMenu.ziel", geoLocation =>
        this.contextMenuZielAngelegtCallbackFn(geoLocation)
      ),
    ];
    this.wegpunktContextMenuEntries = [
      new KarteContextMenuEntry("markerLayer.contextMenu.entfernen", geoLocation =>
        this.contextMenuWegpunktEntferntCallbackFn(geoLocation)
      ),
    ];
  }

  ngOnInit(): void {
    zip(
      this.urlStorageRepository.findQueryParameter<[number, number, string?][]>(UrlStorageKeys.WEGPUNKTE),
      this.urlStorageRepository.findQueryParameter<Radtyp>(UrlStorageKeys.RADTYP),
      this.urlStorageRepository.findQueryParameter<RoutingPraeferenz>(UrlStorageKeys.ROUTING_PRAEFERENZ),
      this.urlStorageRepository.findQueryParameter<boolean>(UrlStorageKeys.STEIGUNG_VERMEIDEN),
      this.urlStorageRepository.findQueryParameter<number>(UrlStorageKeys.FAHRVERHALTEN)
    )
      .pipe(take(1))
      .subscribe(([urlWegpunkte, urlRadtyp, routingPraeferenz, steigungVermeiden, fahrverhaltenFaktor]) => {
        if (urlWegpunkte) {
          const adressen = urlWegpunkte.map(wegpunkt => Adresse.prototype.deserialize(wegpunkt));
          this.applyAdressen(adressen);
        }

        if (urlRadtyp) {
          this.currentRadtyp = urlRadtyp;
        }

        if (routingPraeferenz) {
          this.currentRoutingPraeferenz = routingPraeferenz;
        }

        this.currentSteigungVermeiden = !!steigungVermeiden;

        if (fahrverhaltenFaktor) {
          if (Fahrverhalten.isValid(fahrverhaltenFaktor)) {
            this.currentFahrverhalten = Fahrverhalten.ofFaktor(fahrverhaltenFaktor);
          } else {
            this.currentFahrverhalten = Fahrverhalten.ZUEGIG;
            this.urlStorageRepository.setQueryParameter(UrlStorageKeys.FAHRVERHALTEN, Fahrverhalten.ZUEGIG.faktor);
          }
        }

        this.updateRoute();
      });

    this.titleService.setSuffix("routenplaner.titleSuffix");
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get isSmartphoneLayout(): boolean {
    return this.currentScreenLayout === ScreenLayout.Smartphone;
  }

  onWegpunktBearbeitet(adressen: (Adresse | undefined)[], zoomToRoute = true): void {
    this.mapMarkerService.clearLayer();
    this.markers = [];

    adressen.forEach((adresse, index) => {
      if (adresse != null) {
        if (index === 0) {
          this.markers.push([adresse, this.mapMarkerService.createPinMarker(adresse.geoLocation, "A")]);
        } else if (index < adressen.length - 1) {
          this.markers.push([adresse, this.mapMarkerService.createPointMarker(adresse.geoLocation, index.toString())]);
        } else {
          this.markers.push([adresse, this.mapMarkerService.createPinMarker(adresse.geoLocation, "B")]);
        }
      } else {
        this.markers.push(undefined);
      }
    });

    this.urlStorageRepository.setQueryParameter(
      UrlStorageKeys.WEGPUNKTE,
      adressen.filter(adresse => !!adresse).map(adresse => adresse?.serialize())
    );

    this.updateRoute(zoomToRoute);
  }

  onKartePreviewClicked(sideNav: MatSidenav): void {
    sideNav.close().then(() => {
      this.mapRouteService.zoomToRoute();
    });
  }

  onHoehenprofilPreviewClicked(matSidenav: MatSidenav): void {
    if (this.currentScreenLayout === ScreenLayout.Smartphone) {
      matSidenav.close().then(() => {
        this.mobileKarteTabMenuService.open();
      });
    } else if (this.currentScreenLayout === ScreenLayout.Tablet) {
      matSidenav.close().then(() => {
        this.showHoehenprofil = true;
        this.changeDetectorRef.markForCheck();
      });
    } else {
      this.showHoehenprofil = true;
    }
  }

  onRadtypChanged(radtyp: Radtyp): void {
    this.currentRadtyp = radtyp;
    this.urlStorageRepository.setQueryParameter(UrlStorageKeys.RADTYP, radtyp);
    this.updateRoute();
  }

  onRoutingPraeferenzChanged(routingPraeferenz: RoutingPraeferenz): void {
    this.currentRoutingPraeferenz = routingPraeferenz;
    this.urlStorageRepository.setQueryParameter(UrlStorageKeys.ROUTING_PRAEFERENZ, routingPraeferenz);
    this.updateRoute();
  }

  onSteigungVermeidenChanged(steigungVermeiden: boolean): void {
    this.currentSteigungVermeiden = steigungVermeiden;
    this.urlStorageRepository.setQueryParameter(UrlStorageKeys.STEIGUNG_VERMEIDEN, steigungVermeiden);
    this.updateRoute();
  }

  onFahrverhaltenChanged(fahrverhalten: Fahrverhalten): void {
    this.currentFahrverhalten = fahrverhalten;
    this.urlStorageRepository.setQueryParameter(UrlStorageKeys.FAHRVERHALTEN, fahrverhalten.faktor);
  }

  onRouteEditedPreview([newGeoLocation, routePointsBeforeEdit]: [GeoLocation, GeoLocation[]]): void {
    const approximatedGeoLocations = this.markers
      .filter(marker => !!marker)
      .map(marker => marker![0])
      .map(adresse => adresse.geoLocation)
      .map(geoLocation => this.mapRouteService.getClosestPointToGeoLocation(geoLocation));

    let geoLocationHead = 0;

    const geoLocations: GeoLocation[] = [];
    routePointsBeforeEdit.forEach(routePoint => {
      if (routePoint.isEqualTo(newGeoLocation)) {
        this.previewGeoLocationIndex = geoLocationHead;
        geoLocations.push(routePoint);
      }

      if (routePoint.isEqualTo(approximatedGeoLocations[geoLocationHead])) {
        geoLocations.push(approximatedGeoLocations[geoLocationHead]);
        geoLocationHead++;
      }
    });

    if (this.previewGeoLocations !== geoLocations) {
      this.previewGeoLocations = geoLocations;

      this.$previewRouteThrottle.next(this.previewGeoLocations);
    }
  }

  onRouteEdited(newGeoLocation: GeoLocation): void {
    const adresse = new Adresse(newGeoLocation);

    if (!this.previewGeoLocations || this.previewGeoLocations.length < 2) {
      return;
    }

    this.mapRouteService
      .setRoute(
        this.previewGeoLocations,
        this.currentRadtyp,
        this.currentRoutingPraeferenz,
        this.currentSteigungVermeiden
      )
      .subscribe(() => {});

    this.zoomToRoute = false;
    this.wegpunktEditorService.wegpunktAnlegen(adresse, this.previewGeoLocationIndex, false);

    this.previewGeoLocations = [];
    this.previewGeoLocationIndex = undefined;

    this.changeDetectorRef.markForCheck();
  }

  onHoehenprofilPointMarked(index: number | undefined): void {
    this.mapHoehenprofilService.highlightPointAtIndex(index);
  }

  onHoehenprofilHovered(hoverLocation: HoveredHoehenprofilLocation | undefined): void {
    this.hoveredHoehenprofilLocation = hoverLocation;
  }

  onMatDrawerClose(): void {
    this.matDrawerContent = undefined;
    this.selectedPoi = undefined;
  }

  onPoiDetailsClosed(): void {
    this.poiSelectionService.deselectPoi();
    this.matDrawer.close();
  }

  onPoiClicked(poiClickedEvent: SelectedInfrastrukturFeature | null): void {
    this.selectedPoi = poiClickedEvent ?? undefined;
    if (!this.isSmartphoneLayout) {
      if (!poiClickedEvent) {
        if (this.matDrawerContent === MatDrawerContent.POI_DETAILS) {
          this.matDrawer.close();
        }
      } else {
        this.matDrawerContent = MatDrawerContent.POI_DETAILS;
        this.matSidenav.open();
        this.matDrawer.open();
      }
    }
  }

  openRoutingOptions(): void {
    this.matDrawerContent = MatDrawerContent.ROUTING_OPTIONS;
    this.matDrawer.open();
  }

  openRouteTeilenDialog(): void {
    this.dialogService.open(RouteTeilenDialogComponent);
  }

  openRouteSpeichernDialog(): void {
    const speichernDialog = this.dialogService.open(RouteSpeichernDialogComponent) as RouteSpeichernDialogComponent;
    speichernDialog.generateGpxFromRouteFn = () => this.mapRouteService.getRouteAsGpx();
    speichernDialog.generateKmlFromRouteFn = () => this.mapRouteService.getRouteAsKml();
  }

  onMarkerPreview(mapMarker: MapMarker): void {
    const markers: MapMarker[] = this.markers.filter(item => !!item).map(item => item![1]);
    const index = markers.findIndex(marker => marker?.id === mapMarker.id);
    const geoLocations = markers.map(marker => marker?.geoLocation);
    if (geoLocations.length > 1) {
      this.$previewRouteThrottle.next([
        ...geoLocations.slice(0, index),
        mapMarker.geoLocation,
        ...geoLocations.slice(index + 1),
      ]);
    }
  }

  onMarkerEdited(mapMarker: MapMarker): void {
    const index = this.markers.findIndex(item => !!item && item[1].id === mapMarker.id);
    this.wegpunktEditorService.wegpunktAnlegen(new Adresse(mapMarker.geoLocation), index, true);
  }

  get sichtbareInfrastrukturen(): Infrastruktur[] {
    return this.infrastrukturRepository
      .getAllNonPoiInfrastrukturenWithData()
      .filter(layer => layer === this.infrastrukturRepository.radnetzInfrastruktur);
  }

  get selektierbarePoiInfrastrukturen(): Infrastruktur[] {
    return this.infrastrukturRepository.getAllPois();
  }

  get selectedPoiIcon(): string {
    return (
      this.selectedPoi?.infrastruktur?.mapIconStyleInfoFn?.(this.selectedPoi?.feature)?.mapIcon ??
      this.selectedPoi?.infrastruktur?.mapIcon ??
      ""
    );
  }

  get lastenrad(): boolean {
    return this.currentRadtyp === Radtyp.LASTENRAD;
  }

  private contextMenuStartAngelegtCallbackFn(geoLocation: GeoLocation): void {
    const adresse = new Adresse(geoLocation);
    this.wegpunktEditorService.wegpunktAnlegen(adresse, 0, true);
  }

  private contextMenuZielAngelegtCallbackFn(geoLocation: GeoLocation): void {
    const adresse = new Adresse(geoLocation);
    this.wegpunktEditorService.wegpunktAnlegen(adresse, undefined, true);
  }

  private contextMenuWegpunktAngelegtCallbackFn(geoLocation: GeoLocation): void {
    const adresse = new Adresse(geoLocation);
    this.wegpunktEditorService.wegpunktAnlegen(adresse, this.markers.length - 1, false);
  }

  private contextMenuWegpunktEntferntCallbackFn(interactionGeoLocation: GeoLocation): void {
    /*
     * 03.12.21 - td: Da auf Touch-Geräten sowohl Click und Drag-Interaktion auftreten kann,
     * kann sich die GeoLocation leicht verändern, bevor wir das Kommando geben den Wegpunkt zu löschen.
     * Aus diesem Grund wird stattdessen der nächste Wegpunkt ausgewählt.
     */
    const distances = this.markers
      .map(item => (item ? item[1] : undefined))
      .map(mapMarker => mapMarker?.geoLocation.distanceTo(interactionGeoLocation) ?? Number.MAX_VALUE);
    const index = distances.indexOf(Math.min(...distances));
    const geoLocation = this.markers[index]![1].geoLocation;
    const adresse = new Adresse(geoLocation);
    this.wegpunktEditorService.wegpunktEntfernen(adresse);
  }

  private updateRoute(zoomToRoute = true): void {
    const validMarkers = this.markers.filter(marker => !!marker);
    if (validMarkers.length >= 2) {
      const geoLocations = validMarkers.map(item => item![1].geoLocation);
      this.mapRouteService
        .setRoute(geoLocations, this.currentRadtyp, this.currentRoutingPraeferenz, this.currentSteigungVermeiden)
        .subscribe({
          next: route => {
            this.routeMitWegpunkten = {
              route,
              wegpunkte: this.markers.filter(marker => !!marker).map(item => item![1].geoLocation.coordinates),
            };

            this.mapHoehenprofilService.setRoute(route);
            this.kartePreviewService?.updateRoute(route);
            if (this.zoomToRoute && zoomToRoute) {
              this.mapRouteService.zoomToRoute();
            }
            this.zoomToRoute = true;
          },
          error: () => {
            this.mapRouteService.clearLayer();
            this.mapHoehenprofilService.clearLayer();
            this.kartePreviewService?.clearRoute();
            this.routeMitWegpunkten = undefined;
          },
          complete: () => {
            this.changeDetectorRef.markForCheck();
          },
        });
    } else {
      this.mapRouteService.clearLayer();
      this.mapHoehenprofilService.clearLayer();
      this.kartePreviewService?.clearRoute();
      this.routeMitWegpunkten = undefined;
      this.changeDetectorRef.markForCheck();
    }
    this.kartePreviewService?.updateMarker(this.markers.map(item => (item ? item[1] : undefined)));
  }

  private _onQueryChanged(query: string): Observable<Stringer[]> {
    if (query) {
      const querySources: Observable<Stringer[]>[] = [];
      querySources.push(this.adresseRepository.queryAdressen(query));
      if (Environment.poisAnzeigen()) {
        querySources.push(this.poiRepository.queryPois(query));
      }

      const replaceErrorWithEmpty = catchError<Stringer[], Observable<Stringer[]>>(error => {
        console.error("Abort collecting query results due to error:", error);
        return of([]);
      });
      const querySourcesWithCatch = querySources.map(s => s.pipe(replaceErrorWithEmpty));

      return zip(querySourcesWithCatch).pipe(
        map(results =>
          results
            .reduce((acc, item) => [...acc, ...item], [])
            .sort((a, b) => a.sortingKey!.localeCompare(b.sortingKey!, "de"))
        )
      );
    }

    return of([]);
  }

  private applyAdressen(adressen: Adresse[]): void {
    adressen.forEach((adresse, index) => {
      const deletePreviousElement = index === 0 || index === adressen.length - 1;
      this.wegpunktEditorService.wegpunktAnlegen(adresse, index, deletePreviousElement, true);
    });
  }
}
