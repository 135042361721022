const IS_MOBILE_DEVICE =
  /Android|webOS|iPhone|iPad|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
  "ontouchstart" in window;

const IS_IOS_DEVICE = /iPhone|iPad|Macintosh|iPod/i.test(navigator.userAgent) && "ontouchstart" in window;

export function isMobileDevice(): boolean {
  return IS_MOBILE_DEVICE;
}

export function isIOSDevice(): boolean {
  return IS_IOS_DEVICE;
}
