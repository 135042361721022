<mat-form-field appearance="standard">
  <mat-select
    (selectionChange)="onFilterKategorieChanges($event)"
    [value]="fahrradroutenFilterService.filterKategorie"
    [placeholder]="'fahrradrouten.filterKategoriePlaceholder' | translate"
  >
    <mat-option *ngFor="let kategorie of allFahrradroutenKategorien" [value]="kategorie">{{
      "detailProperties.values." + kategorie | translate
    }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-select
    (selectionChange)="onFilterTourenkategorieChanges($event)"
    [value]="fahrradroutenFilterService.filterTourenkategorie"
    [placeholder]="'fahrradrouten.filterTourenkategoriePlaceholder' | translate"
  >
    <mat-option *ngFor="let tourenkategorie of allFahrradroutenTourenkategorien" [value]="tourenkategorie">{{
      "detailProperties.values." + tourenkategorie | translate
    }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-select
    (selectionChange)="onFilterVarianteChanged($event)"
    [value]="fahrradroutenFilterService.filterVariantenkategorie"
    [placeholder]="'fahrradrouten.filterVariantenkategoriePlaceholder' | translate"
  >
    <mat-option *ngFor="let variantenkategorie of allFahrradroutenVarianten" [value]="variantenkategorie">{{
      "detailProperties.values." + variantenkategorie | translate
    }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard">
  <mat-icon matSuffix>search</mat-icon>
  <input
    matInput
    type="text"
    [value]="fahrradroutenFilterService.filterVolltext ?? ''"
    [placeholder]="'fahrradrouten.filterVolltextPlaceholder' | translate"
    (input)="onFilterVolltextChanged($event)"
  />
</mat-form-field>
