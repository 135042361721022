import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import TileLayer from "ol/layer/Tile";
import { Subscription } from "rxjs";
import TileSource from "ol/source/Tile";
import XYZ from "ol/source/XYZ";
import { HintergrundebenenService } from "../../services/hintergrundebenen.service";

@Component({
  selector: "rrpbw-tile-layer",
  templateUrl: "./tile-layer.component.html",
  styleUrls: ["./tile-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileLayerComponent implements OnInit, OnDestroy {
  @Input()
  public layerId: string;

  @Input()
  public label: string;

  @Input()
  public url: string;

  @Input()
  public maxZoom: number;

  @Input()
  public initiallyVisible: boolean = false;

  private readonly layer: TileLayer<TileSource>;
  private readonly subscriptions: Subscription[] = [];

  constructor(private hintergrundebenenService: HintergrundebenenService) {
    this.layer = new TileLayer();
  }

  ngOnInit(): void {
    this.layer.setSource(
      new XYZ({
        url: this.url,
        maxZoom: this.maxZoom,
      })
    );
    this.layer.setProperties({
      label: this.label,
      id: this.layerId,
    });
    this.layer.setVisible(this.initiallyVisible);

    this.subscriptions.push(
      this.hintergrundebenenService.$selectLayer.subscribe((layerId: string) => {
        this.layer.setVisible(layerId === this.layerId);
      })
    );

    this.hintergrundebenenService.addLayer(this.layer);
  }

  ngOnDestroy(): void {
    this.hintergrundebenenService.removeLayer(this.layer);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
