import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "rrpbw-radnetz-toggle-menu",
  templateUrl: "./radnetz-toggle-menu.component.html",
  styleUrls: ["./radnetz-toggle-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadnetzToggleMenuComponent {
  @Input()
  radNetzSelected: boolean = true;

  @Output()
  radNetzVisibilityChanged: EventEmitter<boolean> = new EventEmitter();
}
