import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { LayoutService, ScreenLayout } from "../common/services/layout.service";
import { NotificationService } from "../common/services/notification.service";
import { registerChartComponents } from "../common/utils/chart";
import { isIOSDevice } from "../common/utils/device";
import { Environment } from "../common/utils/environment";
import { FAHRRADROUTEN_PATH } from "src/app/fahrradrouten/fahrradrouten-routing.module";

@Component({
  selector: "rrpbw-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("mainNavList", { read: ElementRef })
  mainNavList: ElementRef | undefined;

  @ViewChild("secondaryNavList", { read: ElementRef })
  secondaryNavList: ElementRef | undefined;

  mainNavigationItems: [string, string][] = [["toolbar.radroutenplaner", "/radroutenplaner"]];

  secondaryNavigationItems: [string, string][] = [
    ["toolbar.hilfe", "/hilfe"],
    ["toolbar.kontakt", "/kontakt"],
    ["toolbar.datenschutz", "/datenschutz"],
    ["toolbar.impressum", "/impressum"],
    ["toolbar.nutzungsbedingungen", "/nutzungsbedingungen"],
  ];

  isMobileToolbar: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    translateService: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private layoutService: LayoutService,
    private notificationService: NotificationService
  ) {
    translateService.setDefaultLang("de");

    if (Environment.fahrradroutenAnzeigen()) {
      this.mainNavigationItems.push(["toolbar.fahrradrouten", `/${FAHRRADROUTEN_PATH}`]);
    }
    if (Environment.radvisViewerAnzeigen()) {
      this.mainNavigationItems.push(["toolbar.radvisViewer", "/radvis"]);
    }
    if (Environment.meldeplattformAnzeigen()) {
      this.mainNavigationItems.push(["toolbar.meldeplattform", "/meldeplattform"]);
    }

    this.registerIcons();
    registerChartComponents();
  }

  ngOnInit(): void {
    if (isIOSDevice()) {
      document.documentElement.style.height = "100vh";

      window.addEventListener("resize", () => {
        document.documentElement.style.height = window.innerHeight + "px";
      });
    }

    this.subscriptions.push(
      this.layoutService.screenLayout.subscribe(
        screenLayout =>
          (this.isMobileToolbar = screenLayout === ScreenLayout.Smartphone || screenLayout === ScreenLayout.Tablet)
      )
    );

    const toastTextKey = Environment.toastTextKey();
    if (toastTextKey != null && toastTextKey.trim() !== "") {
      this.notificationService.notifyPermanent("toast." + toastTextKey);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private registerIcons(): void {
    this.matIconRegistry.addSvgIcon(
      "biker",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/routenplaner/Biker.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "mountainbiker",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/routenplaner/Mountainbiker.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "racingbiker",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/routenplaner/Racingbiker.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "cargobiker",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/routenplaner/Cargobiker.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "open-arrows",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/routenplaner/open-arrows.svg")
    );
  }

  setAriaCurrentMainNav(active: boolean, index: number): void {
    if (!active) {
      return;
    }
    this.removeAllAriaCurrent();
    this.mainNavList?.nativeElement.querySelectorAll("a")[index].setAttribute("aria-current", "page");
  }

  setAriaCurrentSecondaryNav(active: boolean, index: number): void {
    if (!active) {
      return;
    }
    this.removeAllAriaCurrent();
    this.secondaryNavList?.nativeElement.querySelectorAll("a")[index].setAttribute("aria-current", "page");
  }

  private removeAllAriaCurrent(): void {
    Array.from(this.mainNavList?.nativeElement.querySelectorAll("a")).forEach((a: any) =>
      a.removeAttribute("aria-current")
    );
    Array.from(this.secondaryNavList?.nativeElement.querySelectorAll("a")).forEach((a: any) =>
      a.removeAttribute("aria-current")
    );
  }
}
