import { Component, ChangeDetectionStrategy, Input } from "@angular/core";

@Component({
  selector: "rrpbw-fahrradroute-detail-item",
  templateUrl: "./fahrradroute-detail-item.component.html",
  styleUrls: ["./fahrradroute-detail-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrradrouteDetailItemComponent {
  @Input()
  content: string;

  @Input()
  labelKey: string;
}
