<mat-sidenav-container [class.smartphone-layout]="isSmartphoneLayout">
  <mat-sidenav #matSidenav mode="side" opened [disableClose]="isSmartphoneLayout">
    <aside>
      <mat-drawer-container>
        <mat-drawer-content>
          <div class="mb-xs">
            <rrpbw-wegpunkt-editor
              #wegpunktEditor
              [adresseQueryChangedFn]="onQueryChanged"
              (wegpunkteBearbeitet)="$onWegpunktBearbeitet.next($event)"
            >
            </rrpbw-wegpunkt-editor>
            <button
              class="full-width-navigation-button focus"
              (click)="openRoutingOptions()"
              mat-flat-button
              [attr.aria-label]="'routenplaner.optionenLabel' | translate"
            >
              {{ "routenplaner.optionen" | translate }}
              <mat-icon class="contrast">tune</mat-icon>
            </button>
          </div>
          <div class="preview-container">
            <rrpbw-karte-preview
              *ngIf="isSmartphoneLayout"
              #kartePreview
              (clicked)="onKartePreviewClicked(matSidenav)"
              (keydown)="onKeyboardSubmit($event, matSidenav.close, matSidenav)"
            ></rrpbw-karte-preview>
            <rrpbw-routendetails
              class="sidenav-routendetails"
              *ngIf="routeMitWegpunkten"
              [duration]="routeMitWegpunkten.route?.duration"
              [distance]="routeMitWegpunkten.route?.distance"
              [totalAscend]="routeMitWegpunkten.route?.totalAscend"
              [totalDescend]="routeMitWegpunkten.route?.totalDescend"
            ></rrpbw-routendetails>
            <rrpbw-hoehenprofil-preview
              *ngIf="routeMitWegpunkten && matSidenav.opened"
              [routeMitWegpunkten]="routeMitWegpunkten"
              (clicked)="onHoehenprofilPreviewClicked(matSidenav)"
              (keydown)="onKeyboardSubmit($event, onHoehenprofilPreviewClicked, this)"
            ></rrpbw-hoehenprofil-preview>
            <rrpbw-fahrradroute-auswahl
              *ngIf="fahrradroutenAnzeigen && !isSmartphoneLayout"
            ></rrpbw-fahrradroute-auswahl>
          </div>
          <div class="route-buttons" *ngIf="routeMitWegpunkten">
            <button
              mat-stroked-button
              class="button-highlight button-all-caps mr-s route-teilen-button focus"
              (click)="openRouteTeilenDialog()"
              [attr.aria-label]="'routenplaner.teilen' | translate"
            >
              <mat-icon class="mr-xs" aria-hidden="true">share</mat-icon>
              <span>{{ "routenplaner.teilen" | translate }}</span>
            </button>
            <button
              mat-flat-button
              color="primary"
              class="button-all-caps focus"
              (click)="openRouteSpeichernDialog()"
              [attr.aria-label]="'routenplaner.speichernLabel' | translate"
            >
              <span>{{ "routenplaner.speichern" | translate }}</span>
            </button>
          </div>
        </mat-drawer-content>
        <mat-drawer #matDrawer (closed)="onMatDrawerClose()">
          <rrpbw-options-menu
            *ngIf="matDrawer.opened && matDrawerContent === MatDrawerContent.ROUTING_OPTIONS"
            [matDrawer]="matDrawer"
            [route]="routeMitWegpunkten?.route"
            [currentRadtyp]="currentRadtyp"
            [currentFahrverhalten]="currentFahrverhalten"
            [currentRoutingPraeferenz]="currentRoutingPraeferenz"
            [currentSteigungVermeiden]="currentSteigungVermeiden"
            (radtypChanged)="onRadtypChanged($event)"
            (fahrverhaltenChanged)="onFahrverhaltenChanged($event)"
            (routingPraeferenzChanged)="onRoutingPraeferenzChanged($event)"
            (steigungVermeidenChanged)="onSteigungVermeidenChanged($event)"
          >
          </rrpbw-options-menu>
          <rrpbw-details
            *ngIf="matDrawer.opened && matDrawerContent === MatDrawerContent.POI_DETAILS"
            (close)="onPoiDetailsClosed()"
            [selectedFeature]="selectedPoi"
            [layerName]="selectedPoi?.infrastruktur?.labelKey ?? '' | translate"
            [iconPath]="selectedPoiIcon"
            [iconColor]="selectedPoi?.infrastruktur?.iconColor ?? ''"
          >
          </rrpbw-details>
        </mat-drawer>
      </mat-drawer-container>
    </aside>
  </mat-sidenav>
  <mat-sidenav-content [class.sidenav-opened]="matSidenav.opened">
    <ng-container *ngIf="!isSmartphoneLayout && !matDrawer.opened">
      <button
        *ngIf="matSidenav.opened"
        class="sidenav-toggle focus"
        (click)="matSidenav.close()"
        mat-flat-button
        [attr.aria-label]="'routenplaner.hide' | translate"
      >
        <mat-icon class="contrast">expand_less</mat-icon>
      </button>
      <button
        *ngIf="!routeMitWegpunkten && !matSidenav.opened"
        class="sidenav-toggle focus"
        (click)="matSidenav.open()"
        mat-flat-button
        [attr.aria-label]="'routenplaner.show' | translate"
      >
        <mat-icon class="contrast">expand_more</mat-icon>
      </button>
      <aside
        class="sidenav-toggle-with-routendetails focus"
        *ngIf="routeMitWegpunkten && !matSidenav.opened"
        (click)="matSidenav.open()"
        tabindex="0"
        (keydown)="onKeyboardSubmit($event, matSidenav.open, matSidenav)"
        [attr.aria-label]="'routenplaner.show' | translate"
      >
        <div class="icon-container">
          <mat-icon class="contrast">expand_more</mat-icon>
        </div>
        <rrpbw-routendetails
          [verticalLayout]="true"
          [duration]="routeMitWegpunkten.route?.duration"
          [distance]="routeMitWegpunkten.route?.distance"
          [totalAscend]="routeMitWegpunkten.route?.totalAscend"
          [totalDescend]="routeMitWegpunkten.route?.totalDescend"
        ></rrpbw-routendetails>
      </aside>
    </ng-container>
    <button
      class="full-width-elevated-button focus"
      (click)="matSidenav.open()"
      mat-flat-button
      *ngIf="isSmartphoneLayout"
      [attr.aria-label]="'routenplaner.zurueckZurRoutenplanung' | translate"
    >
      <mat-icon class="contrast">navigate_before</mat-icon>
      {{ "routenplaner.zurueckZurRoutenplanung" | translate }}
    </button>
    <rrpbw-karte
      [hintergrundebenen]="[Hintergrundebene.OSM, Hintergrundebene.LUFTBILDER]"
      [defaultContextMenuEntries]="defaultContextMenuEntries"
      [featureContextMenuEntries]="wegpunktContextMenuEntries"
      [disableContextMenu]="true"
      (featureSelected)="onPoiClicked($event)"
      [infrastrukturen]="sichtbareInfrastrukturen"
      [selektierbarePoiInfrastrukturen]="selektierbarePoiInfrastrukturen"
      [attr.aria-label]="'Karte der Radrouten in Baden-Württemberg'"
      [signatur]="Signatur.NETZKLASSEN"
    >
      <rrpbw-grenze-layer></rrpbw-grenze-layer>
      <rrpbw-route-layer
        #routeLayer
        (routeEditedPreview)="onRouteEditedPreview($event)"
        (routeEdited)="onRouteEdited($event)"
      ></rrpbw-route-layer>
      <rrpbw-marker-layer
        #markerLayer
        (markerPreview)="onMarkerPreview($event)"
        (markerEdited)="onMarkerEdited($event)"
      ></rrpbw-marker-layer>
      <ng-container *ngFor="let poiInfrastruktur of selektierbarePoiInfrastrukturen">
        <rrpbw-poi-layer
          #poiLayerComponent
          *ngIf="poiInfrastruktur.selected$ | async"
          [poiInfrastruktur]="poiInfrastruktur"
        ></rrpbw-poi-layer>
      </ng-container>
      <rrpbw-lastenrad-barrieren-layer
        #poiLayerComponent
        *ngIf="routeMitWegpunkten && lastenrad"
        [route]="routeMitWegpunkten.route"
      >
      </rrpbw-lastenrad-barrieren-layer>
      <rrpbw-hoehenprofil-layer #hoehenprofilLayer></rrpbw-hoehenprofil-layer>
      <rrpbw-fahrradrouten-feature-layer></rrpbw-fahrradrouten-feature-layer>
    </rrpbw-karte>
    <section>
      <div *ngIf="!isSmartphoneLayout && showHoehenprofil">
        <div id="hoehenprofilTitleBar">
          <div>
            <h1>{{ "hoehenprofil.title" | translate }}</h1>
            <rrpbw-hoehenprofil-tooltip
              *ngIf="hoveredHoehenprofilLocation"
              [hoveredHoehenprofilLocation]="hoveredHoehenprofilLocation"
            ></rrpbw-hoehenprofil-tooltip>
          </div>
          <button
            mat-button
            (click)="showHoehenprofil = false"
            [attr.aria-label]="'hoehenprofil.close' | translate"
            class="focus"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <rrpbw-hoehenprofil
          class="karte-hoehenprofil"
          [routeMitWegpunkten]="routeMitWegpunkten"
          (selectedElementIndex)="onHoehenprofilPointMarked($event)"
          (hoehenprofilHovered)="onHoehenprofilHovered($event)"
        ></rrpbw-hoehenprofil>
      </div>
      <rrpbw-mobile-karte-tab-menu
        #mobileKarteTabMenu
        *ngIf="isSmartphoneLayout && !matSidenav.opened"
        [routeMitWegpunkten]="routeMitWegpunkten"
        [selectedPoi]="selectedPoi"
        (routenplanungBearbeiten)="matSidenav.open()"
        (hoehenprofilPointMarked)="onHoehenprofilPointMarked($event)"
      >
      </rrpbw-mobile-karte-tab-menu>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>
