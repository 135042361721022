import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "rrpbw-property-label",
  templateUrl: "./property-label.component.html",
  styleUrls: ["./property-label.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyLabelComponent {
  @Input()
  text: string | undefined;

  @Input()
  ariaLabel: string;

  @Input()
  icon: string;

  get ariaLabelWithText(): string {
    return this.ariaLabel + " " + (this.text ?? "");
  }
}
