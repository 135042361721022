import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export enum UrlStorageKeys {
  WEGPUNKTE = "wegpunkte",
  RADTYP = "radtyp",
  ROUTING_PRAEFERENZ = "routing_praeferenz",
  STEIGUNG_VERMEIDEN = "steigung_vermeiden",
  FAHRVERHALTEN = "fahrverhalten",
  HINTERGRUNDKARTE = "hintergrund",
  INFRASTRUKTUREN = "infrastrukturen",
  FAHRRADROUTEN = "fahrradrouten",
}

@Injectable({
  providedIn: "root",
})
export class UrlStorageRepository {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  setQueryParameter(urlStorageKey: string, value: any): void {
    const queryParams: any = {};
    queryParams[urlStorageKey] = JSON.stringify(value);
    this.router.navigate([this.router.url.split("?")[0]], { queryParamsHandling: "merge", queryParams: queryParams });
  }

  findQueryParameter<T>(urlStorageKey: string): Observable<T | undefined> {
    return this.activatedRoute.queryParamMap.pipe(
      map(paramsMap => {
        const queryParameter = paramsMap.get(urlStorageKey);
        if (queryParameter) {
          return JSON.parse(queryParameter) as T;
        }
        return undefined;
      })
    );
  }

  getQueryParameter<T>(urlStorageKey: string): T | undefined {
    const queryParameter = this.activatedRoute.snapshot.queryParamMap.get(urlStorageKey);
    if (queryParameter) {
      return JSON.parse(queryParameter) as T;
    }
    return undefined;
  }
}
