<mat-drawer-container>
  <mat-drawer-content>
    <mat-toolbar class="toolbar">
      <a class="logo" href="/radroutenplaner" [attr.aria-label]="'app.ariaLogoLink' | translate">
        <img src="assets/logo.svg" alt="Radroutenplaner Baden-Württemberg" />
      </a>
      <ng-container *ngIf="!isMobileToolbar">
        <label hidden id="desktop-primary-navigation">{{ "toolbar.aria.desktop" | translate }}</label>
        <nav role="navigation" aria-labelledby="desktop-primary-navigation">
          <ul class="main-navigation-items" #mainNavList>
            <li *ngFor="let item of mainNavigationItems; let i = index" tabindex="-1" class="mr-xs">
              <a
                role="link"
                [routerLink]="item[1]"
                routerLinkActive="active"
                (isActiveChange)="setAriaCurrentMainNav($event, i)"
                >{{ item[0] | translate }}</a
              >
            </li>
          </ul>
          <ul class="secondary-navigation-items" #secondaryNavList>
            <li
              *ngFor="let item of secondaryNavigationItems; let i = index"
              [routerLink]="item[1]"
              routerLinkActive="active"
              (isActiveChange)="setAriaCurrentSecondaryNav($event, i)"
              tabindex="-1"
            >
              <a role="link" [routerLink]="item[1]">{{ item[0] | translate }}</a>
            </li>
          </ul>
        </nav>
      </ng-container>
      <button
        *ngIf="isMobileToolbar"
        [attr.aria-label]="'app.toolbar.openMenu' | translate"
        class="hamburger-button focus"
        (click)="mobileNavigationMenu.open()"
        mat-icon-button
      >
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
    <rrpbw-loading-bar></rrpbw-loading-bar>
    <main role="main">
      <router-outlet></router-outlet>
    </main>
  </mat-drawer-content>
  <mat-drawer #mobileNavigationMenu position="end">
    <div class="drawer-header">
      <button
        class="hamburger-button focus"
        (click)="mobileNavigationMenu.close()"
        [attr.aria-label]="'app.toolbar.closeMenu' | translate"
        mat-icon-button
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <label hidden id="mobile-primary-navigation">{{ "toolbar.aria.mobile" | translate }}</label>
    <nav role="navigation" aria-labelledby="mobile-primary-navigation">
      <ul class="drawer-main-navigation-items">
        <li
          *ngFor="let item of mainNavigationItems; let i = index"
          (click)="mobileNavigationMenu.close()"
          routerLinkActive="active"
        >
          <a role="link" [routerLink]="item[1]">{{ item[0] | translate }}</a>
        </li>
      </ul>
      <ul class="drawer-secondary-navigation-items">
        <li
          *ngFor="let item of secondaryNavigationItems; let i = index"
          (click)="mobileNavigationMenu.close()"
          routerLinkActive="active"
        >
          <a role="link" [routerLink]="item[1]">{{ item[0] | translate }}</a>
        </li>
      </ul>
    </nav>
  </mat-drawer>
</mat-drawer-container>
