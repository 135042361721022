import { Injectable, OnDestroy } from "@angular/core";
import BaseLayer from "ol/layer/Base";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HintergrundebenenService implements OnDestroy {
  private _$addLayer: Subject<BaseLayer> = new Subject();
  private _$removeLayer: Subject<BaseLayer> = new Subject();
  private _$selectLayer: BehaviorSubject<string> = new BehaviorSubject("");

  ngOnDestroy(): void {
    this._$addLayer.complete();
    this._$removeLayer.complete();
    this._$selectLayer.complete();
  }

  public addLayer(layer: BaseLayer): void {
    this._$addLayer.next(layer);
  }

  public removeLayer(layer: BaseLayer): void {
    this._$removeLayer.next(layer);
  }

  public selectLayer(layerId: string): void {
    this._$selectLayer.next(layerId);
  }

  get $addLayer(): Observable<BaseLayer> {
    return this._$addLayer.asObservable();
  }

  get $removeLayer(): Observable<BaseLayer> {
    return this._$removeLayer.asObservable();
  }

  get $selectLayer(): Observable<string> {
    return this._$selectLayer.asObservable();
  }

  get selectedLayerId(): string {
    return this._$selectLayer.value;
  }
}
