export class Fahrverhalten {
  public static readonly GEMUETLICH = new Fahrverhalten(1.4, "Gemütlich");
  public static readonly DURCHSCHNITTLICH = new Fahrverhalten(1.2, "Durchschnittlich");
  public static readonly ZUEGIG = new Fahrverhalten(1, "Zügig");
  public static readonly SPORTLICH = new Fahrverhalten(0.8, "Sportlich");
  public static readonly SEHRPORTLICH = new Fahrverhalten(0.6, "Sehr Sportlich");

  private static ALL_VALUES = [
    Fahrverhalten.GEMUETLICH,
    Fahrverhalten.DURCHSCHNITTLICH,
    Fahrverhalten.ZUEGIG,
    Fahrverhalten.SPORTLICH,
    Fahrverhalten.SEHRPORTLICH,
  ];

  public static ofFaktor(faktor: number): Fahrverhalten {
    const result = Fahrverhalten.ALL_VALUES.find(fahrverhalten => fahrverhalten.faktor === faktor);

    if (!result) {
      throw Error(`Es existiert kein Fahrverhalten mit Faktor ${faktor}`);
    }
    return result;
  }

  public static isValid(faktor: number): boolean {
    return Fahrverhalten.ALL_VALUES.some(fahrverhalten => fahrverhalten.faktor === faktor);
  }

  private constructor(private _faktor: number, public displayText: string) {}

  public get faktor(): number {
    return this._faktor;
  }
}
