import { Injectable } from "@angular/core";
import { UrlStorageKeys, UrlStorageRepository } from "src/app/common/repositories/url-storage.repository";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class FahrradroutenAuswahlService {
  private _selectedFahrradroutenIds$: BehaviorSubject<number[]>;

  private _highlightedRouteId$ = new Subject<number | undefined>();

  private _zoomToRouteId$ = new ReplaySubject<number>(1, 500);

  constructor(private urlStorageRepository: UrlStorageRepository) {
    this._selectedFahrradroutenIds$ = new BehaviorSubject<number[]>([]);

    urlStorageRepository
      .findQueryParameter<number[]>(UrlStorageKeys.FAHRRADROUTEN)
      .pipe(map(arr => arr || []))
      .subscribe(ids => {
        this._selectedFahrradroutenIds$.next(ids);
      });

    urlStorageRepository.findQueryParameter<number>("zoomToRoute").subscribe(id => {
      if (id) {
        this._zoomToRouteId$.next(id);
        urlStorageRepository.setQueryParameter("zoomToRoute", undefined);
      }
    });
  }

  get selectedFahrradroutenIds$(): Observable<number[]> {
    return this._selectedFahrradroutenIds$.asObservable();
  }

  get selectedFahrradroutenIds(): number[] {
    return this._selectedFahrradroutenIds$.value;
  }

  get highlightedRouteId$(): Observable<number | undefined> {
    return this._highlightedRouteId$.asObservable();
  }

  get zoomToRouteId$(): Observable<number> {
    return this._zoomToRouteId$.asObservable();
  }

  selectFahrradroute(id: number): void {
    const selectedFahrradroutenIds = this.selectedFahrradroutenIds;
    if (!selectedFahrradroutenIds.includes(id)) {
      selectedFahrradroutenIds.push(id);
      this.urlStorageRepository.setQueryParameter(UrlStorageKeys.FAHRRADROUTEN, selectedFahrradroutenIds);
    }
  }

  deselectFahrradroute(id: number): void {
    const selectedFahrradroutenIds = this.selectedFahrradroutenIds;
    if (selectedFahrradroutenIds.includes(id)) {
      this.urlStorageRepository.setQueryParameter(
        UrlStorageKeys.FAHRRADROUTEN,
        selectedFahrradroutenIds.filter(routeId => routeId !== id)
      );
    }
  }

  highlightRoute(id: number | undefined): void {
    this._highlightedRouteId$.next(id);
  }

  zoomToRoute(id: number): void {
    this._zoomToRouteId$.next(id);
  }
}
