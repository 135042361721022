import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { Observable, startWith, switchMap } from "rxjs";
import { FahrradroutenService } from "src/app/fahrradrouten/services/fahrradrouten.service";
import { Geometry } from "ol/geom";
import { filter, map, tap } from "rxjs/operators";
import { Feature } from "ol";
import { Stroke, Style } from "ol/style";
import { ColorTone, GrayscaleTone, RrpBwColors } from "src/app/common/utils/rrp-bw-colors";
import { DetailProperties } from "src/app/karte/entities/detail-properties";
import { FahrradroutenAuswahlService } from "src/app/fahrradrouten/services/fahrradrouten-auswahl.service";

@Component({
  selector: "rrpbw-fahrradrouten-feature-layer",
  templateUrl: "./fahrradrouten-feature-layer.component.html",
  styleUrls: ["./fahrradrouten-feature-layer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FahrradroutenFeatureLayerComponent {
  public routes: Observable<Feature<Geometry>[]>;

  FAHRRADROUTE_STYLE_THIN = [
    new Style({
      stroke: new Stroke({
        width: 4,
        color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone1, 0.75),
      }),
    }),
    new Style({
      stroke: new Stroke({
        width: 3,
        color: RrpBwColors.getPrimaryColor(ColorTone.Tone700, 0.75),
      }),
    }),
  ];

  FAHRRADROUTE_STYLE_THIN_HIGHLIGHTED = [
    new Style({
      stroke: new Stroke({
        width: 4,
        color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone1, 0.75),
      }),
    }),
    new Style({
      stroke: new Stroke({
        width: 3,
        color: RrpBwColors.getAccentColor(ColorTone.Tone700, 0.75),
      }),
    }),
  ];

  constructor(
    private fahrradroutenAuswahlService: FahrradroutenAuswahlService,
    fahrradroutenService: FahrradroutenService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.routes = fahrradroutenAuswahlService.selectedFahrradroutenIds$.pipe(
      switchMap(ids => fahrradroutenService.getRouteGeoms(ids || [])),
      tap(() => changeDetectorRef.detectChanges())
    );
  }

  getGeom(feature: Feature<Geometry>): Geometry | undefined {
    return feature.getGeometry();
  }

  getStyle$(feature: Feature<Geometry>): Observable<Style[]> {
    return this.fahrradroutenAuswahlService.highlightedRouteId$.pipe(
      startWith(undefined),
      map(id =>
        id === feature.getProperties()[DetailProperties.ID.key]
          ? this.FAHRRADROUTE_STYLE_THIN_HIGHLIGHTED
          : this.FAHRRADROUTE_STYLE_THIN
      )
    );
  }

  getZoomToFeature$(feature: Feature<Geometry>): Observable<void> {
    return this.fahrradroutenAuswahlService.zoomToRouteId$.pipe(
      filter(id => feature.getProperties()[DetailProperties.ID.key] === id),
      map(() => undefined)
    );
  }
}
