import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { toLonLat } from "ol/proj";
import { GeoLocation } from "../../common/entities/geo-location";
import { Route } from "src/app/common/entities/route";
import { Analytics } from "../../common/utils/analytics";
import { Radtyp } from "../entities/radtyp";
import { RoutingPraeferenz } from "../entities/routing-praeferenz";
import { SinglePathDetail } from "src/app/common/entities/single-path-detail";

export interface RouteResponseDto {
  distance: number; // in meters
  time: number; // in milliseconds
  ascend: number; // in meters
  descend: number; // in meters
  pointList: number[][];
  pathDetails: SinglePathDetail[];
}

interface RouteRequestDto {
  points: [number, number][];
  routingprofil: string;
  weighting: string;
  routingPraeferenz: string;
}

export enum RoutenprofilDto {
  FAHRRAD = "FAHRRAD",
  RENNRAD = "RENNRAD",
  MOUNTAINBIKE = "MOUNTAINBIKE",
}

export enum WeightingDto {
  FASTEST = "FASTEST",
  STEIGUNG_VERMEIDEN = "STEIGUNG_VERMEIDEN",
}

@Injectable({
  providedIn: "root",
})
export class RouteRepository {
  private static readonly ROUTE_URL: string = "/api/route";

  constructor(private httpClient: HttpClient) {}

  getRoute(
    geoLocations: GeoLocation[],
    radtyp: Radtyp,
    routingPraeferenz: RoutingPraeferenz,
    steigungVermeiden: boolean
  ): Observable<Route> {
    let weighting = WeightingDto.FASTEST;
    if (steigungVermeiden) {
      weighting = WeightingDto.STEIGUNG_VERMEIDEN;
    }

    return this.httpClient
      .post<RouteResponseDto>(
        RouteRepository.ROUTE_URL,
        JSON.stringify({
          points: geoLocations.map(geoLocation => toLonLat(geoLocation.coordinates)),
          routingprofil: RouteRepository.toProfileDto(radtyp),
          weighting: weighting,
          routingPraeferenz: routingPraeferenz,
        } as RouteRequestDto),
        { headers: new HttpHeaders().set("Content-Type", "application/json") }
      )
      .pipe(
        tap(response => {
          Analytics.pushEvent(
            "routing",
            "api",
            `Start: ${geoLocations[0].string}; Ziel: ${geoLocations[geoLocations.length - 1].string}; Distanz: ${
              response.distance
            }`
          );
        }),
        map(response => {
          return new Route(
            response.pointList,
            response.pathDetails,
            response.distance,
            response.ascend,
            response.descend,
            response.time
          );
        })
      );
  }

  static toProfileDto(radtyp: Radtyp): string {
    switch (radtyp) {
      case Radtyp.RENNRAD:
        return RoutenprofilDto.RENNRAD;
      case Radtyp.FAHRRAD:
        return RoutenprofilDto.FAHRRAD;
      case Radtyp.MOUNTAINBIKE:
        return RoutenprofilDto.MOUNTAINBIKE;
      default:
        return RoutenprofilDto.FAHRRAD;
    }
  }
}
