<div class="canvas-container">
  <canvas
    #chartCanvas
    [attr.aria-label]="'hoehenprofil.title' | translate"
    tabindex="0"
    (keydown)="onKeyDown($event)"
    (touchcancel)="onUnhover()"
    (mouseout)="onUnhover()"
    (mousemove)="onMouseMove()"
    (touchmove)="onMouseMove()"
  ></canvas>
</div>

<div
  *ngIf="withAttributeVisualization"
  class="eigenschaften-wrapper"
  [style.margin-left.px]="xAxisStart"
  [style.margin-right.px]="xAxisEnd"
>
  <div class="strecken-eigenschaften mb-xxs">
    <div
      *ngFor="let eigenschaft of pathDetails"
      [style.left.%]="eigenschaft.from * 100"
      [style.width.%]="(eigenschaft.to - eigenschaft.from) * 100"
      [style.background-color]="convertToCss(belagArtColors.get(eigenschaft.belagArt))"
    ></div>
  </div>
  <div class="strecken-eigenschaften">
    <div
      *ngFor="let eigenschaft of pathDetails"
      [style.left.%]="eigenschaft.from * 100"
      [style.width.%]="(eigenschaft.to - eigenschaft.from) * 100"
      [style.background-color]="convertToCss(radverkehrsfuehrungColors.get(eigenschaft.radverkehrsfuehrung))"
    ></div>
  </div>
</div>
