import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ChartDataset, ChartOptions } from "chart.js";
import { GrayscaleTone, RrpBwColors } from "../../../common/utils/rrp-bw-colors";
import { AbstractHoehenprofilComponent } from "src/app/common/dumb-components/abstract-hoehenprofil.component";
import { HoveredHoehenprofilLocation } from "src/app/common/entities/hoehenprofil-hover-location";

@Component({
  selector: "rrpbw-mobile-hoehenprofil",
  templateUrl: "./mobile-hoehenprofil.component.html",
  styleUrls: ["./mobile-hoehenprofil.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHoehenprofilComponent extends AbstractHoehenprofilComponent implements OnInit, OnDestroy {
  @ViewChild("chartCanvas", { static: true })
  chartCanvas: any;

  public hoveredHoehenprofilLocation: HoveredHoehenprofilLocation | undefined;

  constructor() {
    super(true, true);
  }

  lineChartDatasets: ChartDataset[] = [
    {
      data: [],
      borderWidth: 3,
      borderColor: RrpBwColors.getPrimaryColor(500),
      backgroundColor: RrpBwColors.getPrimaryColor(500, 0.1),
      fill: { value: 0 },
      normalized: true,
      spanGaps: true,
      parsing: false,
    },
  ];

  lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    parsing: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        right: 0,
        bottom: 0,
        left: -28,
        top: 0,
      },
    },
    scales: {
      x: {
        type: "linear",
        display: false,
        position: "top",
        grid: {
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone4),
        },
        ticks: {
          display: false,
          maxTicksLimit: 1,
        },
      },
      y: {
        type: "linear",
        grid: {
          offset: false,
          drawBorder: false,
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone4),
        },
        ticks: {
          mirror: true,
          padding: 10,
          z: 1,
          labelOffset: -12,
          font: {
            size: 16,
          },
          color: RrpBwColors.getGrayscaleColor(GrayscaleTone.Tone11),
          maxTicksLimit: 2,
          callback(value: number | string): string | number | null | undefined {
            return `${value} m`;
          },
        },
      },
    },
    hover: {
      intersect: false,
      mode: "index",
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        position: "average",
        enabled: false,
        external: () => {}, // Leere Funktion damit wir zwar den vertikalen Strich haben, aber kein default Tooltip im Plot
      },
      legend: {
        display: false,
      },
      decimation: {
        enabled: true,
        algorithm: "lttb",
        samples: 50,
        threshold: 50,
      },
    },
  };

  ngOnInit(): void {
    this.hoehenprofilHovered.subscribe(hoverEvent => {
      this.hoveredHoehenprofilLocation = hoverEvent;
    });

    const context = this.chartCanvas.nativeElement.getContext("2d")!;
    this.initChart(context);
  }

  onUnhover(): void {
    super.onUnhover();
  }
}
