export class Environment {
  static poisAnzeigen(): boolean {
    return this.retrieveEnvironmentValue("poisAnzeigen");
  }

  static toastTextKey(): string {
    return this.retrieveEnvironmentValue("toastTextKey");
  }

  static radNetzLayerAnzeigen(): boolean {
    return this.retrieveEnvironmentValue("radNetzLayerAnzeigen");
  }

  static radvisViewerAnzeigen(): boolean {
    return this.retrieveEnvironmentValue("radvisViewerAnzeigen");
  }

  static meldeplattformAnzeigen(): boolean {
    return this.retrieveEnvironmentValue("meldeplattformAnzeigen");
  }

  static fahrradroutenAnzeigen(): boolean {
    return this.retrieveEnvironmentValue("fahrradroutenAnzeigen");
  }

  static poiIconZoomLevel(): Map<string, number> {
    return this.retrieveEnvironmentValue("poiIconZoomLevel");
  }

  static radvisViewerSichtbareInfrastrukturenLabelKeys(): string[] {
    return this.retrieveEnvironmentValue("radvisViewerSichtbareInfrastrukturenLabelKeys");
  }

  static lastenradAnzeigen(): boolean {
    return this.retrieveEnvironmentValue("lastenradAnzeigen");
  }

  static barriereToRouteDistance(): number {
    return this.retrieveEnvironmentValue("barriereToRouteDistance");
  }

  private static retrieveEnvironmentValue(key: string): any {
    // @ts-ignore
    return window.__env[key];
  }
}
